/**
 * @fileOverview the main UI component, current home page
 * 
 * Displays Statewide overview using H3 indices
 * @param  {Object[][]} props 
 *    @param {String} statusGuide a tracking string for current page, expected value "State"
 * 
 * @Components 
 *    @DeckglMapH3 displays the map
 * 
 *    @DashBoardLinksStatewide display the in-page links for content
 *        @param {function} toggleVisibility
 *          @param {Object[][]} visiblity
 *              @param {Boolean} show1 shows/hides table panel
 *              @param {Boolean} show2 shows/hides events by duration using a Donut3D Chart by Month
 *              @param {Boolean} show3 shows/hides events by day-of-week using a Stacked Bar Chart
 *              @param {Boolean} show4 shows/hides events by month using a Stacked Bar Chart
 *              @param {Boolean} show5 shows/hides events by half hour duration bin using a Time Series Line Chart
 *              @param {Boolean} show6 shows/hides events by time of day using a BoxPlot
 *              @param {Boolean} show7 shows/hides events by time of day using a Column 3D Plot
 * 
 *    @TablePanel displays the parking events table
 *        @param {String} titleDesc passes "Parking Events"
 * 
 *    @ChartsLayoutH3 displays state parking events using highCharts library
 *        @param {String} statusGuide 
 *        @param {Integer} chartHeight determined by canvas height offset
 *        @param {Object[][]} visibility  

 *   @OptionsBar displays toolbar to select map and data options 
 *        @param {String} statusGuide
 *        @param {Boolean} showVehicleWeightClass To show/hide selecting Weight Class
 *        @param {Boolean} showDayType  To show/hide selecting Day Type 
 *        @param {Boolean} showYear To show/hide selecting Year
 *        @param {Boolean} showMonth To show/hide selecting Month
 *        @param {Boolean} showGridResolution To show/hide selecting Grid Resolution
 *        @param {Boolean} showOpacity To show/hide selecting Opacity
 * 
 *    @ScrollArrow button to scroll back to top
 * 
 * @return {StatewideLayoutH3} a layout containing dashboard view 
 */

import "../App.scss";
import React, { useContext, useEffect, useRef, useState } from "react";

import { Box, Grid, useMediaQuery } from "../components/mui.js";
import DashboardLinksStatewide from "./DashboardLinksStatewide.js";
import ChartsLayoutH3 from "../Charts/ChartsLayoutH3";
import DeckglMapH3 from "../map/DeckglMapH3.js";
import OptionsBar from "../ui/OptionsBar";
import TablePanel from "../table/TablePanel";
import { txdotDistricts, mpoList } from "../common/constants";
import ScrollArrow from "../ui/ScrollTop.js";
import { QueryContext } from "../context/QueryContext.js";

function StatewideLayoutH3(props) {
  const { statusGuide } = props;

  const [query, dispatch] = useContext(QueryContext);

  /**Checks user system preference to set mode */
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const mode = prefersDarkMode ? "dark" : "light";

  const stageCanvasRef = useRef(null);

  const [chartHeight, setChartHeight] = useState(600);

  const [visibility, setVisibility] = useState({
    show1: true,
    show2: true,
    show3: true,
    show4: true,
    show5: true,
    show6: true,
    show7: true,
  });

  // Toggle function for visibility based on checkbox change
  const toggleVisibility = (section) => {
    setVisibility((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  useEffect(() => {
    // The 'current' property contains info of the reference:
    // align, title, ... , width, height, etc.
    if (stageCanvasRef.current) {
      let height = stageCanvasRef.current.offsetHeight;
      //let width = stageCanvasRef.current.offsetWidth;

      setChartHeight(height);
    }
  }, [stageCanvasRef]);

  useEffect(() => {
    if (query.geoFilterOption) {
      let region = "State";
      if (query.geoFilterMode === "district") {
        region = txdotDistricts.find(
          (d) => d.value === query.geoFilterOption
        ).label;
      } else if (query.geoFilterMode === "mpo") {
        region = mpoList.find((m) => m.value === query.geoFilterOption).label;
      } else {
        region = query.geoFilterOption;
      }
      dispatch({
        type: "setRegionLookup",
        selection: region,
      });
    }
  }, [query.geoFilterOption]);

  return (
    <React.Fragment>
      <Grid container spacing={2} className="grid-container">
        <Grid item xs={12} sm={12} md={8} lg={8} className="grid-item">
          <Box className="map-container" >
            <DeckglMapH3 />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} className="grid-item">
          <DashboardLinksStatewide toggleVisibility={toggleVisibility} />
        </Grid>
        {visibility.show1 && (
          <Grid item xs={12} sm={12} md={12} lg={12} className="grid-item">
            <TablePanel id="parkingevent" titleDesc="Parking Events" />
          </Grid>
        )}
        <ChartsLayoutH3
          chartHeight={chartHeight}
          visibility={visibility}
        />
      </Grid>
      <OptionsBar
        statusGuide={statusGuide}
        showVehicleWeightClass={true}
        showDayType={true}
        showYear={true}
        showMonth={true}
        showGridResolution={true}
        showOpacity={true}
      />
      <ScrollArrow />
    </React.Fragment>
  );
}

export default StatewideLayoutH3;
