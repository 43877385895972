import React, { useState, useEffect, useContext, useMemo } from "react";
import DeckGL from "@deck.gl/react";
import { GeoJsonLayer } from "@deck.gl/layers";
import { H3HexagonLayer } from "@deck.gl/geo-layers";
import { h3ToGeo } from "h3-js";
import { scaleThreshold } from "d3-scale";
import { color } from "d3-color";

import {
  StaticMap,
  MapContext,
  NavigationControl,
  ScaleControl,
  WebMercatorViewport,
} from "react-map-gl";

import { makeStyles, Box, CircularProgress } from "../components/mui";

import {
  fetchEventsByH3,
  fetchEventsH3Summary,
  fetchStatewideTruckParkingLocations,
  fetchCommercialBorderCrossings,
  fetchRailBorderCrossings,
  fetchCargoAirports,
  fetchPorts,
} from "../data-loaders/DataLoaders";

import { QueryContext } from "../context/QueryContext";

import {
  regionParkingDataTypes,
  vehicleWeightClassOptions,
  yearsMonths,
  dayTypeOptions,
} from "../common/constants";

import LayerControls from "./contols/LayerControls";

import StateMapLegend from "./StateMapLegend";

import truckParkingIconSvg from "../assets/icons/local_parking_white_on_green_24dp.svg";
import commercialBorderCrossingIconSvg from "../assets/icons/green_box_24dp.svg";
import railBorderCrossingIconSvg from "../assets/icons/pink_box_24dp.svg";
import cargoAirportsIconSvg from "../assets/icons/flight_yellow_24dp.svg";
import portsIconSvg from "../assets/icons/anchor_white_24dp.svg";

const useStyles = makeStyles({
  deckglMap: {
    position: "relative",
  },
  dialog: {
    position: "absolute",
    left: "92%",
    top: "50%",
    transform: "translate(-75%,-50%)",
  },
  dialog2: {
    position: "absolute",
    left: "69vw",
    top: "27vh",
  },
  dataParameters: {
    position: "absolute",
    marginLeft: "12px",
    marginTop: "150px",
    color: "black",
  },
  dataParameters2: {
    position: "absolute",
    left: "74%",
    top: "1%",
    color: "black",
  },
  boxBackground: {
    color: "blue",
    fontSize: 12,
    position: "absolute",
  },
});

function DeckglMapH3() {
  const classes = useStyles();
  const [query, dispatch] = useContext(QueryContext);

  /**Map hexagon layer data */
  const [statewideH3Data, setStatewideH3Data] = useState([]);

  /**loading overlay status to display spinner */
  const [isLoading, setIsLoading] = useState({
    current: 0,
    total: 0,
    progress: 0,
  });

  /**map icons settings */
  const NAV_CONTROL_STYLE = {
    position: "absolute",
    marginTop: "10px",
    marginLeft: "12px",
  };
  const ScaleControl_STYLE = {
    position: "absolute",
    top: "96%",
    left: "125px",
  };
  const layers_CONTROL_STYLE = {
    position: "absolute",
    marginTop: "90px",
    marginLeft: "0px",
    paddingLeft: "0px",
  };

  /**DeckGL Map related states*/
  const [viewport, setViewport] = useState({
    width: "100%",
    height: "100%",
  });

  const [homeMapBounds, setHomeMapBounds] = useState({
    longitude: -99.75,
    latitude: 29.3,
    zoom: 5.75,
    pitch: 52,
    bearing: 0,
    transitionDuration: 0,
    transitionEasing: "Ease-In-Sine",
  });

  const [baseMap, setBaseMap] = useState("mapbox://styles/mapbox/streets-v11");
  const [zoomLevel, setZoomLevel] = useState(5.75);
  const [controllerAvail, setControllerAvail] = useState(false);

  /**Map Layers data states and visibility states */
  const [showRegionParking, setShowRegionParking] = useState(true);
  const [showStateParkingSites, setShowStateParkingSites] = useState(false);
  const [showCommercialBorderCrossings, setShowCommercialBorderCrossings] =
    useState(false);
  const [showRailBorderCrossings, setShowRailBorderCrossings] = useState(false);
  const [showCargoAirports, setShowCargoAirports] = useState(false);
  const [showPorts, setShowPorts] = useState(false);

  const dataLayers = [
    {
      label: "Region Parking Data",
      key: "region-parking-data",
      visibility: showRegionParking,
      setVisibility: setShowRegionParking,
      icon: null,
    },
    {
      label: "Statewide Parking Sites",
      key: "state-parking-sites",
      visibility: showStateParkingSites,
      setVisibility: setShowStateParkingSites,
      icon: truckParkingIconSvg,
    },
    {
      label: "Commercial Border Crossings",
      key: "commercial-border-crossings",
      visibility: showCommercialBorderCrossings,
      setVisibility: setShowCommercialBorderCrossings,
      icon: commercialBorderCrossingIconSvg,
    },
    {
      label: "Rail Border Crossings",
      key: "rail-border-crossings",
      visibility: showRailBorderCrossings,
      setVisibility: setShowRailBorderCrossings,
      icon: railBorderCrossingIconSvg,
    },
    {
      label: "Cargo Airports",
      key: "cargo-airports",
      visibility: showCargoAirports,
      setVisibility: setShowCargoAirports,
      icon: cargoAirportsIconSvg,
    },
    {
      label: "Ports",
      key: "ports",
      visibility: showPorts,
      setVisibility: setShowPorts,
      icon: portsIconSvg,
    },
  ];

  const [portsData, setPortsData] = useState(null);
  useMemo(() => {
    if (showPorts && portsData == null) {
      const fetchPortsData = async () => {
        await fetchPorts().then((res) => {
          setPortsData(res.data);
        });
      };
      fetchPortsData();
    }
  }, [portsData, showPorts]);
  const [cargoAirportsData, setCargoAirportsData] = useState(null);
  useMemo(() => {
    if (showCargoAirports && cargoAirportsData == null) {
      const fetchCargoAirportsData = async () => {
        await fetchCargoAirports().then((res) => {
          setCargoAirportsData(res.data);
        });
      };
      fetchCargoAirportsData();
    }
  }, [cargoAirportsData, showCargoAirports]);
  const [railBorderCrossingsData, setRailBorderCrossingsData] = useState(null);
  useMemo(() => {
    if (showRailBorderCrossings && railBorderCrossingsData == null) {
      const fetchRailBorderCrossingsData = async () => {
        await fetchRailBorderCrossings().then((res) => {
          setRailBorderCrossingsData(res.data);
        });
      };
      fetchRailBorderCrossingsData();
    }
  }, [railBorderCrossingsData, showRailBorderCrossings]);
  const [commercialBorderCrossingsData, setCommercialBorderCrossingsData] =
    useState(null);
  useMemo(() => {
    if (
      showCommercialBorderCrossings &&
      commercialBorderCrossingsData == null
    ) {
      const fetchCommercialBorderCrossingsData = async () => {
        await fetchCommercialBorderCrossings().then((res) => {
          setCommercialBorderCrossingsData(res.data);
        });
      };
      fetchCommercialBorderCrossingsData();
    }
  }, [commercialBorderCrossingsData, showCommercialBorderCrossings]);
  const [stateParkingSitesData, setStateParkingSitesData] = useState(null);
  useMemo(() => {
    if (showStateParkingSites && stateParkingSitesData == null) {
      const fetchStateParkingSitesData = async () => {
        await fetchStatewideTruckParkingLocations().then((res) => {
          setStateParkingSitesData(res.data);
        });
      };
      fetchStateParkingSitesData();
    }
  }, [showStateParkingSites]);

  /**Fetch map h3 data */
  useMemo(() => {
    if (query.geoFilterMode === "statewide" || query.geoFilterOption !== "") {
      setIsLoading({
        current: 1,
        total: 2,
        progress: 50,
      });
      const fetchTParkingDataH3 = async () => {
        let vehWeightClasses = [2, 3];
        if (query.selectedVehicleWeightClass != 0)
          vehWeightClasses = query.selectedVehicleWeightClass;
        let dayTypes = [1, 2, 3, 4, 5, 6, 7];
        if (query.selectedDayType != "All Daytypes")
          dayTypes = dayTypeOptions.filter(
            (itm) => itm.label === query.selectedDayType
          )[0].value;

        await fetchEventsH3Summary(
          query.geoFilterMode,
          query.statewide.year,
          query.statewide.month,
          query.stateMapGridResolution,
          query.parkingDurationCategories,
          vehWeightClasses,
          dayTypes,
          typeof query.geoFilterOption === "number"
            ? query.geoFilterOption
            : undefined,
          typeof query.geoFilterOption === "string"
            ? query.geoFilterOption
            : undefined
        ).then((res) => {
          setStatewideH3Data(res.data);
          setIsLoading({
            current: 2,
            total: 2,
            progress: 100,
          });
        });
      };
      fetchTParkingDataH3();
    }
  }, [
    query.geoFilterMode,
    query.selectedVehicleWeightClass,
    query.selectedDayType,
    query.statewide.year,
    query.statewide.month,
    query.stateMapGridResolution,
    query.geoFilterOption,
  ]);

  useEffect(() => {
    if (statewideH3Data.length > 0) {
      const bounds = getBounds(statewideH3Data);
      const viewport = new WebMercatorViewport({
        width: window.innerWidth,
        height: window.innerHeight,
      });

      const { longitude, latitude, zoom } = viewport.fitBounds(bounds, {
        padding: 100,
      });
      setZoomLevel(zoom);

      setHomeMapBounds({
        ...homeMapBounds,
        longitude,
        latitude,
        zoom,
      });
    }
  }, [statewideH3Data]);

  useEffect(() => {
    if (isLoading.current !== isLoading.total) setControllerAvail(false);
    else setControllerAvail(true);
  }, [isLoading]);

  const getBounds = (data) => {
    const hexagonData = data.map((d) => d.h3index);
    const geoCoords = hexagonData.map((h3) => h3ToGeo(h3));

    // Extract the latitude and longitude for each point
    const latitudes = geoCoords.map((coord) => coord[0]);
    const longitudes = geoCoords.map((coord) => coord[1]);

    // Find the minimum and maximum latitude and longitude values
    const minLat = Math.min(...latitudes);
    const maxLat = Math.max(...latitudes);
    const minLng = Math.min(...longitudes);
    const maxLng = Math.max(...longitudes);

    return [
      [minLng, minLat],
      [maxLng, maxLat],
    ];
  };

  const handleViewStateChange = (newViewState) => {
    if (newViewState.viewState.zoom !== zoomLevel) {
      setZoomLevel(newViewState.viewState.zoom);
    }
  };

  const deckGlTooltip = (layer, object) => {
    let toolTipStyle = {
      backgroundColor: "#e1e7ed",
      color: "#000000",
      fontSize: "1.0em",
    };
    if (layer.id === "statewideData-grid") {
      let vehWeightClassLabel = vehicleWeightClassOptions.find(
        (obj) => obj.value === query.selectedVehicleWeightClass
      ).label;

      return {
        html:
          `<h7><strong>Cluster of Parking Events</strong></h7>` +
          `<br />` +
          `<h7>Count: </h7> ${object.count.toLocaleString()}` +
          `<br />` +
          query.regionLookup +
          `<br />` +
          yearsMonths.filter(
            (itm) =>
              itm.year === query.statewide.year &&
              itm.month === query.statewide.month
          )[0].yearMonth +
          `<br />` +
          regionParkingDataTypes[0].label +
          `<br />` +
          vehWeightClassLabel +
          `<br />` +
          query.selectedDayType,
        style: toolTipStyle,
      };
    }

    if (layer.id === "state-parking-sites")
      return {
        html: `<h7>Truck Parking Location</h7>`,
        style: toolTipStyle,
      };

    if (
      layer.id === "commercial-border-crossings" ||
      layer.id === "rail-border-crossings"
    ) {
      let borderCrossingCategory = "Commercial";
      if (layer.id === "railBorderCrossings") borderCrossingCategory = "Rail";
      let commercialAccess = object.properties.VEH_COML === 1 ? "Yes" : "No";
      let noncommercialAccess = object.properties.VEH_CAR === 1 ? "Yes" : "No";
      let railAccess = object.properties.RR_BRDGE === 1 ? "Yes" : "No";
      let pedestrianAccess = object.properties.PED === 1 ? "Yes" : "No";

      return {
        html: `<div>
      <div style="text-align: center;border-bottom: 2.0px solid black;">
        <h5 style="margin-bottom: 0.25rem;margin-top: 0.25rem;">Border Crossings - ${borderCrossingCategory} Layer</h5>
      </div>
      <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>Name:</strong> ${object.properties.CROSS_NM}</p>
      <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>Type:</strong> ${object.properties.CRS_TYPE}</p>
      <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>Owner:</strong> ${object.properties.OWNER}</p>
      <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>Operator:</strong> ${object.properties.OPRTR}</p>
      <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>Jurisdiction Type:</strong> ${object.properties.JURIS_TYPE}</p>
      <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>Commercial:</strong> ${commercialAccess}</p>
      <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>Non-Commercial:</strong> ${noncommercialAccess}</p>
      <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>Rail:</strong> ${railAccess}</p>
      <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>Pedestrian:</strong> ${pedestrianAccess}</p>
    </div>`,
        style: toolTipStyle,
      };
    }

    if (layer.id === "cargo-airports" || layer.id === "airports") {
      let airportCategory = "Cargo Airports";
      if (layer.id === "airports")
        airportCategory = "Other Major Airports Airports";
      return {
        html: `<div>
      <div style="text-align: center;border-bottom: 2.0px solid black;">
        <h5 style="margin-bottom: 0.25rem;margin-top: 0.25rem;">${airportCategory} Layer</h5>
      </div>
      <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>Airport Name:</strong> ${object.properties.ARPT_NAME}</p>
      <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>Location:</strong> ${object.properties.CITY}, TX</p>
      <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>Commercial Ops:</strong> ${object.properties.COMMERCIAL_OPS}</p>
      <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>Commuter Ops:</strong> ${object.properties.AIR_TAXI_OPS}</p>
      <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>Other Services:</strong> ${object.properties.OTHER_SERVICES}</p>
    </div>`,
        style: toolTipStyle,
      };
    }

    if (layer.id === "ports")
      return {
        html: `<div>
      <div style="text-align: center;border-bottom: 2.0px solid black;">
        <h5 style="margin-bottom: 0.25rem;margin-top: 0.25rem;">Ports Layer</h5>
      </div>
      <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>Name:</strong> ${object.properties.PORT_NM}</p>
      <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>Type:</strong> ${object.properties.PORT_TYPE}</p>
    </div>`,
        style: toolTipStyle,
      };

    return;
  };

  return (
    <div onContextMenu={(e) => e.preventDefault()} id="map">
      <DeckGL
        {...viewport}
        initialViewState={homeMapBounds}
        getTooltip={({ layer, object }) =>
          object && deckGlTooltip(layer, object)
        }
        controller={controllerAvail}
        ContextProvider={MapContext.Provider}
        onViewStateChange={handleViewStateChange}
      >
        <StaticMap
          mapStyle={baseMap}
          mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_ACCESSTOKEN}
          preventStyleDiffing={true}
        />
        {isLoading.current !== isLoading.total && statewideH3Data && (
          <Box
            sx={{ marginTop: "43vh", marginLeft: "50%" }}
            className={classes.boxBackground}
          >
            <CircularProgress
              style={{
                marginLeft: "5%",
                marginTop: "5%",
              }}
              size={50}
              thickness={5}
            />
          </Box>
        )}
        {statewideH3Data.length > 0 && showRegionParking && (
          <H3HexagonLayer
            id="statewideData-grid"
            data={statewideH3Data}
            pickable={true}
            wireframe={false}
            filled={true}
            extruded={true}
            getHexagon={(d) => d.h3index}
            getFillColor={(d) => {
              const threshold = scaleThreshold()
                .domain([10, 100, 250, 500, 1000, 1500, 2000])
                .range([
                  "#e6ddc9",
                  "#e5c99c",
                  "#e8b373",
                  "#ec9a4d",
                  "#f17d2c",
                  "#f7580f",
                  "#fd0202",
                ]);

              const colorFill = color(threshold(d.count));
              return [colorFill.r, colorFill.g, colorFill.b];
            }}
            getElevation={(d) => d.count * (10 / zoomLevel)}
            opacity={query.mapOpacity}
            updateTriggers={{
              getElevation: zoomLevel,
            }}
          />
        )}
        {stateParkingSitesData !== null && showStateParkingSites && (
          <GeoJsonLayer
            id="state-parking-sites"
            data={stateParkingSitesData}
            pickable={true}
            wireframe={false}
            pointType={"icon"}
            getIcon={() => ({
              url: truckParkingIconSvg,
              width: 24,
              height: 24,
            })}
            getIconSize={1}
            getIconColor={(d) => [Math.sqrt(d.exits), 140, 0]}
            getIconAngle={0}
            getIconPixelOffset={[0, 0]}
            iconSizeUnits={"pixels"}
            iconSizeScale={15}
          />
        )}
        {commercialBorderCrossingsData !== null &&
          showCommercialBorderCrossings && (
            <GeoJsonLayer
              id="commercial-border-crossings"
              data={commercialBorderCrossingsData}
              pickable={true}
              wireframe={false}
              pointType={"icon"}
              getIcon={() => ({
                url: commercialBorderCrossingIconSvg,
                width: 24,
                height: 24,
              })}
              getIconSize={1}
              getIconColor={(d) => [Math.sqrt(d.exits), 140, 0]}
              getIconAngle={0}
              getIconPixelOffset={[0, 0]}
              iconSizeUnits={"pixels"}
              iconSizeScale={15}
            />
          )}
        {railBorderCrossingsData !== null && showRailBorderCrossings && (
          <GeoJsonLayer
            id="rail-border-crossings"
            data={railBorderCrossingsData}
            pickable={true}
            wireframe={false}
            pointType={"icon"}
            getIcon={() => ({
              url: railBorderCrossingIconSvg,
              width: 24,
              height: 24,
            })}
            getIconSize={1}
            getIconColor={(d) => [Math.sqrt(d.exits), 140, 0]}
            getIconAngle={0}
            getIconPixelOffset={[0, 0]}
            iconSizeUnits={"pixels"}
            iconSizeScale={15}
          />
        )}
        {cargoAirportsData !== null && showCargoAirports && (
          <GeoJsonLayer
            id="cargo-airports"
            data={cargoAirportsData}
            pickable={true}
            wireframe={false}
            pointType={"icon"}
            getIcon={() => ({
              url: cargoAirportsIconSvg,
              width: 24,
              height: 24,
            })}
            getIconSize={1}
            getIconColor={(d) => [Math.sqrt(d.exits), 140, 0]}
            getIconAngle={0}
            getIconPixelOffset={[0, 0]}
            iconSizeUnits={"pixels"}
            iconSizeScale={15}
          />
        )}
        {portsData !== null && showPorts && (
          <GeoJsonLayer
            id="ports"
            data={portsData}
            pickable={true}
            wireframe={false}
            pointType={"icon"}
            getIcon={() => ({
              url: portsIconSvg,
              width: 24,
              height: 24,
            })}
            getIconSize={1}
            getIconColor={(d) => [Math.sqrt(d.exits), 140, 0]}
            getIconAngle={0}
            getIconPixelOffset={[0, 0]}
            iconSizeUnits={"pixels"}
            iconSizeScale={15}
          />
        )}

        <NavigationControl style={NAV_CONTROL_STYLE} captureScroll={true} />

        <div style={layers_CONTROL_STYLE}>
          <LayerControls
            baseMap={baseMap}
            setBaseMap={setBaseMap}
            dataLayers={dataLayers}
          />
        </div>

        <StateMapLegend />
        <ScaleControl style={ScaleControl_STYLE} />
      </DeckGL>
    </div>
  );
}

export default DeckglMapH3;
