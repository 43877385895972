/** see https://upmostly.com/tutorials/how-to-use-the-usecontext-hook-in-react*/

import React, { createContext, useReducer } from "react";
import {
  sitesYearsMonths,
  yearsMonths,
} from "../common/constants";

const initialState = {
  popUpEnabled: true,
  highlightQuery: function (feature) {
    return false;
  },
  regionParkingData: [],
  regionParkingDataType: 0,
  regionCategoryTableData: {},
  selectedDayType: "All Daytypes",
  selectedVehicleWeightClass: 0,
  selectedSite: {
    selected: "All",
    year: sitesYearsMonths.at(-1).year,
    month: sitesYearsMonths.at(-1).month,
    yearMonth: sitesYearsMonths.at(-1).yearMonth,
    parkingDataType: 0,
    parkingDurationCategories: [1, 2, 3, 4, 5],
  },
  statewide: {
    year: yearsMonths.at(-1).year,
    month: yearsMonths.at(-1).month,
    yearMonth: yearsMonths.at(-1).yearMonth,
  },
  selectedComponents: {
    Nday: false,
    Nhour: false,
    Sday: false,
    Shour: false,
    Eday: false,
    Ehour: false,
    Wday: false,
    Whour: false,
    parkingEvents: false,
    utilizationByDay: false,
    utilizationByHour: false,
  },
  stateMapGridResolution: 6,
  mapOpacity: 0.8,
  cardAttributes: {
    usBorder: "active",
    disruptiveEvents: "inactive",
    freightGenerators: "inactive",
  },
  // what geo filter options to use based on 'geoOptions' object in constants.js ('statewide', 'district', 'mpo', or 'corridor')
  geoFilterMode: "statewide",
  // what options to filter by (could be a single MPO value (number), district value (number), or corridor name (string))
  geoFilterOption: "",
  regionLookup: "State",
  parkingDurationCategories: [1, 2, 3, 4, 5],
};

const QueryContext = createContext(null);

const queryReducer = (state, action) => {
  switch (action.type) {
    case "setRegionParkingData":
      return {
        ...state,
        regionParkingData: action.selection,
      };
    case "setRegionParkingDataType":
      return {
        ...state,
        regionParkingDataType: action.selection,
      };
    case "setRegionCategoryTableData":
      return {
        ...state,
        regionCategoryTableData: action.selection,
      };
    case "setSelectedVehicleWeightClass":
      return {
        ...state,
        selectedVehicleWeightClass: action.selection,
      };
    case "setSelectedDayType":
      return {
        ...state,
        selectedDayType: action.selection,
      };
    case "setSelectedSite":
      return {
        ...state,
        selectedSite: action.selection,
      };
    case "statewide":
      return {
        ...state,
        statewide: action.selection,
      };
    case "setToggle":
      return {
        ...state,
        selectedComponents: action.selection,
      };
    case "setStateMapGridResolution":
      return {
        ...state,
        stateMapGridResolution: action.selection,
      };
    case "setMapOpacity":
      return {
        ...state,
        mapOpacity: action.selection,
      };
    case "setCardAttributes":
      return {
        ...state,
        cardAttributes: action.selection,
      };
    case "setGeoFilterMode":
      return {
        ...state,
        geoFilterMode: action.selection,
      };
    case "setGeoFilterOption":
      return {
        ...state,
        geoFilterOption: action.selection,
      };
    case "setRegionLookup":
      return {
        ...state,
        regionLookup: action.selection,
      };
    default:
      throw new Error("error in query");
  }
};

const QueryProvider = (props) => {
  const [state, dispatch] = useReducer(queryReducer, initialState);
  return (
    <QueryContext.Provider value={[state, dispatch]}>
      {props.children}
    </QueryContext.Provider>
  );
};

export { QueryContext, QueryProvider };
