import React, { useState } from "react";

import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";

const ScrollArrow = () => {
  const [showScroll, setShowScroll] = useState(false);

  const checkScrollTop = () => {
    if (!showScroll && window.scrollY > 300) {
      setShowScroll(true);
    } else if (showScroll && window.scrollY <= 300) {
      setShowScroll(false);
    }
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  window.addEventListener("scroll", checkScrollTop);

  return (
    <Grid sx={{ display: showScroll ? "block" : "none" }}>
      <IconButton aria-label="Scoll to top" onClick={scrollTop}>
        <ArrowCircleUpIcon className="optionsBar--scrollTop" />
      </IconButton>
    </Grid>
  );
};

export default ScrollArrow;
