export const parkingSites = [
  {
    id: 1,
    name: "El Paso County",
    dir: ['E', 'W'],
    type: "Public",
    districtName: "El Paso",
    districtNumber: 24,
    mpoName: "El Paso",
    mpoNumber: 66,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 2,
    name: "Fabens Speedway",
    dir: ['B'],
    type: "Private",
    districtName: "El Paso",
    districtNumber: 24,
    mpoName: "El Paso",
    mpoNumber: 66,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 3,
    name: "Culberson County",
    dir: ['E', 'W'],
    type: "Public",
    districtName: "El Paso",
    districtNumber: 24,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 4,
    name: "Van Horn Loves Travel Stop",
    dir: ['B'],
    type: "Private",
    districtName: "El Paso",
    districtNumber: 24,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 5,
    name: "Van Horn Pilot Travel Center Vanhorn Dr",
    dir: ['B'],
    type: "Private",
    districtName: "El Paso",
    districtNumber: 24,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 6,
    name: "Chevron Van Horn",
    dir: ['B'],
    type: "Private",
    districtName: "El Paso",
    districtNumber: 24,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 7,
    name: "Etholen Exxon",
    dir: ['B'],
    type: "Private",
    districtName: "El Paso",
    districtNumber: 24,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 8,
    name: "El Paso Petro Travle Center Stockyard Dr",
    dir: ['B'],
    type: "Private",
    districtName: "El Paso",
    districtNumber: 24,
    mpoName: "El Paso",
    mpoNumber: 66,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 9,
    name: "El Paso Flying J Travel Center",
    dir: ['B'],
    type: "Private",
    districtName: "El Paso",
    districtNumber: 24,
    mpoName: "El Paso",
    mpoNumber: 66,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 10,
    name: "El Paso Loves Travel Stop",
    dir: ['B'],
    type: "Private",
    districtName: "El Paso",
    districtNumber: 24,
    mpoName: "El Paso",
    mpoNumber: 66,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 11,
    name: "El Paso Speedway I10",
    dir: ['B'],
    type: "Private",
    districtName: "El Paso",
    districtNumber: 24,
    mpoName: "El Paso",
    mpoNumber: 66,
    corridor_interstate_name: null,
  },
  {
    id: 12,
    name: "El Paso Ta Express Travle Center Westway Blvd",
    dir: ['B'],
    type: "Private",
    districtName: "El Paso",
    districtNumber: 24,
    mpoName: "El Paso",
    mpoNumber: 66,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 13,
    name: "El Paso Ta Express Travel Center Kingsway Dr",
    dir: ['B'],
    type: "Private",
    districtName: "El Paso",
    districtNumber: 24,
    mpoName: "El Paso",
    mpoNumber: 66,
    corridor_interstate_name: null,
  },
  {
    id: 14,
    name: "Anthony Pilot Travel Center",
    dir: ['B'],
    type: "Private",
    districtName: "El Paso",
    districtNumber: 24,
    mpoName: "El Paso",
    mpoNumber: 66,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 15,
    name: "Anthony Flying J Travel Center",
    dir: ['B'],
    type: "Private",
    districtName: "El Paso",
    districtNumber: 24,
    mpoName: "El Paso",
    mpoNumber: 66,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 16,
    name: "Anthony Loves Truck Stop",
    dir: ['B'],
    type: "Private",
    districtName: "El Paso",
    districtNumber: 24,
    mpoName: "El Paso",
    mpoNumber: 66,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 17,
    name: "Texas TIC Anthony",
    dir: ['S'],
    type: "Public",
    districtName: "El Paso",
    districtNumber: 24,
    mpoName: "El Paso",
    mpoNumber: 66,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 18,
    name: "Esperanza Traveling Tiger Center",
    dir: ['B'],
    type: "Private",
    districtName: "El Paso",
    districtNumber: 24,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 19,
    name: "Etholen",
    dir: ['E', 'W'],
    type: "Public",
    districtName: "El Paso",
    districtNumber: 24,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 20,
    name: "Plateau Truck Stop",
    dir: ['B'],
    type: "Private",
    districtName: "El Paso",
    districtNumber: 24,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 21,
    name: "Fort Davis",
    dir: ['E', 'W'],
    type: "Public",
    districtName: "El Paso",
    districtNumber: 24,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 22,
    name: "Saragosa Uncles Gas Station",
    dir: ['B'],
    type: "Private",
    districtName: "Odessa",
    districtNumber: 6,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 23,
    name: "Pecos West County",
    dir: ['E', 'W'],
    type: "Public",
    districtName: "Odessa",
    districtNumber: 6,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 24,
    name: "Toyah",
    dir: ['E', 'W'],
    type: "Public",
    districtName: "Odessa",
    districtNumber: 6,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0020-KG",
  },
  {
    id: 25,
    name: "Pecos Sunoco Bickley",
    dir: ['B'],
    type: "Private",
    districtName: "Odessa",
    districtNumber: 6,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 26,
    name: "Pecos Pilot Travel Center California Ave",
    dir: ['B'],
    type: "Private",
    districtName: "Odessa",
    districtNumber: 6,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0020-KG",
  },
  {
    id: 27,
    name: "Pecos Stripes Lincoln St",
    dir: ['B'],
    type: "Private",
    districtName: "Odessa",
    districtNumber: 6,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0020-KG",
  },
  {
    id: 28,
    name: "Pecos Flying J Travel Center",
    dir: ['B'],
    type: "Private",
    districtName: "Odessa",
    districtNumber: 6,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0020-KG",
  },
  {
    id: 29,
    name: "Pecos Loves Travel Stop",
    dir: ['B'],
    type: "Private",
    districtName: "Odessa",
    districtNumber: 6,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0020-KG",
  },
  {
    id: 30,
    name: "Orla Pilot Travel Center",
    dir: ['B'],
    type: "Private",
    districtName: "Odessa",
    districtNumber: 6,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 31,
    name: "Ward County",
    dir: ['E', 'W'],
    type: "Public",
    districtName: "Odessa",
    districtNumber: 6,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0020-KG",
  },
  {
    id: 32,
    name: "Wickett Allsups I20",
    dir: ['B'],
    type: "Private",
    districtName: "Odessa",
    districtNumber: 6,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0020-KG",
  },
  {
    id: 33,
    name: "Wickett Main Street Market 1219",
    dir: ['B'],
    type: "Private",
    districtName: "Odessa",
    districtNumber: 6,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0020-KG",
  },
  {
    id: 34,
    name: "Thorntonville Pilot Travel Center",
    dir: ['B'],
    type: "Private",
    districtName: "Odessa",
    districtNumber: 6,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0020-KG",
  },
  {
    id: 35,
    name: "Thorntonville Kenk Kwik",
    dir: ['B'],
    type: "Private",
    districtName: "Odessa",
    districtNumber: 6,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0020-KG",
  },
  {
    id: 36,
    name: "Kermit Pilot Travel Center",
    dir: ['B'],
    type: "Private",
    districtName: "Odessa",
    districtNumber: 6,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 37,
    name: "West Odessa Pilot Travel Center Meteor Crater Rd",
    dir: ['B'],
    type: "Private",
    districtName: "Odessa",
    districtNumber: 6,
    mpoName: "Permian Basin",
    mpoNumber: 174,
    corridor_interstate_name: "IH0020-KG",
  },
  {
    id: 38,
    name: "West Odessa Road Ranger",
    dir: ['B'],
    type: "Private",
    districtName: "Odessa",
    districtNumber: 6,
    mpoName: "Permian Basin",
    mpoNumber: 174,
    corridor_interstate_name: "IH0020-KG",
  },
  {
    id: 39,
    name: "Odessa Loves Travel Stop",
    dir: ['B'],
    type: "Private",
    districtName: "Odessa",
    districtNumber: 6,
    mpoName: "Permian Basin",
    mpoNumber: 174,
    corridor_interstate_name: "IH0020-KG",
  },
  {
    id: 40,
    name: "West Odessa Stripes County Rd W",
    dir: ['B'],
    type: "Private",
    districtName: "Odessa",
    districtNumber: 6,
    mpoName: "Permian Basin",
    mpoNumber: 174,
    corridor_interstate_name: "IH0020-KG",
  },
  {
    id: 41,
    name: "Odessa Pilot Cardlock",
    dir: ['B'],
    type: "Private",
    districtName: "Odessa",
    districtNumber: 6,
    mpoName: "Permian Basin",
    mpoNumber: 174,
    corridor_interstate_name: "IH0020-KG",
  },
  {
    id: 42,
    name: "Odessa Flying J Travel Center I20",
    dir: ['B'],
    type: "Private",
    districtName: "Odessa",
    districtNumber: 6,
    mpoName: "Permian Basin",
    mpoNumber: 174,
    corridor_interstate_name: "IH0020-KG",
  },
  {
    id: 43,
    name: "West Odessa Stripes Kermit Hwy",
    dir: ['B'],
    type: "Private",
    districtName: "Odessa",
    districtNumber: 6,
    mpoName: "Permian Basin",
    mpoNumber: 174,
    corridor_interstate_name: null,
  },
  {
    id: 44,
    name: "Midland Pilot Travel Center County Rd127",
    dir: ['B'],
    type: "Private",
    districtName: "Odessa",
    districtNumber: 6,
    mpoName: "Permian Basin",
    mpoNumber: 174,
    corridor_interstate_name: "IH0020-KG",
  },
  {
    id: 45,
    name: "Midland Loves Travel Center",
    dir: ['B'],
    type: "Private",
    districtName: "Odessa",
    districtNumber: 6,
    mpoName: "Permian Basin",
    mpoNumber: 174,
    corridor_interstate_name: "IH0020-KG",
  },
  {
    id: 46,
    name: "Midland Stripes Midkiff",
    dir: ['B'],
    type: "Private",
    districtName: "Odessa",
    districtNumber: 6,
    mpoName: "Permian Basin",
    mpoNumber: 174,
    corridor_interstate_name: null,
  },
  {
    id: 47,
    name: "Midland Exxon Hwy20",
    dir: ['B'],
    type: "Private",
    districtName: "Odessa",
    districtNumber: 6,
    mpoName: "Permian Basin",
    mpoNumber: 174,
    corridor_interstate_name: "IH0020-KG",
  },
  {
    id: 48,
    name: "Midland Pilot",
    dir: ['B'],
    type: "Private",
    districtName: "Odessa",
    districtNumber: 6,
    mpoName: "Permian Basin",
    mpoNumber: 174,
    corridor_interstate_name: "IH0020-KG",
  },
  {
    id: 49,
    name: "Midland Sunoco Garden City Hwy",
    dir: ['B'],
    type: "Private",
    districtName: "Odessa",
    districtNumber: 6,
    mpoName: "Permian Basin",
    mpoNumber: 174,
    corridor_interstate_name: "IH0020-KG",
  },
  {
    id: 50,
    name: "Midland Stripes Travel Plaza Garden City Hwy",
    dir: ['B'],
    type: "Private",
    districtName: "Odessa",
    districtNumber: 6,
    mpoName: "Permian Basin",
    mpoNumber: 174,
    corridor_interstate_name: "IH0020-KG",
  },
  {
    id: 51,
    name: "Midland Flying J Travel Center 158",
    dir: ['B'],
    type: "Private",
    districtName: "Odessa",
    districtNumber: 6,
    mpoName: "Permian Basin",
    mpoNumber: 174,
    corridor_interstate_name: "IH0020-KG",
  },
  {
    id: 52,
    name: "Midland CS Plaza",
    dir: ['E', 'W'],
    type: "Public",
    districtName: "Odessa",
    districtNumber: 6,
    mpoName: "Permian Basin",
    mpoNumber: 174,
    corridor_interstate_name: "IH0020-KG",
  },
  {
    id: 53,
    name: "Crane Pilot Fuel Stop",
    dir: ['B'],
    type: "Private",
    districtName: "Odessa",
    districtNumber: 6,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 54,
    name: "Fort Stockton Stripes Truck Stop 285",
    dir: ['B'],
    type: "Private",
    districtName: "Odessa",
    districtNumber: 6,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 55,
    name: "Fort Stockton Flying J Travel Center",
    dir: ['B'],
    type: "Private",
    districtName: "Odessa",
    districtNumber: 6,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 56,
    name: "Fort Stockton Chevron I10",
    dir: ['B'],
    type: "Private",
    districtName: "Odessa",
    districtNumber: 6,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 57,
    name: "Fort Stockton Loves Truck Care",
    dir: ['B'],
    type: "Private",
    districtName: "Odessa",
    districtNumber: 6,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 58,
    name: "Fort Stockton Abandoned 77",
    dir: ['B'],
    type: "Private",
    districtName: "Odessa",
    districtNumber: 6,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 59,
    name: "Fort Stockton",
    dir: ['E'],
    type: "Public",
    districtName: "Odessa",
    districtNumber: 6,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 60,
    name: "Pecos East County",
    dir: ['N', 'S'],
    type: "Public",
    districtName: "Odessa",
    districtNumber: 6,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 61,
    name: "Ozona",
    dir: ['E', 'W'],
    type: "Public",
    districtName: "San Angelo",
    districtNumber: 7,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 62,
    name: "Alpine",
    dir: ['N'],
    type: "Public",
    districtName: "El Paso",
    districtNumber: 24,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 63,
    name: "Sanderson Stripes",
    dir: ['B'],
    type: "Private",
    districtName: "Odessa",
    districtNumber: 6,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 64,
    name: "Big Lake Pilot Travel Center",
    dir: ['B'],
    type: "Private",
    districtName: "San Angelo",
    districtNumber: 7,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 65,
    name: "Andrews Loves Travel Stop",
    dir: ['B'],
    type: "Private",
    districtName: "Odessa",
    districtNumber: 6,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 66,
    name: "Andres Strips Mustang Dr",
    dir: ['B'],
    type: "Private",
    districtName: "Odessa",
    districtNumber: 6,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 67,
    name: "Andrews Pilot",
    dir: ['B'],
    type: "Private",
    districtName: "Odessa",
    districtNumber: 6,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 68,
    name: "Andrews County",
    dir: ['N'],
    type: "Public",
    districtName: "Odessa",
    districtNumber: 6,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 69,
    name: "Seminole Harts Corner Travel Center",
    dir: ['B'],
    type: "Private",
    districtName: "Lubbock",
    districtNumber: 5,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 70,
    name: "Denver City Stripes Santa Fe",
    dir: ['B'],
    type: "Private",
    districtName: "Lubbock",
    districtNumber: 5,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 71,
    name: "Lamesa Yesway Seminole",
    dir: ['B'],
    type: "Private",
    districtName: "Lubbock",
    districtNumber: 5,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "Ports-to-Plains",
  },
  {
    id: 72,
    name: "Brownfield Yesway Lanny Ave",
    dir: ['B'],
    type: "Private",
    districtName: "Lubbock",
    districtNumber: 5,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 73,
    name: "Ropesville Yesway",
    dir: ['B'],
    type: "Private",
    districtName: "Lubbock",
    districtNumber: 5,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 74,
    name: "Chisum Yesway Travel Center",
    dir: ['B'],
    type: "Private",
    districtName: "Lubbock",
    districtNumber: 5,
    mpoName: "Lubbock",
    mpoNumber: 122,
    corridor_interstate_name: null,
  },
  {
    id: 75,
    name: "Lubbock Yesway 46th St",
    dir: ['B'],
    type: "Private",
    districtName: "Lubbock",
    districtNumber: 5,
    mpoName: "Lubbock",
    mpoNumber: 122,
    corridor_interstate_name: "IH0027-KG",
  },
  {
    id: 76,
    name: "Lubbock Phillips66 Avenue A",
    dir: ['B'],
    type: "Private",
    districtName: "Lubbock",
    districtNumber: 5,
    mpoName: "Lubbock",
    mpoNumber: 122,
    corridor_interstate_name: "IH0027-KG",
  },
  {
    id: 77,
    name: "Lubbock Flying J Travel Center",
    dir: ['B'],
    type: "Private",
    districtName: "Lubbock",
    districtNumber: 5,
    mpoName: "Lubbock",
    mpoNumber: 122,
    corridor_interstate_name: "IH0027-KG",
  },
  {
    id: 78,
    name: "Aeg Petroleum US62",
    dir: ['B'],
    type: "Private",
    districtName: "Lubbock",
    districtNumber: 5,
    mpoName: "Lubbock",
    mpoNumber: 122,
    corridor_interstate_name: null,
  },
  {
    id: 79,
    name: "Lubbock Loves Travel Stop",
    dir: ['B'],
    type: "Private",
    districtName: "Lubbock",
    districtNumber: 5,
    mpoName: "Lubbock",
    mpoNumber: 122,
    corridor_interstate_name: "IH0027-KG",
  },
  {
    id: 80,
    name: "New Deal Travel Center",
    dir: ['B'],
    type: "Private",
    districtName: "Lubbock",
    districtNumber: 5,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0027-KG",
  },
  {
    id: 81,
    name: "Hale County",
    dir: ['N', 'S'],
    type: "Public",
    districtName: "Lubbock",
    districtNumber: 5,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0027-KG",
  },
  {
    id: 82,
    name: "Littleifeld Stripes 385",
    dir: ['B'],
    type: "Private",
    districtName: "Lubbock",
    districtNumber: 5,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 83,
    name: "Plainview Cefco Travel Center",
    dir: ['B'],
    type: "Private",
    districtName: "Lubbock",
    districtNumber: 5,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 84,
    name: "Tulia",
    dir: ['N', 'S'],
    type: "Public",
    districtName: "Lubbock",
    districtNumber: 5,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0027-KG",
  },
  {
    id: 85,
    name: "Tulia Pilot Travel Center",
    dir: ['B'],
    type: "Private",
    districtName: "Lubbock",
    districtNumber: 5,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0027-KG",
  },
  {
    id: 86,
    name: "Canyon",
    dir: ['N', 'S'],
    type: "Public",
    districtName: "Amarillo",
    districtNumber: 4,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0027-KG",
  },
  {
    id: 87,
    name: "Amarillo Loves Travel Stop Hollywood Rd",
    dir: ['B'],
    type: "Private",
    districtName: "Amarillo",
    districtNumber: 4,
    mpoName: "Amarillo",
    mpoNumber: 120,
    corridor_interstate_name: "IH0027-KG",
  },
  {
    id: 88,
    name: "Amarillo Loves Travel Stop Arnot Rd",
    dir: ['B'],
    type: "Private",
    districtName: "Amarillo",
    districtNumber: 4,
    mpoName: "Amarillo",
    mpoNumber: 120,
    corridor_interstate_name: "IH0040-KG",
  },
  {
    id: 89,
    name: "Vega Pilot Travel Center I40",
    dir: ['B'],
    type: "Private",
    districtName: "Amarillo",
    districtNumber: 4,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0040-KG",
  },
  {
    id: 90,
    name: "Vega Travel Center",
    dir: ['B'],
    type: "Private",
    districtName: "Amarillo",
    districtNumber: 4,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 91,
    name: "Adrian Midway 66",
    dir: ['B'],
    type: "Private",
    districtName: "Amarillo",
    districtNumber: 4,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 92,
    name: "Amarillo Ta Travel Center Whitaker Rd",
    dir: ['B'],
    type: "Private",
    districtName: "Amarillo",
    districtNumber: 4,
    mpoName: "Amarillo",
    mpoNumber: 120,
    corridor_interstate_name: "IH0040-KG",
  },
  {
    id: 93,
    name: "Amarillo Loves Travel Stop Whitaker Rd",
    dir: ['B'],
    type: "Private",
    districtName: "Amarillo",
    districtNumber: 4,
    mpoName: "Amarillo",
    mpoNumber: 120,
    corridor_interstate_name: "IH0040-KG",
  },
  {
    id: 94,
    name: "Amarillo Petro Fuel Island",
    dir: ['B'],
    type: "Private",
    districtName: "Amarillo",
    districtNumber: 4,
    mpoName: "Amarillo",
    mpoNumber: 120,
    corridor_interstate_name: "IH0040-KG",
  },
  {
    id: 95,
    name: "Amarillo Pilot Travel Center Lakeside",
    dir: ['B'],
    type: "Private",
    districtName: "Amarillo",
    districtNumber: 4,
    mpoName: "Amarillo",
    mpoNumber: 120,
    corridor_interstate_name: "IH0040-KG",
  },
  {
    id: 96,
    name: "Amarillo Flying J Travel Center",
    dir: ['B'],
    type: "Private",
    districtName: "Amarillo",
    districtNumber: 4,
    mpoName: "Amarillo",
    mpoNumber: 120,
    corridor_interstate_name: "IH0040-KG",
  },
  {
    id: 97,
    name: "Texas TIC Amarillo",
    dir: ['E'],
    type: "Public",
    districtName: "Amarillo",
    districtNumber: 4,
    mpoName: "Amarillo",
    mpoNumber: 120,
    corridor_interstate_name: "IH0040-KG",
  },
  {
    id: 98,
    name: "Amarillo National Truck Stop Amarillo",
    dir: ['B'],
    type: "Private",
    districtName: "Amarillo",
    districtNumber: 4,
    mpoName: "Amarillo",
    mpoNumber: 120,
    corridor_interstate_name: "IH0040-KG",
  },
  {
    id: 99,
    name: "Amarillo Loves Travel Stop 207",
    dir: ['B'],
    type: "Private",
    districtName: "Amarillo",
    districtNumber: 4,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0040-KG",
  },
  {
    id: 100,
    name: "Claude Taylors Truck Stop",
    dir: ['B'],
    type: "Private",
    districtName: "Amarillo",
    districtNumber: 4,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 101,
    name: "Dumas Toot-n-Totum",
    dir: ['B'],
    type: "Private",
    districtName: "Amarillo",
    districtNumber: 4,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "Ports-to-Plains",
  },
  {
    id: 102,
    name: "Dumas Loves Travel Stop",
    dir: ['B'],
    type: "Private",
    districtName: "Amarillo",
    districtNumber: 4,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "Ports-to-Plains",
  },
  {
    id: 103,
    name: "Dalhart Toot-n-Totum",
    dir: ['B'],
    type: "Private",
    districtName: "Amarillo",
    districtNumber: 4,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "Ports-to-Plains",
  },
  {
    id: 104,
    name: "Stratford Allsups US287",
    dir: ['B'],
    type: "Private",
    districtName: "Amarillo",
    districtNumber: 4,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "Ports-to-Plains",
  },
  {
    id: 105,
    name: "Stratford Pilot Travel Center Texas St",
    dir: ['B'],
    type: "Private",
    districtName: "Amarillo",
    districtNumber: 4,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "Ports-to-Plains",
  },
  {
    id: 106,
    name: "Stratford Toot-n-Totum",
    dir: ['B'],
    type: "Private",
    districtName: "Amarillo",
    districtNumber: 4,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "Ports-to-Plains",
  },
  {
    id: 107,
    name: "Texline Valero US87",
    dir: ['B'],
    type: "Private",
    districtName: "Amarillo",
    districtNumber: 4,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 108,
    name: "Friona Fast Stop Truck Wash",
    dir: ['B'],
    type: "Private",
    districtName: "Lubbock",
    districtNumber: 5,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 109,
    name: "Ralls Stripes",
    dir: ['B'],
    type: "Private",
    districtName: "Lubbock",
    districtNumber: 5,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 110,
    name: "Crosby County",
    dir: ['E'],
    type: "Public",
    districtName: "Lubbock",
    districtNumber: 5,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 111,
    name: "Post Allsups US84",
    dir: ['B'],
    type: "Private",
    districtName: "Lubbock",
    districtNumber: 5,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 112,
    name: "Bigspring",
    dir: ['E', 'W'],
    type: "Public",
    districtName: "Abilene",
    districtNumber: 8,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0020-KG",
  },
  {
    id: 113,
    name: "Big Spring Stripes",
    dir: ['B'],
    type: "Private",
    districtName: "Abilene",
    districtNumber: 8,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0020-KG",
  },
  {
    id: 114,
    name: "Big Spring Ta Travel Center 87",
    dir: ['B'],
    type: "Private",
    districtName: "Abilene",
    districtNumber: 8,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0020-KG",
  },
  {
    id: 115,
    name: "Big Spring Pate Trucking",
    dir: ['B'],
    type: "Private",
    districtName: "Abilene",
    districtNumber: 8,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0020-KG",
  },
  {
    id: 116,
    name: "Big Spring Pilot Travel Center 12th St",
    dir: ['B'],
    type: "Private",
    districtName: "Abilene",
    districtNumber: 8,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0020-KG",
  },
  {
    id: 117,
    name: "Howard County",
    dir: ['E'],
    type: "Public",
    districtName: "Abilene",
    districtNumber: 8,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0020-KG",
  },
  {
    id: 118,
    name: "Snyder Rip Griffin Travel Center",
    dir: ['B'],
    type: "Private",
    districtName: "Abilene",
    districtNumber: 8,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 119,
    name: "M And M Fuels US84",
    dir: ['B'],
    type: "Private",
    districtName: "Abilene",
    districtNumber: 8,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 120,
    name: "Mitchell County",
    dir: ['W'],
    type: "Public",
    districtName: "Abilene",
    districtNumber: 8,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0020-KG",
  },
  {
    id: 121,
    name: "Colorado City Stripes",
    dir: ['B'],
    type: "Private",
    districtName: "Abilene",
    districtNumber: 8,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0020-KG",
  },
  {
    id: 122,
    name: "Loraine",
    dir: ['E', 'W'],
    type: "Public",
    districtName: "Abilene",
    districtNumber: 8,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0020-KG",
  },
  {
    id: 123,
    name: "Sterling City Stripes",
    dir: ['B'],
    type: "Private",
    districtName: "San Angelo",
    districtNumber: 7,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "Ports-to-Plains",
  },
  {
    id: 124,
    name: "Sterling City Allsups US87",
    dir: ['B'],
    type: "Private",
    districtName: "San Angelo",
    districtNumber: 7,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "Ports-to-Plains",
  },
  {
    id: 125,
    name: "Coke County",
    dir: ['S'],
    type: "Public",
    districtName: "San Angelo",
    districtNumber: 7,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "Ports-to-Plains",
  },
  {
    id: 126,
    name: "San Angelo Stripes Ben Fricklin",
    dir: ['B'],
    type: "Private",
    districtName: "San Angelo",
    districtNumber: 7,
    mpoName: "San Angelo",
    mpoNumber: 208,
    corridor_interstate_name: "Ports-to-Plains",
  },
  {
    id: 127,
    name: "San Angelo Stripes Bell",
    dir: ['B'],
    type: "Private",
    districtName: "San Angelo",
    districtNumber: 7,
    mpoName: "San Angelo",
    mpoNumber: 208,
    corridor_interstate_name: null,
  },
  {
    id: 128,
    name: "Paducah",
    dir: ['N'],
    type: "Public",
    districtName: "Childress",
    districtNumber: 25,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 129,
    name: "Childres Pilot Travel Center 287",
    dir: ['B'],
    type: "Private",
    districtName: "Childress",
    districtNumber: 25,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 130,
    name: "Childress Cefco Hwy287",
    dir: ['B'],
    type: "Private",
    districtName: "Childress",
    districtNumber: 25,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 131,
    name: "Estelline",
    dir: ['N'],
    type: "Public",
    districtName: "Childress",
    districtNumber: 25,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 132,
    name: "Memphis Loves Travel Center",
    dir: ['B'],
    type: "Private",
    districtName: "Childress",
    districtNumber: 25,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 133,
    name: "Donley County",
    dir: ['N', 'S'],
    type: "Public",
    districtName: "Childress",
    districtNumber: 25,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0040-KG",
  },
  {
    id: 134,
    name: "Donley County I40",
    dir: ['E'],
    type: "Public",
    districtName: "Childress",
    districtNumber: 25,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0040-KG",
  },
  {
    id: 135,
    name: "Gray County",
    dir: ['W'],
    type: "Public",
    districtName: "Amarillo",
    districtNumber: 4,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0040-KG",
  },
  {
    id: 136,
    name: "Perryton Kent Kwik",
    dir: ['B'],
    type: "Private",
    districtName: "Amarillo",
    districtNumber: 4,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 137,
    name: "Canadian Oasis Truck Stop",
    dir: ['B'],
    type: "Private",
    districtName: "Amarillo",
    districtNumber: 4,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 138,
    name: "Higgins Percs Truck Stop",
    dir: ['B'],
    type: "Private",
    districtName: "Amarillo",
    districtNumber: 4,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 139,
    name: "Guthrie",
    dir: ['N'],
    type: "Public",
    districtName: "Childress",
    districtNumber: 25,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 140,
    name: "Sylvester Empty Lot FM57",
    dir: ['B'],
    type: "Private",
    districtName: "Abilene",
    districtNumber: 8,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 141,
    name: "Sweetwater Dirt Patch",
    dir: ['B'],
    type: "Private",
    districtName: "Abilene",
    districtNumber: 8,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 142,
    name: "Sweet Water Loves Travel Stop",
    dir: ['B'],
    type: "Private",
    districtName: "Abilene",
    districtNumber: 8,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0020-KG",
  },
  {
    id: 143,
    name: "Sweetwater Ta Travel Center Rd143",
    dir: ['B'],
    type: "Private",
    districtName: "Abilene",
    districtNumber: 8,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0020-KG",
  },
  {
    id: 144,
    name: "Nolan County",
    dir: ['E', 'W'],
    type: "Public",
    districtName: "Abilene",
    districtNumber: 8,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0020-KG",
  },
  {
    id: 145,
    name: "Bronte Stripes",
    dir: ['B'],
    type: "Private",
    districtName: "San Angelo",
    districtNumber: 7,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 146,
    name: "Ozona Fuel America Travel Center",
    dir: ['B'],
    type: "Private",
    districtName: "San Angelo",
    districtNumber: 7,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 147,
    name: "Sutton County",
    dir: ['E', 'W'],
    type: "Public",
    districtName: "San Angelo",
    districtNumber: 7,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 148,
    name: "Sonora Jmc Transport",
    dir: ['B'],
    type: "Private",
    districtName: "San Angelo",
    districtNumber: 7,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 149,
    name: "Sonora Loves Travel Stop",
    dir: ['B'],
    type: "Private",
    districtName: "San Angelo",
    districtNumber: 7,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 150,
    name: "Fabrica Minits3",
    dir: ['B'],
    type: "Private",
    districtName: "Laredo",
    districtNumber: 22,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 151,
    name: "Eagle Pass Minits Pilot Flying J",
    dir: ['B'],
    type: "Private",
    districtName: "Laredo",
    districtNumber: 22,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "Ports-to-Plains",
  },
  {
    id: 152,
    name: "Eagle Pass Stripes",
    dir: ['B'],
    type: "Private",
    districtName: "Laredo",
    districtNumber: 22,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 153,
    name: "Sonora",
    dir: ['E', 'W'],
    type: "Public",
    districtName: "San Angelo",
    districtNumber: 7,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 154,
    name: "Collingsworth",
    dir: ['S'],
    type: "Public",
    districtName: "Childress",
    districtNumber: 25,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 155,
    name: "Shamrock Conoco",
    dir: ['B'],
    type: "Private",
    districtName: "Childress",
    districtNumber: 25,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0040-KG",
  },
  {
    id: 156,
    name: "Shamrock Cefco Travel Center",
    dir: ['B'],
    type: "Private",
    districtName: "Childress",
    districtNumber: 25,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 157,
    name: "Quanah Loves Travel Stop",
    dir: ['B'],
    type: "Private",
    districtName: "Childress",
    districtNumber: 25,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 158,
    name: "Hardeman County",
    dir: ['E', 'W'],
    type: "Public",
    districtName: "Childress",
    districtNumber: 25,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 159,
    name: "Cernon Cefco Travel Center Bentley",
    dir: ['B'],
    type: "Private",
    districtName: "Wichita Falls",
    districtNumber: 3,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 160,
    name: "Stamford Yesway US277G",
    dir: ['B'],
    type: "Private",
    districtName: "Abilene",
    districtNumber: 8,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 161,
    name: "Haskell County",
    dir: ['S'],
    type: "Public",
    districtName: "Abilene",
    districtNumber: 8,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 162,
    name: "Merkel Allsups FM1235",
    dir: ['B'],
    type: "Private",
    districtName: "Abilene",
    districtNumber: 8,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0020-KG",
  },
  {
    id: 163,
    name: "Tye Flying J Travel Center",
    dir: ['B'],
    type: "Private",
    districtName: "Abilene",
    districtNumber: 8,
    mpoName: "Abilene",
    mpoNumber: 166,
    corridor_interstate_name: "IH0020-KG",
  },
  {
    id: 164,
    name: "Concho County",
    dir: ['E', 'W'],
    type: "Public",
    districtName: "San Angelo",
    districtNumber: 7,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "US87_US83",
  },
  {
    id: 165,
    name: "Ballinger Stripes",
    dir: ['B'],
    type: "Private",
    districtName: "San Angelo",
    districtNumber: 7,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 166,
    name: "Seymour Allsups US183",
    dir: ['B'],
    type: "Private",
    districtName: "Wichita Falls",
    districtNumber: 3,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 167,
    name: "Harrold Southwest Convenience Store",
    dir: ['B'],
    type: "Private",
    districtName: "Wichita Falls",
    districtNumber: 3,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 168,
    name: "Tuscola Empty Lot 84",
    dir: ['B'],
    type: "Private",
    districtName: "Abilene",
    districtNumber: 8,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 169,
    name: "Callahan County",
    dir: ['E', 'W'],
    type: "Public",
    districtName: "Abilene",
    districtNumber: 8,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0020-KG",
  },
  {
    id: 170,
    name: "Baird Loves Travel Stop",
    dir: ['B'],
    type: "Private",
    districtName: "Abilene",
    districtNumber: 8,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0020-KG",
  },
  {
    id: 171,
    name: "Junction",
    dir: ['N', 'S'],
    type: "Public",
    districtName: "San Angelo",
    districtNumber: 7,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 172,
    name: "Junction Pilot Travel Center",
    dir: ['B'],
    type: "Private",
    districtName: "San Angelo",
    districtNumber: 7,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 173,
    name: "Junction Timewise Food Store Travel Plaza",
    dir: ['B'],
    type: "Private",
    districtName: "San Angelo",
    districtNumber: 7,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 174,
    name: "Junction Genes Go Truck Stop",
    dir: ['B'],
    type: "Private",
    districtName: "San Angelo",
    districtNumber: 7,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 175,
    name: "Segovia 66 Truck Stop",
    dir: ['B'],
    type: "Private",
    districtName: "San Angelo",
    districtNumber: 7,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 176,
    name: "Brady Fuel Stop Brady Ts",
    dir: ['B'],
    type: "Private",
    districtName: "Brownwood",
    districtNumber: 23,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 177,
    name: "Brownwood Alon Hwy377",
    dir: ['B'],
    type: "Private",
    districtName: "Brownwood",
    districtNumber: 23,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 178,
    name: "Brownwood Stripes",
    dir: ['B'],
    type: "Private",
    districtName: "Brownwood",
    districtNumber: 23,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 179,
    name: "Crystal City Txb",
    dir: ['B'],
    type: "Private",
    districtName: "Laredo",
    districtNumber: 22,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 180,
    name: "Carrizo Springs Valero",
    dir: ['B'],
    type: "Private",
    districtName: "Laredo",
    districtNumber: 22,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "Ports-to-Plains",
  },
  {
    id: 181,
    name: "Catarina One Stop",
    dir: ['B'],
    type: "Private",
    districtName: "Laredo",
    districtNumber: 22,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "Ports-to-Plains",
  },
  {
    id: 182,
    name: "La Salle County",
    dir: ['N', 'S'],
    type: "Public",
    districtName: "Laredo",
    districtNumber: 22,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0035-KG",
  },
  {
    id: 183,
    name: "Encinal Road Ranger",
    dir: ['B'],
    type: "Private",
    districtName: "Laredo",
    districtNumber: 22,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0035-KG",
  },
  {
    id: 184,
    name: "Encinal Loves Travel Stop",
    dir: ['B'],
    type: "Private",
    districtName: "Laredo",
    districtNumber: 22,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0035-KG",
  },
  {
    id: 185,
    name: "Texas TIC Laredo",
    dir: ['N'],
    type: "Public",
    districtName: "Laredo",
    districtNumber: 22,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0035-KG",
  },
  {
    id: 186,
    name: "Laredo Western Express Wtcl",
    dir: ['B'],
    type: "Private",
    districtName: "Laredo",
    districtNumber: 22,
    mpoName: "Laredo Webb County Area",
    mpoNumber: 205,
    corridor_interstate_name: null,
  },
  {
    id: 187,
    name: "Laredo Exxon Mines Rd",
    dir: ['B'],
    type: "Private",
    districtName: "Laredo",
    districtNumber: 22,
    mpoName: "Laredo Webb County Area",
    mpoNumber: 205,
    corridor_interstate_name: "IH0035-KG",
  },
  {
    id: 188,
    name: "Laredo Pilot Travel Center Unitec Industrial Park",
    dir: ['B'],
    type: "Private",
    districtName: "Laredo",
    districtNumber: 22,
    mpoName: "Laredo Webb County Area",
    mpoNumber: 205,
    corridor_interstate_name: "IH0035-KG",
  },
  {
    id: 189,
    name: "Laredo Flying J Travel Center Unitech Industrial Park",
    dir: ['B'],
    type: "Private",
    districtName: "Laredo",
    districtNumber: 22,
    mpoName: "Laredo Webb County Area",
    mpoNumber: 205,
    corridor_interstate_name: "IH0035-KG",
  },
  {
    id: 190,
    name: "Laredo Ta Travel Center Mercury Dr",
    dir: ['B'],
    type: "Private",
    districtName: "Laredo",
    districtNumber: 22,
    mpoName: "Laredo Webb County Area",
    mpoNumber: 205,
    corridor_interstate_name: "IH0035-KG",
  },
  {
    id: 191,
    name: "Freer Stripes",
    dir: ['B'],
    type: "Private",
    districtName: "Laredo",
    districtNumber: 22,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "US59",
  },
  {
    id: 192,
    name: "Rio Bravo 7Eleven US83",
    dir: ['B'],
    type: "Private",
    districtName: "Laredo",
    districtNumber: 22,
    mpoName: "Laredo Webb County Area",
    mpoNumber: 205,
    corridor_interstate_name: null,
  },
  {
    id: 193,
    name: "Cotulla Stripes",
    dir: ['B'],
    type: "Private",
    districtName: "Laredo",
    districtNumber: 22,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0035-KG",
  },
  {
    id: 194,
    name: "Dilley Shell I35",
    dir: ['B'],
    type: "Private",
    districtName: "San Antonio",
    districtNumber: 15,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 195,
    name: "Pearsall Petro Travel Center",
    dir: ['B'],
    type: "Private",
    districtName: "San Antonio",
    districtNumber: 15,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0035-KG",
  },
  {
    id: 196,
    name: "Moore Road Ranger",
    dir: ['B'],
    type: "Private",
    districtName: "San Antonio",
    districtNumber: 15,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0035-KG",
  },
  {
    id: 197,
    name: "Amigos Country Corner",
    dir: ['B'],
    type: "Private",
    districtName: "San Antonio",
    districtNumber: 15,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 198,
    name: "Natalia Loves Travel Stop",
    dir: ['B'],
    type: "Private",
    districtName: "San Antonio",
    districtNumber: 15,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0035-KG",
  },
  {
    id: 199,
    name: "Medina County",
    dir: ['E', 'W', 'N', 'S'],
    type: "Public",
    districtName: "San Antonio",
    districtNumber: 15,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0035-KG",
  },
  {
    id: 200,
    name: "Von Ormy Pilot Travel Center",
    dir: ['B'],
    type: "Private",
    districtName: "San Antonio",
    districtNumber: 15,
    mpoName: "Alamo Area",
    mpoNumber: 28,
    corridor_interstate_name: "IH0035-KG",
  },
  {
    id: 201,
    name: "Von Ormy Tex Best Travel Center",
    dir: ['B'],
    type: "Private",
    districtName: "San Antonio",
    districtNumber: 15,
    mpoName: "Alamo Area",
    mpoNumber: 28,
    corridor_interstate_name: "IH0035-KG",
  },
  {
    id: 202,
    name: "Von Ormy Circlek Quintana",
    dir: ['B'],
    type: "Private",
    districtName: "San Antonio",
    districtNumber: 15,
    mpoName: "Alamo Area",
    mpoNumber: 28,
    corridor_interstate_name: null,
  },
  {
    id: 203,
    name: "Pleasanton Kuntry Korner",
    dir: ['B'],
    type: "Private",
    districtName: "San Antonio",
    districtNumber: 15,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0037-KG",
  },
  {
    id: 204,
    name: "Pleasanton Tex Best Travel Center",
    dir: ['B'],
    type: "Private",
    districtName: "San Antonio",
    districtNumber: 15,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0037-KG",
  },
  {
    id: 205,
    name: "San Antonio Quicktrip Labus Rd",
    dir: ['B'],
    type: "Private",
    districtName: "San Antonio",
    districtNumber: 15,
    mpoName: "Alamo Area",
    mpoNumber: 28,
    corridor_interstate_name: "IH0037-KG",
  },
  {
    id: 206,
    name: "San Antonio Pilot Travel Center Eagle Frd",
    dir: ['B'],
    type: "Private",
    districtName: "San Antonio",
    districtNumber: 15,
    mpoName: "Alamo Area",
    mpoNumber: 28,
    corridor_interstate_name: "IH0037-KG",
  },
  {
    id: 207,
    name: "San Antonio Tex Best Travel Center 1604",
    dir: ['B'],
    type: "Private",
    districtName: "San Antonio",
    districtNumber: 15,
    mpoName: "Alamo Area",
    mpoNumber: 28,
    corridor_interstate_name: "IH0037-KG",
  },
  {
    id: 208,
    name: "San Antonio 7Eleven I37",
    dir: ['B'],
    type: "Private",
    districtName: "San Antonio",
    districtNumber: 15,
    mpoName: "Alamo Area",
    mpoNumber: 28,
    corridor_interstate_name: "IH0037-KG",
  },
  {
    id: 209,
    name: "San Antonio Pilot Travel Center Ackerman Rd",
    dir: ['B'],
    type: "Private",
    districtName: "San Antonio",
    districtNumber: 15,
    mpoName: "Alamo Area",
    mpoNumber: 28,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 210,
    name: "San Antonio Ta Travel Center Ackerman Rd",
    dir: ['B'],
    type: "Private",
    districtName: "San Antonio",
    districtNumber: 15,
    mpoName: "Alamo Area",
    mpoNumber: 28,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 211,
    name: "San Antonio Ta Travel Center Foster Rd",
    dir: ['B'],
    type: "Private",
    districtName: "San Antonio",
    districtNumber: 15,
    mpoName: "Alamo Area",
    mpoNumber: 28,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 212,
    name: "San Antonio Flying J Travel Center Foster Rd",
    dir: ['B'],
    type: "Private",
    districtName: "San Antonio",
    districtNumber: 15,
    mpoName: "Alamo Area",
    mpoNumber: 28,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 213,
    name: "San Antonio Empty Lot I10",
    dir: ['B'],
    type: "Private",
    districtName: "San Antonio",
    districtNumber: 15,
    mpoName: "Alamo Area",
    mpoNumber: 28,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 214,
    name: "San Antonio Circlek Oconnor",
    dir: ['B'],
    type: "Private",
    districtName: "San Antonio",
    districtNumber: 15,
    mpoName: "Alamo Area",
    mpoNumber: 28,
    corridor_interstate_name: "IH0035-KG",
  },
  {
    id: 215,
    name: "Schertz Stripes",
    dir: ['B'],
    type: "Private",
    districtName: "San Antonio",
    districtNumber: 15,
    mpoName: "Alamo Area",
    mpoNumber: 28,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 216,
    name: "Schertz Tiger Mart",
    dir: ['B'],
    type: "Private",
    districtName: "San Antonio",
    districtNumber: 15,
    mpoName: "Alamo Area",
    mpoNumber: 28,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 217,
    name: "Seguin Main Street Market",
    dir: ['B'],
    type: "Private",
    districtName: "San Antonio",
    districtNumber: 15,
    mpoName: "Alamo Area",
    mpoNumber: 28,
    corridor_interstate_name: null,
  },
  {
    id: 218,
    name: "Seguin Loves Travel Stop",
    dir: ['B'],
    type: "Private",
    districtName: "San Antonio",
    districtNumber: 15,
    mpoName: "Alamo Area",
    mpoNumber: 28,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 219,
    name: "Guadalupe County",
    dir: ['E', 'W'],
    type: "Public",
    districtName: "San Antonio",
    districtNumber: 15,
    mpoName: "Alamo Area",
    mpoNumber: 28,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 220,
    name: "Luling Loves Travel Stop",
    dir: ['B'],
    type: "Private",
    districtName: "Austin",
    districtNumber: 14,
    mpoName: "Capital Area",
    mpoNumber: 90,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 221,
    name: "New Braunfels Pilot Travel Center Algelt",
    dir: ['B'],
    type: "Private",
    districtName: "San Antonio",
    districtNumber: 15,
    mpoName: "Alamo Area",
    mpoNumber: 28,
    corridor_interstate_name: "IH0035-KG",
  },
  {
    id: 222,
    name: "New Braunfels Tex Best Travel Center",
    dir: ['B'],
    type: "Private",
    districtName: "San Antonio",
    districtNumber: 15,
    mpoName: "Alamo Area",
    mpoNumber: 28,
    corridor_interstate_name: "IH0035-KG",
  },
  {
    id: 223,
    name: "New Braunfels Ta Travel Center Conrads Ln",
    dir: ['B'],
    type: "Private",
    districtName: "San Antonio",
    districtNumber: 15,
    mpoName: "Alamo Area",
    mpoNumber: 28,
    corridor_interstate_name: "IH0035-KG",
  },
  {
    id: 224,
    name: "San Marcos Truck Stop",
    dir: ['B'],
    type: "Private",
    districtName: "Austin",
    districtNumber: 14,
    mpoName: "Capital Area",
    mpoNumber: 90,
    corridor_interstate_name: null,
  },
  {
    id: 225,
    name: "Kyle Tex Best Travel Center",
    dir: ['B'],
    type: "Private",
    districtName: "Austin",
    districtNumber: 14,
    mpoName: "Capital Area",
    mpoNumber: 90,
    corridor_interstate_name: "IH0035-KG",
  },
  {
    id: 226,
    name: "Welfare",
    dir: ['N', 'S'],
    type: "Public",
    districtName: "San Antonio",
    districtNumber: 15,
    mpoName: "Alamo Area",
    mpoNumber: 28,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 227,
    name: "Amigos Express",
    dir: ['B'],
    type: "Private",
    districtName: "San Antonio",
    districtNumber: 15,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 228,
    name: "El Tigre Food Store 17",
    dir: ['B'],
    type: "Private",
    districtName: "Pharr",
    districtNumber: 21,
    mpoName: "Rio Grande Valley",
    mpoNumber: 230,
    corridor_interstate_name: null,
  },
  {
    id: 229,
    name: "Rio Grande City Stripes",
    dir: ['B'],
    type: "Private",
    districtName: "Pharr",
    districtNumber: 21,
    mpoName: "Rio Grande Valley",
    mpoNumber: 230,
    corridor_interstate_name: "US277_US83",
  },
  {
    id: 230,
    name: "Palmview Sunoco",
    dir: ['B'],
    type: "Private",
    districtName: "Pharr",
    districtNumber: 21,
    mpoName: "Rio Grande Valley",
    mpoNumber: 230,
    corridor_interstate_name: "IH0002-KG",
  },
  {
    id: 231,
    name: "Mission Stripes",
    dir: ['B'],
    type: "Private",
    districtName: "Pharr",
    districtNumber: 21,
    mpoName: "Rio Grande Valley",
    mpoNumber: 230,
    corridor_interstate_name: "IH0002-KG",
  },
  {
    id: 232,
    name: "Pharr River Mart",
    dir: ['B'],
    type: "Private",
    districtName: "Pharr",
    districtNumber: 21,
    mpoName: "Rio Grande Valley",
    mpoNumber: 230,
    corridor_interstate_name: null,
  },
  {
    id: 233,
    name: "Pharr Road Ranger",
    dir: ['B'],
    type: "Private",
    districtName: "Pharr",
    districtNumber: 21,
    mpoName: "Rio Grande Valley",
    mpoNumber: 230,
    corridor_interstate_name: null,
  },
  {
    id: 234,
    name: "Pharr Stripes Cage Blvd",
    dir: ['B'],
    type: "Private",
    districtName: "Pharr",
    districtNumber: 21,
    mpoName: "Rio Grande Valley",
    mpoNumber: 230,
    corridor_interstate_name: null,
  },
  {
    id: 235,
    name: "Pharr Stripes Polk Ave",
    dir: ['B'],
    type: "Private",
    districtName: "Pharr",
    districtNumber: 21,
    mpoName: "Rio Grande Valley",
    mpoNumber: 230,
    corridor_interstate_name: "IH0002-KG",
  },
  {
    id: 236,
    name: "Donna Loves Truck Care",
    dir: ['B'],
    type: "Private",
    districtName: "Pharr",
    districtNumber: 21,
    mpoName: "Rio Grande Valley",
    mpoNumber: 230,
    corridor_interstate_name: "IH0002-KG",
  },
  {
    id: 237,
    name: "Edinburg Stripes",
    dir: ['B'],
    type: "Private",
    districtName: "Pharr",
    districtNumber: 21,
    mpoName: "Rio Grande Valley",
    mpoNumber: 230,
    corridor_interstate_name: null,
  },
  {
    id: 238,
    name: "Edinburg Flying J Travel Center",
    dir: ['B'],
    type: "Private",
    districtName: "Pharr",
    districtNumber: 21,
    mpoName: "Rio Grande Valley",
    mpoNumber: 230,
    corridor_interstate_name: "IH0069C-KG",
  },
  {
    id: 239,
    name: "Edinburg Ta Travel Center FM2812",
    dir: ['B'],
    type: "Private",
    districtName: "Pharr",
    districtNumber: 21,
    mpoName: "Rio Grande Valley",
    mpoNumber: 230,
    corridor_interstate_name: "IH0069C-KG",
  },
  {
    id: 240,
    name: "Edinburg Loves Travel Stop FM2812",
    dir: ['B'],
    type: "Private",
    districtName: "Pharr",
    districtNumber: 21,
    mpoName: "Rio Grande Valley",
    mpoNumber: 230,
    corridor_interstate_name: "IH0069C-KG",
  },
  {
    id: 241,
    name: "Combes Road Ranger",
    dir: ['B'],
    type: "Private",
    districtName: "Pharr",
    districtNumber: 21,
    mpoName: "Rio Grande Valley",
    mpoNumber: 230,
    corridor_interstate_name: "IH0069E-KG",
  },
  {
    id: 242,
    name: "Combes Stripes",
    dir: ['B'],
    type: "Private",
    districtName: "Pharr",
    districtNumber: 21,
    mpoName: "Rio Grande Valley",
    mpoNumber: 230,
    corridor_interstate_name: "IH0069E-KG",
  },
  {
    id: 243,
    name: "Harlingen Speedy Stop Ed Carey Dr",
    dir: ['B'],
    type: "Private",
    districtName: "Pharr",
    districtNumber: 21,
    mpoName: "Rio Grande Valley",
    mpoNumber: 230,
    corridor_interstate_name: null,
  },
  {
    id: 244,
    name: "San Benito Stripes",
    dir: ['B'],
    type: "Private",
    districtName: "Pharr",
    districtNumber: 21,
    mpoName: "Rio Grande Valley",
    mpoNumber: 230,
    corridor_interstate_name: null,
  },
  {
    id: 245,
    name: "Brownsville Stripes",
    dir: ['B'],
    type: "Private",
    districtName: "Pharr",
    districtNumber: 21,
    mpoName: "Rio Grande Valley",
    mpoNumber: 230,
    corridor_interstate_name: null,
  },
  {
    id: 246,
    name: "Los Indios Stripes 281",
    dir: ['B'],
    type: "Private",
    districtName: "Pharr",
    districtNumber: 21,
    mpoName: "Rio Grande Valley",
    mpoNumber: 230,
    corridor_interstate_name: null,
  },
  {
    id: 247,
    name: "Kenedy County",
    dir: ['N', 'S'],
    type: "Public",
    districtName: "Pharr",
    districtNumber: 21,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 248,
    name: "Falfurrias Pilot Travel Center",
    dir: ['B'],
    type: "Private",
    districtName: "Pharr",
    districtNumber: 21,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 249,
    name: "Falfurrias 7Eleven Sunoco",
    dir: ['B'],
    type: "Private",
    districtName: "Pharr",
    districtNumber: 21,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 250,
    name: "Falfurrias Stripes",
    dir: ['B'],
    type: "Private",
    districtName: "Pharr",
    districtNumber: 21,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 251,
    name: "Riviera Stripes",
    dir: ['B'],
    type: "Private",
    districtName: "Corpus Christi",
    districtNumber: 16,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 252,
    name: "Kingsville Circlek",
    dir: ['B'],
    type: "Private",
    districtName: "Corpus Christi",
    districtNumber: 16,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 253,
    name: "Kingsville Loves Travel Stop",
    dir: ['B'],
    type: "Private",
    districtName: "Corpus Christi",
    districtNumber: 16,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 254,
    name: "Salice Tex Best",
    dir: ['B'],
    type: "Private",
    districtName: "Corpus Christi",
    districtNumber: 16,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 255,
    name: "Robstown Road Ranger",
    dir: ['B'],
    type: "Private",
    districtName: "Corpus Christi",
    districtNumber: 16,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0069E-KG",
  },
  {
    id: 256,
    name: "Robstown Lonestar Travel Stop",
    dir: ['B'],
    type: "Private",
    districtName: "Corpus Christi",
    districtNumber: 16,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0069E-KG",
  },
  {
    id: 257,
    name: "Corpus Christi Empty Lot Navigation Blvd",
    dir: ['B'],
    type: "Private",
    districtName: "Corpus Christi",
    districtNumber: 16,
    mpoName: "Corpus Christi",
    mpoNumber: 96,
    corridor_interstate_name: "IH0037-KG",
  },
  {
    id: 258,
    name: "Odem Snappy Food",
    dir: ['B'],
    type: "Private",
    districtName: "Corpus Christi",
    districtNumber: 16,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 259,
    name: "Odem Circlek",
    dir: ['B'],
    type: "Private",
    districtName: "Corpus Christi",
    districtNumber: 16,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 260,
    name: "Sinton Loves Travel Stop",
    dir: ['B'],
    type: "Private",
    districtName: "Corpus Christi",
    districtNumber: 16,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 261,
    name: "Sinton Truckees",
    dir: ['B'],
    type: "Private",
    districtName: "Corpus Christi",
    districtNumber: 16,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 262,
    name: "Mathis Criclek",
    dir: ['B'],
    type: "Private",
    districtName: "Corpus Christi",
    districtNumber: 16,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 263,
    name: "Woodsboro Fastbreak",
    dir: ['B'],
    type: "Private",
    districtName: "Corpus Christi",
    districtNumber: 16,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 264,
    name: "Refugio Stripes",
    dir: ['B'],
    type: "Private",
    districtName: "Corpus Christi",
    districtNumber: 16,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 265,
    name: "Refugio Travel Center",
    dir: ['B'],
    type: "Private",
    districtName: "Corpus Christi",
    districtNumber: 16,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 266,
    name: "River Creek Stripes Truck Stop",
    dir: ['B'],
    type: "Private",
    districtName: "Corpus Christi",
    districtNumber: 16,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0037-KG",
  },
  {
    id: 267,
    name: "George West Flying J Travel Center",
    dir: ['B'],
    type: "Private",
    districtName: "Corpus Christi",
    districtNumber: 16,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0037-KG",
  },
  {
    id: 268,
    name: "River Creek Stripes",
    dir: ['B'],
    type: "Private",
    districtName: "Corpus Christi",
    districtNumber: 16,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0037-KG",
  },
  {
    id: 269,
    name: "George West Tex Best Travel Center",
    dir: ['B'],
    type: "Private",
    districtName: "Corpus Christi",
    districtNumber: 16,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "US59",
  },
  {
    id: 270,
    name: "Three Rivers Circlek",
    dir: ['B'],
    type: "Private",
    districtName: "Corpus Christi",
    districtNumber: 16,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0037-KG",
  },
  {
    id: 271,
    name: "Three Rivers Loves Travel Stop",
    dir: ['B'],
    type: "Private",
    districtName: "Corpus Christi",
    districtNumber: 16,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0037-KG",
  },
  {
    id: 272,
    name: "Live Oak County",
    dir: ['N', 'S'],
    type: "Public",
    districtName: "Corpus Christi",
    districtNumber: 16,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0037-KG",
  },
  {
    id: 273,
    name: "Karnes City Stripes Truck Stop",
    dir: ['B'],
    type: "Private",
    districtName: "Corpus Christi",
    districtNumber: 16,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 274,
    name: "Pleasanton",
    dir: ['N', 'S'],
    type: "Public",
    districtName: "San Antonio",
    districtNumber: 15,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0037-KG",
  },
  {
    id: 275,
    name: "Victoria Speedy Stop Truck Stop",
    dir: ['B'],
    type: "Private",
    districtName: "Yoakum",
    districtNumber: 13,
    mpoName: "Victoria",
    mpoNumber: 363,
    corridor_interstate_name: null,
  },
  {
    id: 276,
    name: "Big Vic Truck Stop",
    dir: ['B'],
    type: "Private",
    districtName: "Yoakum",
    districtNumber: 13,
    mpoName: "Victoria",
    mpoNumber: 363,
    corridor_interstate_name: "US59",
  },
  {
    id: 277,
    name: "Victoria County",
    dir: ['N', 'S'],
    type: "Public",
    districtName: "Yoakum",
    districtNumber: 13,
    mpoName: "Victoria",
    mpoNumber: 363,
    corridor_interstate_name: "US59",
  },
  {
    id: 278,
    name: "Edna Loves Travel Stop 59",
    dir: ['B'],
    type: "Private",
    districtName: "Yoakum",
    districtNumber: 13,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "US59",
  },
  {
    id: 279,
    name: "Ganado Ta Travel Center",
    dir: ['B'],
    type: "Private",
    districtName: "Yoakum",
    districtNumber: 13,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "US59",
  },
  {
    id: 280,
    name: "Cuero Stripes",
    dir: ['B'],
    type: "Private",
    districtName: "Yoakum",
    districtNumber: 13,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 281,
    name: "El Campo Western Store",
    dir: ['B'],
    type: "Private",
    districtName: "Yoakum",
    districtNumber: 13,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 282,
    name: "Hungerford Loves Travel Stop",
    dir: ['B'],
    type: "Private",
    districtName: "Yoakum",
    districtNumber: 13,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "US59",
  },
  {
    id: 283,
    name: "Hungerford Foodmart",
    dir: ['B'],
    type: "Private",
    districtName: "Yoakum",
    districtNumber: 13,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "US59",
  },
  {
    id: 284,
    name: "Beasley Chevron",
    dir: ['B'],
    type: "Private",
    districtName: "Houston",
    districtNumber: 12,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: "US59",
  },
  {
    id: 285,
    name: "Beasley Pilot Travel Center",
    dir: ['B'],
    type: "Private",
    districtName: "Houston",
    districtNumber: 12,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: "US59",
  },
  {
    id: 286,
    name: "Rosenberg Travel Plaza",
    dir: ['B'],
    type: "Private",
    districtName: "Houston",
    districtNumber: 12,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: "IH0069-KG",
  },
  {
    id: 287,
    name: "Rosenburg Valero Bryan Rd",
    dir: ['B'],
    type: "Private",
    districtName: "Houston",
    districtNumber: 12,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: null,
  },
  {
    id: 288,
    name: "Bay City Stripes",
    dir: ['B'],
    type: "Private",
    districtName: "Yoakum",
    districtNumber: 13,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 289,
    name: "Sugar Valley Tc Country Store",
    dir: ['B'],
    type: "Private",
    districtName: "Yoakum",
    districtNumber: 13,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 290,
    name: "Angleton Loves Travel Stop",
    dir: ['B'],
    type: "Private",
    districtName: "Houston",
    districtNumber: 12,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: null,
  },
  {
    id: 291,
    name: "La Marque Shell",
    dir: ['B'],
    type: "Private",
    districtName: "Houston",
    districtNumber: 12,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: "IH0045-KG",
  },
  {
    id: 292,
    name: "Galveston Harborside Food Mart",
    dir: ['B'],
    type: "Private",
    districtName: "Houston",
    districtNumber: 12,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: "IH0045-KG",
  },
  {
    id: 293,
    name: "Houston Mchard Mart Post Oak",
    dir: ['B'],
    type: "Private",
    districtName: "Houston",
    districtNumber: 12,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: null,
  },
  {
    id: 294,
    name: "Houston Texaco Fondren",
    dir: ['B'],
    type: "Private",
    districtName: "Houston",
    districtNumber: 12,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: null,
  },
  {
    id: 295,
    name: "Houston Singh Mart Burdine St",
    dir: ['B'],
    type: "Private",
    districtName: "Houston",
    districtNumber: 12,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: null,
  },
  {
    id: 296,
    name: "Houston Hobby Express Truck Stop",
    dir: ['B'],
    type: "Private",
    districtName: "Houston",
    districtNumber: 12,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: null,
  },
  {
    id: 297,
    name: "Laporte Lion King Truck Stop",
    dir: ['B'],
    type: "Private",
    districtName: "Houston",
    districtNumber: 12,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: null,
  },
  {
    id: 298,
    name: "La Porte One9 Travel Center Hwy146",
    dir: ['B'],
    type: "Private",
    districtName: "Houston",
    districtNumber: 12,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: null,
  },
  {
    id: 299,
    name: "La Porte Moodys Travel Plaza",
    dir: ['B'],
    type: "Private",
    districtName: "Houston",
    districtNumber: 12,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: null,
  },
  {
    id: 300,
    name: "Deer Park Loves Travel Stop Independence Pkwy",
    dir: ['B'],
    type: "Private",
    districtName: "Houston",
    districtNumber: 12,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: null,
  },
  {
    id: 301,
    name: "Baytown Oasis Market 146",
    dir: ['B'],
    type: "Private",
    districtName: "Houston",
    districtNumber: 12,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: null,
  },
  {
    id: 302,
    name: "Baytown Oasis Market 99",
    dir: ['B'],
    type: "Private",
    districtName: "Beaumont",
    districtNumber: 20,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: null,
  },
  {
    id: 303,
    name: "Baytown Ta Express Oasis Travel Center FM1405",
    dir: ['B'],
    type: "Private",
    districtName: "Beaumont",
    districtNumber: 20,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: null,
  },
  {
    id: 304,
    name: "Baytown North Main Travel Center",
    dir: ['B'],
    type: "Private",
    districtName: "Houston",
    districtNumber: 12,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 305,
    name: "Mcnair Flying J Travel Center",
    dir: ['B'],
    type: "Private",
    districtName: "Houston",
    districtNumber: 12,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 306,
    name: "Baytown Ta Travel Center Thompson Rd",
    dir: ['B'],
    type: "Private",
    districtName: "Houston",
    districtNumber: 12,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 307,
    name: "Baytown Express Travel Plaza",
    dir: ['B'],
    type: "Private",
    districtName: "Houston",
    districtNumber: 12,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 308,
    name: "Baytown Loves Travel Stop Thompson Rd",
    dir: ['B'],
    type: "Private",
    districtName: "Houston",
    districtNumber: 12,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 309,
    name: "Channellview Travel Center",
    dir: ['B'],
    type: "Private",
    districtName: "Houston",
    districtNumber: 12,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 310,
    name: "Channelview Now And Forever",
    dir: ['B'],
    type: "Private",
    districtName: "Houston",
    districtNumber: 12,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 311,
    name: "Channelview Chevron Ave C",
    dir: ['B'],
    type: "Private",
    districtName: "Houston",
    districtNumber: 12,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: null,
  },
  {
    id: 312,
    name: "Channelview Flying J Travel Center",
    dir: ['B'],
    type: "Private",
    districtName: "Houston",
    districtNumber: 12,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 313,
    name: "Houston Normandy Truck Stop",
    dir: ['B'],
    type: "Private",
    districtName: "Houston",
    districtNumber: 12,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: null,
  },
  {
    id: 314,
    name: "Houston Stripes Federal Rd",
    dir: ['B'],
    type: "Private",
    districtName: "Houston",
    districtNumber: 12,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 315,
    name: "Alen Park Sunmart",
    dir: ['B'],
    type: "Private",
    districtName: "Houston",
    districtNumber: 12,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: null,
  },
  {
    id: 316,
    name: "Houston Texas Truck Stop Laurentide St",
    dir: ['B'],
    type: "Private",
    districtName: "Houston",
    districtNumber: 12,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: null,
  },
  {
    id: 317,
    name: "Houston Handy Stop",
    dir: ['B'],
    type: "Private",
    districtName: "Houston",
    districtNumber: 12,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: "IH0610-KG",
  },
  {
    id: 318,
    name: "Pasadena Flying J Travel Center",
    dir: ['B'],
    type: "Private",
    districtName: "Houston",
    districtNumber: 12,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: null,
  },
  {
    id: 319,
    name: "Breaktime 225 Ts",
    dir: ['B'],
    type: "Private",
    districtName: "Houston",
    districtNumber: 12,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: null,
  },
  {
    id: 320,
    name: "Houston Lockwood Travel Center",
    dir: ['B'],
    type: "Private",
    districtName: "Houston",
    districtNumber: 12,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 321,
    name: "Houston A1 Fuel Stop",
    dir: ['B'],
    type: "Private",
    districtName: "Houston",
    districtNumber: 12,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: null,
  },
  {
    id: 322,
    name: "Houston Truckers Paradise Manitou",
    dir: ['B'],
    type: "Private",
    districtName: "Houston",
    districtNumber: 12,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 323,
    name: "Houston Loves Travel Stop Mccarty St",
    dir: ['B'],
    type: "Private",
    districtName: "Houston",
    districtNumber: 12,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 324,
    name: "Houston Mccarty Chevron 610",
    dir: ['B'],
    type: "Private",
    districtName: "Houston",
    districtNumber: 12,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: null,
  },
  {
    id: 325,
    name: "Houston Pilot Travel Center Manitou",
    dir: ['B'],
    type: "Private",
    districtName: "Houston",
    districtNumber: 12,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 326,
    name: "Houston Xpress Truck Stop Mccarty St",
    dir: ['B'],
    type: "Private",
    districtName: "Houston",
    districtNumber: 12,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: "IH0610-KG",
  },
  {
    id: 327,
    name: "Houston Swingby Truck Stop",
    dir: ['B'],
    type: "Private",
    districtName: "Houston",
    districtNumber: 12,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: null,
  },
  {
    id: 328,
    name: "Houston Texas Travel Plaza Liberty Rd",
    dir: ['B'],
    type: "Private",
    districtName: "Houston",
    districtNumber: 12,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: "IH0610-KG",
  },
  {
    id: 329,
    name: "Houston Chevron Hempstead Rd",
    dir: ['B'],
    type: "Private",
    districtName: "Houston",
    districtNumber: 12,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: "IH0610-KG",
  },
  {
    id: 330,
    name: "Houston Loves Travel Stop Patton St",
    dir: ['B'],
    type: "Private",
    districtName: "Houston",
    districtNumber: 12,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 331,
    name: "Houston Potent Petroleum",
    dir: ['B'],
    type: "Private",
    districtName: "Houston",
    districtNumber: 12,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: null,
  },
  {
    id: 332,
    name: "Royal Wood Express Truck Stop",
    dir: ['B'],
    type: "Private",
    districtName: "Houston",
    districtNumber: 12,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: null,
  },
  {
    id: 333,
    name: "Reservoir Acres Fuel Max",
    dir: ['B'],
    type: "Private",
    districtName: "Houston",
    districtNumber: 12,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: null,
  },
  {
    id: 334,
    name: "Sheldon Stripes Blairwood Dr",
    dir: ['B'],
    type: "Private",
    districtName: "Houston",
    districtNumber: 12,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: null,
  },
  {
    id: 335,
    name: "Sheldon Shell Crosby Fwy",
    dir: ['B'],
    type: "Private",
    districtName: "Houston",
    districtNumber: 12,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: null,
  },
  {
    id: 336,
    name: "Crosby Maxx Crosby Dayton",
    dir: ['B'],
    type: "Private",
    districtName: "Houston",
    districtNumber: 12,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: null,
  },
  {
    id: 337,
    name: "Dayton Fuel Max 1413",
    dir: ['B'],
    type: "Private",
    districtName: "Beaumont",
    districtNumber: 20,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: null,
  },
  {
    id: 338,
    name: "Houston Chevron Aldine Bender",
    dir: ['B'],
    type: "Private",
    districtName: "Houston",
    districtNumber: 12,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: null,
  },
  {
    id: 339,
    name: "Humble Eastex Truck Stop",
    dir: ['B'],
    type: "Private",
    districtName: "Houston",
    districtNumber: 12,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: null,
  },
  {
    id: 340,
    name: "Houston Ace Tire Shop",
    dir: ['B'],
    type: "Private",
    districtName: "Houston",
    districtNumber: 12,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: null,
  },
  {
    id: 341,
    name: "Humble Texas Travel Plaza I69",
    dir: ['B'],
    type: "Private",
    districtName: "Houston",
    districtNumber: 12,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: "IH0069-KG",
  },
  {
    id: 342,
    name: "Aldine Start Stop96",
    dir: ['B'],
    type: "Private",
    districtName: "Houston",
    districtNumber: 12,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: null,
  },
  {
    id: 343,
    name: "Mont Belvieu Sunmart400",
    dir: ['B'],
    type: "Private",
    districtName: "Beaumont",
    districtNumber: 20,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 344,
    name: "Baytown Travel Plaza",
    dir: ['B'],
    type: "Private",
    districtName: "Beaumont",
    districtNumber: 20,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 345,
    name: "Cove I10 Travel Center",
    dir: ['B'],
    type: "Private",
    districtName: "Beaumont",
    districtNumber: 20,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 346,
    name: "Cove Time Maxx1",
    dir: ['B'],
    type: "Private",
    districtName: "Beaumont",
    districtNumber: 20,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 347,
    name: "Country Boys Country Store Travel Plaza",
    dir: ['B'],
    type: "Private",
    districtName: "Beaumont",
    districtNumber: 20,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 348,
    name: "Winnie Exxon 1663",
    dir: ['B'],
    type: "Private",
    districtName: "Beaumont",
    districtNumber: 20,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 349,
    name: "Winnie Jp Truck Stop",
    dir: ['B'],
    type: "Private",
    districtName: "Beaumont",
    districtNumber: 20,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 350,
    name: "Bingo Truck Stop",
    dir: ['B'],
    type: "Private",
    districtName: "Beaumont",
    districtNumber: 20,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 351,
    name: "Winnie Truck Stop 124",
    dir: ['B'],
    type: "Private",
    districtName: "Beaumont",
    districtNumber: 20,
    mpoName: "South East Texas Regional Planning Commission",
    mpoNumber: 135,
    corridor_interstate_name: null,
  },
  {
    id: 352,
    name: "Chambers County",
    dir: ['E', 'W'],
    type: "Public",
    districtName: "Beaumont",
    districtNumber: 20,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 353,
    name: "Beaumont Loves Travel Stop",
    dir: ['B'],
    type: "Private",
    districtName: "Beaumont",
    districtNumber: 20,
    mpoName: "South East Texas Regional Planning Commission",
    mpoNumber: 135,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 354,
    name: "Beaumont Ta Travel Center Walden Rd",
    dir: ['B'],
    type: "Private",
    districtName: "Beaumont",
    districtNumber: 20,
    mpoName: "South East Texas Regional Planning Commission",
    mpoNumber: 135,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 355,
    name: "Rose City Gateway Travel Plaza",
    dir: ['B'],
    type: "Private",
    districtName: "Beaumont",
    districtNumber: 20,
    mpoName: "South East Texas Regional Planning Commission",
    mpoNumber: 135,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 356,
    name: "Orange Pilot Travel Center 62",
    dir: ['B'],
    type: "Private",
    districtName: "Beaumont",
    districtNumber: 20,
    mpoName: "South East Texas Regional Planning Commission",
    mpoNumber: 135,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 357,
    name: "Orange Flying J Travel Center",
    dir: ['B'],
    type: "Private",
    districtName: "Beaumont",
    districtNumber: 20,
    mpoName: "South East Texas Regional Planning Commission",
    mpoNumber: 135,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 358,
    name: "Texas TIC Orange",
    dir: ['W'],
    type: "Public",
    districtName: "Beaumont",
    districtNumber: 20,
    mpoName: "South East Texas Regional Planning Commission",
    mpoNumber: 135,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 359,
    name: "Buna Valero Corner Store",
    dir: ['B'],
    type: "Private",
    districtName: "Beaumont",
    districtNumber: 20,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 360,
    name: "Jasper Valero Hall St",
    dir: ['B'],
    type: "Private",
    districtName: "Beaumont",
    districtNumber: 20,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 361,
    name: "Houston 7Eleven Imperial Valley",
    dir: ['B'],
    type: "Private",
    districtName: "Houston",
    districtNumber: 12,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: "IH0045-KG",
  },
  {
    id: 362,
    name: "Houston Stripes Airtex",
    dir: ['B'],
    type: "Private",
    districtName: "Houston",
    districtNumber: 12,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: "IH0045-KG",
  },
  {
    id: 363,
    name: "Cypress Station Flying J Travel Center",
    dir: ['B'],
    type: "Private",
    districtName: "Houston",
    districtNumber: 12,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: "IH0045-KG",
  },
  {
    id: 364,
    name: "Texaco Houston Gessner",
    dir: ['B'],
    type: "Private",
    districtName: "Houston",
    districtNumber: 12,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: null,
  },
  {
    id: 365,
    name: "Houston Valero Little York Rd",
    dir: ['B'],
    type: "Private",
    districtName: "Houston",
    districtNumber: 12,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: null,
  },
  {
    id: 366,
    name: "Houston Hempstead Truck Stop",
    dir: ['B'],
    type: "Private",
    districtName: "Houston",
    districtNumber: 12,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: null,
  },
  {
    id: 367,
    name: "Houston Exxon Hammerly",
    dir: ['B'],
    type: "Private",
    districtName: "Houston",
    districtNumber: 12,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: null,
  },
  {
    id: 368,
    name: "Katy Texaco I10",
    dir: ['B'],
    type: "Private",
    districtName: "Houston",
    districtNumber: 12,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: null,
  },
  {
    id: 369,
    name: "Katy Loves Truck Care",
    dir: ['B'],
    type: "Private",
    districtName: "Houston",
    districtNumber: 12,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 370,
    name: "Katy Flying J Travel Center",
    dir: ['B'],
    type: "Private",
    districtName: "Houston",
    districtNumber: 12,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 371,
    name: "Cypress Hwy 290 Ts",
    dir: ['B'],
    type: "Private",
    districtName: "Houston",
    districtNumber: 12,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: null,
  },
  {
    id: 372,
    name: "Waller Loves Travel Stop FM2920",
    dir: ['B'],
    type: "Private",
    districtName: "Houston",
    districtNumber: 12,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: null,
  },
  {
    id: 373,
    name: "Hempstead Pilot Travel Center",
    dir: ['B'],
    type: "Private",
    districtName: "Houston",
    districtNumber: 12,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: null,
  },
  {
    id: 374,
    name: "Hempstead Chevron",
    dir: ['B'],
    type: "Private",
    districtName: "Houston",
    districtNumber: 12,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: null,
  },
  {
    id: 375,
    name: "Chappel Hill Circlek Gin",
    dir: ['B'],
    type: "Private",
    districtName: "Bryan",
    districtNumber: 17,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 376,
    name: "Sealy Main Street Market",
    dir: ['B'],
    type: "Private",
    districtName: "Yoakum",
    districtNumber: 13,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 377,
    name: "Sealy Truck Stop",
    dir: ['B'],
    type: "Private",
    districtName: "Yoakum",
    districtNumber: 13,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 378,
    name: "Sealy Ta Travel Center Bernardo Rd",
    dir: ['B'],
    type: "Private",
    districtName: "Yoakum",
    districtNumber: 13,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 379,
    name: "Colorado County",
    dir: ['E', 'W'],
    type: "Public",
    districtName: "Yoakum",
    districtNumber: 13,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 380,
    name: "Weimar Loves Travel Stop",
    dir: ['B'],
    type: "Private",
    districtName: "Yoakum",
    districtNumber: 13,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 381,
    name: "Weimar Truck Stop I10",
    dir: ['B'],
    type: "Private",
    districtName: "Yoakum",
    districtNumber: 13,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 382,
    name: "Schulenburg Pilot Travel Center",
    dir: ['B'],
    type: "Private",
    districtName: "Yoakum",
    districtNumber: 13,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 383,
    name: "Hallettsville Mortons Truck Stop",
    dir: ['B'],
    type: "Private",
    districtName: "Yoakum",
    districtNumber: 13,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 384,
    name: "Flatonia Super Travel Center",
    dir: ['B'],
    type: "Private",
    districtName: "Yoakum",
    districtNumber: 13,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 385,
    name: "Flatonia",
    dir: ['E', 'W'],
    type: "Public",
    districtName: "Yoakum",
    districtNumber: 13,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 386,
    name: "Smithville Shell 71",
    dir: ['B'],
    type: "Private",
    districtName: "Austin",
    districtNumber: 14,
    mpoName: "Capital Area",
    mpoNumber: 90,
    corridor_interstate_name: null,
  },
  {
    id: 387,
    name: "Giddings Cefco Travel Center",
    dir: ['B'],
    type: "Private",
    districtName: "Austin",
    districtNumber: 14,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 388,
    name: "290 Travel Plaza Ts",
    dir: ['B'],
    type: "Private",
    districtName: "Austin",
    districtNumber: 14,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 389,
    name: "Paige Fuel Max 2104",
    dir: ['B'],
    type: "Private",
    districtName: "Austin",
    districtNumber: 14,
    mpoName: "Capital Area",
    mpoNumber: 90,
    corridor_interstate_name: null,
  },
  {
    id: 390,
    name: "Mustang Ridge Pilot Travel Center",
    dir: ['B'],
    type: "Private",
    districtName: "Austin",
    districtNumber: 14,
    mpoName: "Capital Area",
    mpoNumber: 90,
    corridor_interstate_name: null,
  },
  {
    id: 391,
    name: "Blanco Stripes",
    dir: ['B'],
    type: "Private",
    districtName: "Austin",
    districtNumber: 14,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "US281",
  },
  {
    id: 392,
    name: "Comfort Loves Travel Stop",
    dir: ['B'],
    type: "Private",
    districtName: "San Antonio",
    districtNumber: 15,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 393,
    name: "Kerr County",
    dir: ['E', 'W'],
    type: "Public",
    districtName: "San Antonio",
    districtNumber: 15,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 394,
    name: "Kerrville",
    dir: ['S'],
    type: "Public",
    districtName: "San Antonio",
    districtNumber: 15,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 395,
    name: "Harper",
    dir: ['E', 'W'],
    type: "Public",
    districtName: "Austin",
    districtNumber: 14,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0010-KG",
  },
  {
    id: 396,
    name: "Bigs Valero",
    dir: ['B'],
    type: "Private",
    districtName: "Austin",
    districtNumber: 14,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "US281",
  },
  {
    id: 397,
    name: "Hornsby Bend Valero",
    dir: ['B'],
    type: "Private",
    districtName: "Austin",
    districtNumber: 14,
    mpoName: "Capital Area",
    mpoNumber: 90,
    corridor_interstate_name: null,
  },
  {
    id: 398,
    name: "Elgin Travel Center",
    dir: ['B'],
    type: "Private",
    districtName: "Austin",
    districtNumber: 14,
    mpoName: "Capital Area",
    mpoNumber: 90,
    corridor_interstate_name: null,
  },
  {
    id: 399,
    name: "Elgin Texaco Hwy290",
    dir: ['B'],
    type: "Private",
    districtName: "Austin",
    districtNumber: 14,
    mpoName: "Capital Area",
    mpoNumber: 90,
    corridor_interstate_name: null,
  },
  {
    id: 400,
    name: "Lexington Cefco",
    dir: ['B'],
    type: "Private",
    districtName: "Austin",
    districtNumber: 14,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 401,
    name: "Caldwell Bigs Valero",
    dir: ['B'],
    type: "Private",
    districtName: "Bryan",
    districtNumber: 17,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 402,
    name: "Pflugerville 7Eleven I35",
    dir: ['B'],
    type: "Private",
    districtName: "Austin",
    districtNumber: 14,
    mpoName: "Capital Area",
    mpoNumber: 90,
    corridor_interstate_name: "IH0035-KG",
  },
  {
    id: 403,
    name: "Round Rock Premier Truck Parking",
    dir: ['B'],
    type: "Private",
    districtName: "Austin",
    districtNumber: 14,
    mpoName: "Capital Area",
    mpoNumber: 90,
    corridor_interstate_name: null,
  },
  {
    id: 404,
    name: "Liberty Hill Wag A Bag16",
    dir: ['B'],
    type: "Private",
    districtName: "Austin",
    districtNumber: 14,
    mpoName: "Capital Area",
    mpoNumber: 90,
    corridor_interstate_name: null,
  },
  {
    id: 405,
    name: "Jarrell Circlek",
    dir: ['B'],
    type: "Private",
    districtName: "Austin",
    districtNumber: 14,
    mpoName: "Capital Area",
    mpoNumber: 90,
    corridor_interstate_name: "IH0035-KG",
  },
  {
    id: 406,
    name: "Jarrell Qmart Truck Stop And Travel Plaza",
    dir: ['B'],
    type: "Private",
    districtName: "Austin",
    districtNumber: 14,
    mpoName: "Capital Area",
    mpoNumber: 90,
    corridor_interstate_name: "IH0035-KG",
  },
  {
    id: 407,
    name: "Bell County",
    dir: ['N', 'S'],
    type: "Public",
    districtName: "Waco",
    districtNumber: 9,
    mpoName: "Killeen-Temple",
    mpoNumber: 277,
    corridor_interstate_name: "IH0035-KG",
  },
  {
    id: 408,
    name: "Salado Jds Travel Center",
    dir: ['B'],
    type: "Private",
    districtName: "Waco",
    districtNumber: 9,
    mpoName: "Killeen-Temple",
    mpoNumber: 277,
    corridor_interstate_name: null,
  },
  {
    id: 409,
    name: "Belton Cefco Travel Center",
    dir: ['B'],
    type: "Private",
    districtName: "Waco",
    districtNumber: 9,
    mpoName: "Killeen-Temple",
    mpoNumber: 277,
    corridor_interstate_name: "IH0014-KG",
  },
  {
    id: 410,
    name: "Temple Cefco Travel Center Tx81",
    dir: ['B'],
    type: "Private",
    districtName: "Waco",
    districtNumber: 9,
    mpoName: "Killeen-Temple",
    mpoNumber: 277,
    corridor_interstate_name: "IH0035-KG",
  },
  {
    id: 411,
    name: "Troy Loves Travel Stop",
    dir: ['B'],
    type: "Private",
    districtName: "Waco",
    districtNumber: 9,
    mpoName: "Killeen-Temple",
    mpoNumber: 277,
    corridor_interstate_name: "IH0035-KG",
  },
  {
    id: 412,
    name: "Hamilton Cefco Park Hill",
    dir: ['B'],
    type: "Private",
    districtName: "Waco",
    districtNumber: 9,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "US281",
  },
  {
    id: 413,
    name: "Hewitt Chevron Rolling Hills",
    dir: ['B'],
    type: "Private",
    districtName: "Waco",
    districtNumber: 9,
    mpoName: "Waco",
    mpoNumber: 140,
    corridor_interstate_name: "IH0035-KG",
  },
  {
    id: 414,
    name: "Hewitt Pilot Travel Center",
    dir: ['B'],
    type: "Private",
    districtName: "Waco",
    districtNumber: 9,
    mpoName: "Waco",
    mpoNumber: 140,
    corridor_interstate_name: "IH0035-KG",
  },
  {
    id: 415,
    name: "Waco Flying J Travel Center",
    dir: ['B'],
    type: "Private",
    districtName: "Waco",
    districtNumber: 9,
    mpoName: "Waco",
    mpoNumber: 140,
    corridor_interstate_name: "IH0035-KG",
  },
  {
    id: 416,
    name: "Riesel Exxon Memorial St",
    dir: ['B'],
    type: "Private",
    districtName: "Waco",
    districtNumber: 9,
    mpoName: "Waco",
    mpoNumber: 140,
    corridor_interstate_name: null,
  },
  {
    id: 417,
    name: "Waco Sunoco 6",
    dir: ['B'],
    type: "Private",
    districtName: "Waco",
    districtNumber: 9,
    mpoName: "Waco",
    mpoNumber: 140,
    corridor_interstate_name: null,
  },
  {
    id: 418,
    name: "Road Ranger",
    dir: ['B'],
    type: "Private",
    districtName: "Waco",
    districtNumber: 9,
    mpoName: "Waco",
    mpoNumber: 140,
    corridor_interstate_name: "IH0035-KG",
  },
  {
    id: 419,
    name: "Cowboy Truck Stop Sunoco",
    dir: ['B'],
    type: "Private",
    districtName: "Waco",
    districtNumber: 9,
    mpoName: "Waco",
    mpoNumber: 140,
    corridor_interstate_name: "IH0035-KG",
  },
  {
    id: 420,
    name: "Ross Gator Stop",
    dir: ['B'],
    type: "Private",
    districtName: "Waco",
    districtNumber: 9,
    mpoName: "Waco",
    mpoNumber: 140,
    corridor_interstate_name: "IH0035-KG",
  },
  {
    id: 421,
    name: "Woodlands Main Street Market",
    dir: ['B'],
    type: "Private",
    districtName: "Houston",
    districtNumber: 12,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: "IH0045-KG",
  },
  {
    id: 422,
    name: "Patton Village Circlek",
    dir: ['B'],
    type: "Private",
    districtName: "Houston",
    districtNumber: 12,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: "IH0069-KG",
  },
  {
    id: 423,
    name: "Patton Village Flying J Travel Center",
    dir: ['B'],
    type: "Private",
    districtName: "Houston",
    districtNumber: 12,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: "IH0069-KG",
  },
  {
    id: 424,
    name: "Cleveland Loves Travel Stop",
    dir: ['B'],
    type: "Private",
    districtName: "Beaumont",
    districtNumber: 20,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: null,
  },
  {
    id: 425,
    name: "Cut And Shoot Kountry Mart",
    dir: ['B'],
    type: "Private",
    districtName: "Houston",
    districtNumber: 12,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: null,
  },
  {
    id: 426,
    name: "Conroe Eaglemart 6",
    dir: ['B'],
    type: "Private",
    districtName: "Houston",
    districtNumber: 12,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: null,
  },
  {
    id: 427,
    name: "Willis Loves Travel Stop Longstreet",
    dir: ['B'],
    type: "Private",
    districtName: "Houston",
    districtNumber: 12,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: "IH0045-KG",
  },
  {
    id: 428,
    name: "Willis Circlek",
    dir: ['B'],
    type: "Private",
    districtName: "Houston",
    districtNumber: 12,
    mpoName: "Houston-Galveston Area Council",
    mpoNumber: 15,
    corridor_interstate_name: null,
  },
  {
    id: 429,
    name: "New Waverly Start Stop95",
    dir: ['B'],
    type: "Private",
    districtName: "Bryan",
    districtNumber: 17,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0045-KG",
  },
  {
    id: 430,
    name: "Livingston Livingston Road Mart",
    dir: ['B'],
    type: "Private",
    districtName: "Lufkin",
    districtNumber: 11,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 431,
    name: "Polk County",
    dir: ['N', 'S'],
    type: "Public",
    districtName: "Lufkin",
    districtNumber: 11,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 432,
    name: "Bryan 7Eleven 190",
    dir: ['B'],
    type: "Private",
    districtName: "Bryan",
    districtNumber: 17,
    mpoName: "Bryan-College Station",
    mpoNumber: 249,
    corridor_interstate_name: null,
  },
  {
    id: 433,
    name: "Big Gas Truck Stop",
    dir: ['B'],
    type: "Private",
    districtName: "Bryan",
    districtNumber: 17,
    mpoName: "Bryan-College Station",
    mpoNumber: 249,
    corridor_interstate_name: null,
  },
  {
    id: 434,
    name: "Hearne Loves Travel Stop",
    dir: ['B'],
    type: "Private",
    districtName: "Bryan",
    districtNumber: 17,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 435,
    name: "Huntsville Pilot Travel Center",
    dir: ['B'],
    type: "Private",
    districtName: "Bryan",
    districtNumber: 17,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0045-KG",
  },
  {
    id: 436,
    name: "Huntsville Hitching Post Truck Terminal",
    dir: ['B'],
    type: "Private",
    districtName: "Bryan",
    districtNumber: 17,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0045-KG",
  },
  {
    id: 437,
    name: "Arizona Jacks Truck Stop",
    dir: ['B'],
    type: "Private",
    districtName: "Bryan",
    districtNumber: 17,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 438,
    name: "Walker County",
    dir: ['N', 'S'],
    type: "Public",
    districtName: "Bryan",
    districtNumber: 17,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0045-KG",
  },
  {
    id: 439,
    name: "Leona Yellow Rose Travel Plaza",
    dir: ['B'],
    type: "Private",
    districtName: "Bryan",
    districtNumber: 17,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0045-KG",
  },
  {
    id: 440,
    name: "Centerville",
    dir: ['N', 'S'],
    type: "Public",
    districtName: "Bryan",
    districtNumber: 17,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0045-KG",
  },
  {
    id: 441,
    name: "Centerville Woodys Smokehouse",
    dir: ['B'],
    type: "Private",
    districtName: "Bryan",
    districtNumber: 17,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0045-KG",
  },
  {
    id: 442,
    name: "Buffalo Truck Stop",
    dir: ['B'],
    type: "Private",
    districtName: "Bryan",
    districtNumber: 17,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 443,
    name: "Buffalo Pilot Travel Center",
    dir: ['B'],
    type: "Private",
    districtName: "Bryan",
    districtNumber: 17,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0045-KG",
  },
  {
    id: 444,
    name: "Dew Lucky Js Travel Center",
    dir: ['B'],
    type: "Private",
    districtName: "Bryan",
    districtNumber: 17,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0045-KG",
  },
  {
    id: 445,
    name: "Dew Jet Travel Plaza",
    dir: ['B'],
    type: "Private",
    districtName: "Bryan",
    districtNumber: 17,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0045-KG",
  },
  {
    id: 446,
    name: "Fairfield I45 Truck Stop",
    dir: ['B'],
    type: "Private",
    districtName: "Bryan",
    districtNumber: 17,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0045-KG",
  },
  {
    id: 447,
    name: "Fairfield Loves Travel Stop Commerce St",
    dir: ['B'],
    type: "Private",
    districtName: "Bryan",
    districtNumber: 17,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0045-KG",
  },
  {
    id: 448,
    name: "Palastine Gateway Travel Plaza",
    dir: ['B'],
    type: "Private",
    districtName: "Tyler",
    districtNumber: 10,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 449,
    name: "Alto Stop N Shop",
    dir: ['B'],
    type: "Private",
    districtName: "Tyler",
    districtNumber: 10,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 450,
    name: "Lufkin Pilot Travel Center Irving Dr",
    dir: ['B'],
    type: "Private",
    districtName: "Lufkin",
    districtNumber: 11,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 451,
    name: "Lufkin Loves Travel Stop 59",
    dir: ['B'],
    type: "Private",
    districtName: "Lufkin",
    districtNumber: 11,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "US69_US175",
  },
  {
    id: 452,
    name: "Lufkin Lufkin Truck Stop",
    dir: ['B'],
    type: "Private",
    districtName: "Lufkin",
    districtNumber: 11,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "US69_US175",
  },
  {
    id: 453,
    name: "Redland Loves Travel Center",
    dir: ['B'],
    type: "Private",
    districtName: "Lufkin",
    districtNumber: 11,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 454,
    name: "Nacogdoches Conoco",
    dir: ['B'],
    type: "Private",
    districtName: "Lufkin",
    districtNumber: 11,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 455,
    name: "Nacogdoches Ta Travel Center 59",
    dir: ['B'],
    type: "Private",
    districtName: "Lufkin",
    districtNumber: 11,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 456,
    name: "Nacogdoches Morgan Oil Travel Plaza",
    dir: ['B'],
    type: "Private",
    districtName: "Lufkin",
    districtNumber: 11,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 457,
    name: "Nacogdoches Brookshire Brothers",
    dir: ['B'],
    type: "Private",
    districtName: "Lufkin",
    districtNumber: 11,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 458,
    name: "Jacksonville 7Eleven US69",
    dir: ['B'],
    type: "Private",
    districtName: "Tyler",
    districtNumber: 10,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 459,
    name: "Henderson Gateway Travel Plaza",
    dir: ['B'],
    type: "Private",
    districtName: "Tyler",
    districtNumber: 10,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 460,
    name: "Carthage Ta Express Travel Center Hwy59",
    dir: ['B'],
    type: "Private",
    districtName: "Atlanta",
    districtNumber: 19,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 461,
    name: "Carthage Panola Pines Plaza",
    dir: ['B'],
    type: "Private",
    districtName: "Atlanta",
    districtNumber: 19,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 462,
    name: "B F Graves Ts",
    dir: ['B'],
    type: "Private",
    districtName: "Atlanta",
    districtNumber: 19,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 463,
    name: "Texas TIC Waskom",
    dir: ['W'],
    type: "Public",
    districtName: "Atlanta",
    districtNumber: 19,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0020-KG",
  },
  {
    id: 464,
    name: "Hallsville",
    dir: ['E'],
    type: "Public",
    districtName: "Atlanta",
    districtNumber: 19,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 465,
    name: "Longview Tex Best",
    dir: ['B'],
    type: "Private",
    districtName: "Tyler",
    districtNumber: 10,
    mpoName: "Longview",
    mpoNumber: 361,
    corridor_interstate_name: "IH0020-KG",
  },
  {
    id: 466,
    name: "Kilgore Ta Travel Center",
    dir: ['B'],
    type: "Private",
    districtName: "Tyler",
    districtNumber: 10,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0020-KG",
  },
  {
    id: 467,
    name: "Winona",
    dir: ['E', 'W'],
    type: "Public",
    districtName: "Tyler",
    districtNumber: 10,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0020-KG",
  },
  {
    id: 468,
    name: "Tyler Texas Best",
    dir: ['B'],
    type: "Private",
    districtName: "Tyler",
    districtNumber: 10,
    mpoName: "Tyler",
    mpoNumber: 213,
    corridor_interstate_name: "IH0020-KG",
  },
  {
    id: 469,
    name: "Tyler Triple J",
    dir: ['B'],
    type: "Private",
    districtName: "Tyler",
    districtNumber: 10,
    mpoName: "Tyler",
    mpoNumber: 213,
    corridor_interstate_name: null,
  },
  {
    id: 470,
    name: "Tyler Pilot Travel Center",
    dir: ['B'],
    type: "Private",
    districtName: "Tyler",
    districtNumber: 10,
    mpoName: "Tyler",
    mpoNumber: 213,
    corridor_interstate_name: "IH0020-KG",
  },
  {
    id: 471,
    name: "Lindale Quicktrip Hwy69",
    dir: ['B'],
    type: "Private",
    districtName: "Tyler",
    districtNumber: 10,
    mpoName: "Tyler",
    mpoNumber: 213,
    corridor_interstate_name: "IH0020-KG",
  },
  {
    id: 472,
    name: "Tyler Travel Center",
    dir: ['B'],
    type: "Private",
    districtName: "Tyler",
    districtNumber: 10,
    mpoName: "Tyler",
    mpoNumber: 213,
    corridor_interstate_name: null,
  },
  {
    id: 473,
    name: "Tyler Truck Express",
    dir: ['B'],
    type: "Private",
    districtName: "Tyler",
    districtNumber: 10,
    mpoName: "Tyler",
    mpoNumber: 213,
    corridor_interstate_name: null,
  },
  {
    id: 474,
    name: "Tyler Travel Stop Loop323",
    dir: ['B'],
    type: "Private",
    districtName: "Tyler",
    districtNumber: 10,
    mpoName: "Tyler",
    mpoNumber: 213,
    corridor_interstate_name: null,
  },
  {
    id: 475,
    name: "Hideaway Oasis Super Stop",
    dir: ['B'],
    type: "Private",
    districtName: "Tyler",
    districtNumber: 10,
    mpoName: "Tyler",
    mpoNumber: 213,
    corridor_interstate_name: null,
  },
  {
    id: 476,
    name: "Van Loves Travel Stop",
    dir: ['B'],
    type: "Private",
    districtName: "Tyler",
    districtNumber: 10,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0020-KG",
  },
  {
    id: 477,
    name: "Van Loves Travel Center FM314",
    dir: ['B'],
    type: "Private",
    districtName: "Tyler",
    districtNumber: 10,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0020-KG",
  },
  {
    id: 478,
    name: "Canton Pilot Travel Center I20",
    dir: ['B'],
    type: "Private",
    districtName: "Tyler",
    districtNumber: 10,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0020-KG",
  },
  {
    id: 479,
    name: "Dukes Travel Plaza Of Canton 64 Ts",
    dir: ['B'],
    type: "Private",
    districtName: "Tyler",
    districtNumber: 10,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0020-KG",
  },
  {
    id: 480,
    name: "Navarro County",
    dir: ['S'],
    type: "Public",
    districtName: "Dallas",
    districtNumber: 18,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0045-KG",
  },
  {
    id: 481,
    name: "Dawson Shell Hwy31",
    dir: ['B'],
    type: "Private",
    districtName: "Dallas",
    districtNumber: 18,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 482,
    name: "Rice Exxon",
    dir: ['B'],
    type: "Private",
    districtName: "Dallas",
    districtNumber: 18,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0045-KG",
  },
  {
    id: 483,
    name: "Alma Loves Travel Stop",
    dir: ['B'],
    type: "Private",
    districtName: "Dallas",
    districtNumber: 18,
    mpoName: "North Central Texas Council of Governments",
    mpoNumber: 282,
    corridor_interstate_name: "IH0045-KG",
  },
  {
    id: 484,
    name: "Ennis Tiger Mart",
    dir: ['B'],
    type: "Private",
    districtName: "Dallas",
    districtNumber: 18,
    mpoName: "North Central Texas Council of Governments",
    mpoNumber: 282,
    corridor_interstate_name: "IH0045-KG",
  },
  {
    id: 485,
    name: "Palmer Sunmart Travel Center",
    dir: ['B'],
    type: "Private",
    districtName: "Dallas",
    districtNumber: 18,
    mpoName: "North Central Texas Council of Governments",
    mpoNumber: 282,
    corridor_interstate_name: "IH0045-KG",
  },
  {
    id: 486,
    name: "Italy Pilot Travel Center",
    dir: ['B'],
    type: "Private",
    districtName: "Dallas",
    districtNumber: 18,
    mpoName: "North Central Texas Council of Governments",
    mpoNumber: 282,
    corridor_interstate_name: "IH0035E-KG",
  },
  {
    id: 487,
    name: "Italy Loves Travel Stop",
    dir: ['B'],
    type: "Private",
    districtName: "Dallas",
    districtNumber: 18,
    mpoName: "North Central Texas Council of Governments",
    mpoNumber: 282,
    corridor_interstate_name: "IH0035E-KG",
  },
  {
    id: 488,
    name: "Petro Travel Center",
    dir: ['B'],
    type: "Private",
    districtName: "Waco",
    districtNumber: 9,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0035E-KG",
  },
  {
    id: 489,
    name: "Hillsboro Ta Travel Center Hwy77",
    dir: ['B'],
    type: "Private",
    districtName: "Waco",
    districtNumber: 9,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0035E-KG",
  },
  {
    id: 490,
    name: "Hillsboro Loves Travel Stop",
    dir: ['B'],
    type: "Private",
    districtName: "Waco",
    districtNumber: 9,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0035-KG",
  },
  {
    id: 491,
    name: "Hill County",
    dir: ['N', 'S'],
    type: "Public",
    districtName: "Waco",
    districtNumber: 9,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0035-KG",
  },
  {
    id: 492,
    name: "Abbott Sunmart",
    dir: ['B'],
    type: "Private",
    districtName: "Waco",
    districtNumber: 9,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 493,
    name: "Itasca Exxon",
    dir: ['B'],
    type: "Private",
    districtName: "Waco",
    districtNumber: 9,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 494,
    name: "Covington Cefco",
    dir: ['B'],
    type: "Private",
    districtName: "Waco",
    districtNumber: 9,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 495,
    name: "Waxahachie One9 Fuel Network",
    dir: ['B'],
    type: "Private",
    districtName: "Dallas",
    districtNumber: 18,
    mpoName: "North Central Texas Council of Governments",
    mpoNumber: 282,
    corridor_interstate_name: null,
  },
  {
    id: 496,
    name: "Waxahachie Qwik Stop I35E",
    dir: ['B'],
    type: "Private",
    districtName: "Dallas",
    districtNumber: 18,
    mpoName: "North Central Texas Council of Governments",
    mpoNumber: 282,
    corridor_interstate_name: null,
  },
  {
    id: 497,
    name: "Waxahachie Quicktrip Butcher Rd",
    dir: ['B'],
    type: "Private",
    districtName: "Dallas",
    districtNumber: 18,
    mpoName: "North Central Texas Council of Governments",
    mpoNumber: 282,
    corridor_interstate_name: "IH0035E-KG",
  },
  {
    id: 498,
    name: "Midlothian Smart Stops Texas1",
    dir: ['B'],
    type: "Private",
    districtName: "Dallas",
    districtNumber: 18,
    mpoName: "North Central Texas Council of Governments",
    mpoNumber: 282,
    corridor_interstate_name: null,
  },
  {
    id: 499,
    name: "Midlothian Loves Travel Stop",
    dir: ['B'],
    type: "Private",
    districtName: "Dallas",
    districtNumber: 18,
    mpoName: "North Central Texas Council of Governments",
    mpoNumber: 282,
    corridor_interstate_name: null,
  },
  {
    id: 500,
    name: "Midlothian Mini Mart",
    dir: ['B'],
    type: "Private",
    districtName: "Dallas",
    districtNumber: 18,
    mpoName: "North Central Texas Council of Governments",
    mpoNumber: 282,
    corridor_interstate_name: null,
  },
  {
    id: 501,
    name: "Red Oak Travel Center",
    dir: ['B'],
    type: "Private",
    districtName: "Dallas",
    districtNumber: 18,
    mpoName: "North Central Texas Council of Governments",
    mpoNumber: 282,
    corridor_interstate_name: "IH0035E-KG",
  },
  {
    id: 502,
    name: "Alvarado Shell I35",
    dir: ['B'],
    type: "Private",
    districtName: "Fort Worth",
    districtNumber: 2,
    mpoName: "North Central Texas Council of Governments",
    mpoNumber: 282,
    corridor_interstate_name: "IH0035W-KG",
  },
  {
    id: 503,
    name: "Alvarado Sunnys Market",
    dir: ['B'],
    type: "Private",
    districtName: "Fort Worth",
    districtNumber: 2,
    mpoName: "North Central Texas Council of Governments",
    mpoNumber: 282,
    corridor_interstate_name: null,
  },
  {
    id: 504,
    name: "Wilmer One9 Dealer Ts",
    dir: ['B'],
    type: "Private",
    districtName: "Dallas",
    districtNumber: 18,
    mpoName: "North Central Texas Council of Governments",
    mpoNumber: 282,
    corridor_interstate_name: "IH0045-KG",
  },
  {
    id: 505,
    name: "Hutchins Loves Travel Stop",
    dir: ['B'],
    type: "Private",
    districtName: "Dallas",
    districtNumber: 18,
    mpoName: "North Central Texas Council of Governments",
    mpoNumber: 282,
    corridor_interstate_name: "IH0045-KG",
  },
  {
    id: 506,
    name: "Hutchins Quicktrip Wintergreen Rd",
    dir: ['B'],
    type: "Private",
    districtName: "Dallas",
    districtNumber: 18,
    mpoName: "North Central Texas Council of Governments",
    mpoNumber: 282,
    corridor_interstate_name: "IH0045-KG",
  },
  {
    id: 507,
    name: "Hutchins Top Fuel I45",
    dir: ['B'],
    type: "Private",
    districtName: "Dallas",
    districtNumber: 18,
    mpoName: "North Central Texas Council of Governments",
    mpoNumber: 282,
    corridor_interstate_name: "IH0020-KG",
  },
  {
    id: 508,
    name: "Dallas Ta Travel Center Bonnie View",
    dir: ['B'],
    type: "Private",
    districtName: "Dallas",
    districtNumber: 18,
    mpoName: "North Central Texas Council of Governments",
    mpoNumber: 282,
    corridor_interstate_name: "IH0020-KG",
  },
  {
    id: 509,
    name: "Dallas Flying J Travel Center Bonnie View Rd",
    dir: ['B'],
    type: "Private",
    districtName: "Dallas",
    districtNumber: 18,
    mpoName: "North Central Texas Council of Governments",
    mpoNumber: 282,
    corridor_interstate_name: "IH0020-KG",
  },
  {
    id: 510,
    name: "Dallas Circlek 342",
    dir: ['B'],
    type: "Private",
    districtName: "Dallas",
    districtNumber: 18,
    mpoName: "North Central Texas Council of Governments",
    mpoNumber: 282,
    corridor_interstate_name: "IH0020-KG",
  },
  {
    id: 511,
    name: "Dallas Pilot Travel Center Cedardale",
    dir: ['B'],
    type: "Private",
    districtName: "Dallas",
    districtNumber: 18,
    mpoName: "North Central Texas Council of Governments",
    mpoNumber: 282,
    corridor_interstate_name: "IH0020-KG",
  },
  {
    id: 512,
    name: "Dallas Loves Travel Stop Polk St",
    dir: ['B'],
    type: "Private",
    districtName: "Dallas",
    districtNumber: 18,
    mpoName: "North Central Texas Council of Governments",
    mpoNumber: 282,
    corridor_interstate_name: "IH0020-KG",
  },
  {
    id: 513,
    name: "Dallas Marlows Fuel Center",
    dir: ['B'],
    type: "Private",
    districtName: "Dallas",
    districtNumber: 18,
    mpoName: "North Central Texas Council of Governments",
    mpoNumber: 282,
    corridor_interstate_name: null,
  },
  {
    id: 514,
    name: "Seagoville Circlek",
    dir: ['B'],
    type: "Private",
    districtName: "Dallas",
    districtNumber: 18,
    mpoName: "North Central Texas Council of Governments",
    mpoNumber: 282,
    corridor_interstate_name: null,
  },
  {
    id: 515,
    name: "Forney Xpress Travel Center",
    dir: ['B'],
    type: "Private",
    districtName: "Dallas",
    districtNumber: 18,
    mpoName: "North Central Texas Council of Governments",
    mpoNumber: 282,
    corridor_interstate_name: null,
  },
  {
    id: 516,
    name: "Dallas Fuel City Express Ts Town East Blvd",
    dir: ['B'],
    type: "Private",
    districtName: "Dallas",
    districtNumber: 18,
    mpoName: "North Central Texas Council of Governments",
    mpoNumber: 282,
    corridor_interstate_name: "IH0030-KG",
  },
  {
    id: 517,
    name: "Dallas Fuel City Mesquite Ts",
    dir: ['B'],
    type: "Private",
    districtName: "Dallas",
    districtNumber: 18,
    mpoName: "North Central Texas Council of Governments",
    mpoNumber: 282,
    corridor_interstate_name: "IH0030-KG",
  },
  {
    id: 518,
    name: "Garand One9 Fuel Network",
    dir: ['B'],
    type: "Private",
    districtName: "Dallas",
    districtNumber: 18,
    mpoName: "North Central Texas Council of Governments",
    mpoNumber: 282,
    corridor_interstate_name: "IH0030-KG",
  },
  {
    id: 519,
    name: "Dallas Knox Fuel",
    dir: ['B'],
    type: "Private",
    districtName: "Dallas",
    districtNumber: 18,
    mpoName: "North Central Texas Council of Governments",
    mpoNumber: 282,
    corridor_interstate_name: "IH0035E-KG",
  },
  {
    id: 520,
    name: "Dallas Quick Trip Davis St",
    dir: ['B'],
    type: "Private",
    districtName: "Dallas",
    districtNumber: 18,
    mpoName: "North Central Texas Council of Governments",
    mpoNumber: 282,
    corridor_interstate_name: "IH0030-KG",
  },
  {
    id: 521,
    name: "Big D Travel Center",
    dir: ['B'],
    type: "Private",
    districtName: "Dallas",
    districtNumber: 18,
    mpoName: "North Central Texas Council of Governments",
    mpoNumber: 282,
    corridor_interstate_name: null,
  },
  {
    id: 522,
    name: "Grand Prarie Mr Fuel",
    dir: ['B'],
    type: "Private",
    districtName: "Dallas",
    districtNumber: 18,
    mpoName: "North Central Texas Council of Governments",
    mpoNumber: 282,
    corridor_interstate_name: null,
  },
  {
    id: 523,
    name: "Fort Worth Loves Travel Stop Garden Acres",
    dir: ['B'],
    type: "Private",
    districtName: "Fort Worth",
    districtNumber: 2,
    mpoName: "North Central Texas Council of Governments",
    mpoNumber: 282,
    corridor_interstate_name: "IH0035W-KG",
  },
  {
    id: 524,
    name: "Fort Worth Qt Travel Stop Everman Pkwy",
    dir: ['B'],
    type: "Private",
    districtName: "Fort Worth",
    districtNumber: 2,
    mpoName: "North Central Texas Council of Governments",
    mpoNumber: 282,
    corridor_interstate_name: "IH0035W-KG",
  },
  {
    id: 525,
    name: "Godley Cefco",
    dir: ['B'],
    type: "Private",
    districtName: "Fort Worth",
    districtNumber: 2,
    mpoName: "North Central Texas Council of Governments",
    mpoNumber: 282,
    corridor_interstate_name: null,
  },
  {
    id: 526,
    name: "Tolar Meyers Qwik Stop",
    dir: ['B'],
    type: "Private",
    districtName: "Fort Worth",
    districtNumber: 2,
    mpoName: "North Central Texas Council of Governments",
    mpoNumber: 282,
    corridor_interstate_name: null,
  },
  {
    id: 527,
    name: "Fort Worth Texaco Truck Stop Benbrook",
    dir: ['B'],
    type: "Private",
    districtName: "Fort Worth",
    districtNumber: 2,
    mpoName: "North Central Texas Council of Governments",
    mpoNumber: 282,
    corridor_interstate_name: null,
  },
  {
    id: 528,
    name: "Big Horn Travel Center",
    dir: ['B'],
    type: "Private",
    districtName: "Fort Worth",
    districtNumber: 2,
    mpoName: "North Central Texas Council of Governments",
    mpoNumber: 282,
    corridor_interstate_name: "IH0020-KG",
  },
  {
    id: 529,
    name: "365 Travel Center Ts",
    dir: ['B'],
    type: "Private",
    districtName: "Fort Worth",
    districtNumber: 2,
    mpoName: "North Central Texas Council of Governments",
    mpoNumber: 282,
    corridor_interstate_name: "IH0035W-KG",
  },
  {
    id: 530,
    name: "Fort Worth Alliance Gateway",
    dir: ['B'],
    type: "Private",
    districtName: "Fort Worth",
    districtNumber: 2,
    mpoName: "North Central Texas Council of Governments",
    mpoNumber: 282,
    corridor_interstate_name: "IH0035W-KG",
  },
  {
    id: 531,
    name: "Haslet Texas Travel Plaza",
    dir: ['B'],
    type: "Private",
    districtName: "Fort Worth",
    districtNumber: 2,
    mpoName: "North Central Texas Council of Governments",
    mpoNumber: 282,
    corridor_interstate_name: null,
  },
  {
    id: 532,
    name: "Haslet Tiger Mart",
    dir: ['B'],
    type: "Private",
    districtName: "Fort Worth",
    districtNumber: 2,
    mpoName: "North Central Texas Council of Governments",
    mpoNumber: 282,
    corridor_interstate_name: null,
  },
  {
    id: 533,
    name: "Elizabethtown Quicktrip",
    dir: ['B'],
    type: "Private",
    districtName: "Dallas",
    districtNumber: 18,
    mpoName: "North Central Texas Council of Governments",
    mpoNumber: 282,
    corridor_interstate_name: "IH0035W-KG",
  },
  {
    id: 534,
    name: "Dallas Whip In Holfords Prarie",
    dir: ['B'],
    type: "Private",
    districtName: "Dallas",
    districtNumber: 18,
    mpoName: "North Central Texas Council of Governments",
    mpoNumber: 282,
    corridor_interstate_name: null,
  },
  {
    id: 535,
    name: "Rockwall Ta Travel Center",
    dir: ['B'],
    type: "Private",
    districtName: "Dallas",
    districtNumber: 18,
    mpoName: "North Central Texas Council of Governments",
    mpoNumber: 282,
    corridor_interstate_name: "IH0030-KG",
  },
  {
    id: 536,
    name: "Rockwall Loves Travel Stop",
    dir: ['B'],
    type: "Private",
    districtName: "Dallas",
    districtNumber: 18,
    mpoName: "North Central Texas Council of Governments",
    mpoNumber: 282,
    corridor_interstate_name: "IH0030-KG",
  },
  {
    id: 537,
    name: "Royse City Tiger Mart",
    dir: ['B'],
    type: "Private",
    districtName: "Dallas",
    districtNumber: 18,
    mpoName: "North Central Texas Council of Governments",
    mpoNumber: 282,
    corridor_interstate_name: null,
  },
  {
    id: 538,
    name: "Royse City Quick Trac09",
    dir: ['B'],
    type: "Private",
    districtName: "Dallas",
    districtNumber: 18,
    mpoName: "North Central Texas Council of Governments",
    mpoNumber: 282,
    corridor_interstate_name: "IH0030-KG",
  },
  {
    id: 539,
    name: "Caddo Mills Mega Fuel Stop",
    dir: ['B'],
    type: "Private",
    districtName: "Paris",
    districtNumber: 1,
    mpoName: "North Central Texas Council of Governments",
    mpoNumber: 282,
    corridor_interstate_name: "IH0030-KG",
  },
  {
    id: 540,
    name: "Caddo Mills Pilot Travel Center",
    dir: ['B'],
    type: "Private",
    districtName: "Paris",
    districtNumber: 1,
    mpoName: "North Central Texas Council of Governments",
    mpoNumber: 282,
    corridor_interstate_name: "IH0030-KG",
  },
  {
    id: 541,
    name: "Greenville Quicktrip Hwy67",
    dir: ['B'],
    type: "Private",
    districtName: "Paris",
    districtNumber: 1,
    mpoName: "North Central Texas Council of Governments",
    mpoNumber: 282,
    corridor_interstate_name: "IH0030-KG",
  },
  {
    id: 542,
    name: "Greenville Circlek Moulton",
    dir: ['B'],
    type: "Private",
    districtName: "Paris",
    districtNumber: 1,
    mpoName: "North Central Texas Council of Governments",
    mpoNumber: 282,
    corridor_interstate_name: "IH0030-KG",
  },
  {
    id: 543,
    name: "Hopkins County",
    dir: ['E', 'W'],
    type: "Public",
    districtName: "Paris",
    districtNumber: 1,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0030-KG",
  },
  {
    id: 544,
    name: "Suplhur Springs Pilot Travel Center",
    dir: ['B'],
    type: "Private",
    districtName: "Paris",
    districtNumber: 1,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0030-KG",
  },
  {
    id: 545,
    name: "Mt Vernon Cefco Travel Center I30",
    dir: ['B'],
    type: "Private",
    districtName: "Paris",
    districtNumber: 1,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0030-KG",
  },
  {
    id: 546,
    name: "Vernon Loves Travel Stop Holbrook",
    dir: ['B'],
    type: "Private",
    districtName: "Paris",
    districtNumber: 1,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0030-KG",
  },
  {
    id: 547,
    name: "Winfield Charge Up",
    dir: ['B'],
    type: "Private",
    districtName: "Atlanta",
    districtNumber: 19,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0030-KG",
  },
  {
    id: 548,
    name: "Omaha Empty Lot Hwy67",
    dir: ['B'],
    type: "Private",
    districtName: "Atlanta",
    districtNumber: 19,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 549,
    name: "Bettie Midway Armadillo Truck Stop",
    dir: ['B'],
    type: "Private",
    districtName: "Atlanta",
    districtNumber: 19,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 550,
    name: "Gilmer Cefco Travel Center",
    dir: ['B'],
    type: "Private",
    districtName: "Atlanta",
    districtNumber: 19,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 551,
    name: "Linden Linden Fuel Center",
    dir: ['B'],
    type: "Private",
    districtName: "Atlanta",
    districtNumber: 19,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 552,
    name: "Punj Truck Stop And Indian",
    dir: ['B'],
    type: "Private",
    districtName: "Atlanta",
    districtNumber: 19,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 553,
    name: "Domino Loves Travel Stop",
    dir: ['B'],
    type: "Private",
    districtName: "Atlanta",
    districtNumber: 19,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 554,
    name: "Texarkana Travel Stop Wallace Dr",
    dir: ['B'],
    type: "Private",
    districtName: "Atlanta",
    districtNumber: 19,
    mpoName: "Texarkana",
    mpoNumber: 211,
    corridor_interstate_name: "IH0369-KG",
  },
  {
    id: 555,
    name: "Texas TIC Texark",
    dir: ['W'],
    type: "Public",
    districtName: "Atlanta",
    districtNumber: 19,
    mpoName: "Texarkana",
    mpoNumber: 211,
    corridor_interstate_name: "IH0030-KG",
  },
  {
    id: 556,
    name: "Hooks Loves Truck Stop",
    dir: ['B'],
    type: "Private",
    districtName: "Atlanta",
    districtNumber: 19,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0030-KG",
  },
  {
    id: 557,
    name: "Hooks Sunshine Travel Plaza",
    dir: ['B'],
    type: "Private",
    districtName: "Atlanta",
    districtNumber: 19,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0030-KG",
  },
  {
    id: 558,
    name: "New Boston Circlek",
    dir: ['B'],
    type: "Private",
    districtName: "Atlanta",
    districtNumber: 19,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 559,
    name: "Paris Shell Main St",
    dir: ['B'],
    type: "Private",
    districtName: "Paris",
    districtNumber: 1,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 560,
    name: "Paris Loves Travel Stop",
    dir: ['B'],
    type: "Private",
    districtName: "Paris",
    districtNumber: 1,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 561,
    name: "Trenton Tex Best Travel Center",
    dir: ['B'],
    type: "Private",
    districtName: "Paris",
    districtNumber: 1,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 562,
    name: "Anna Loves Travel Stop White St",
    dir: ['B'],
    type: "Private",
    districtName: "Dallas",
    districtNumber: 18,
    mpoName: "North Central Texas Council of Governments",
    mpoNumber: 282,
    corridor_interstate_name: null,
  },
  {
    id: 563,
    name: "Anna Flying J Travel Center",
    dir: ['B'],
    type: "Private",
    districtName: "Dallas",
    districtNumber: 18,
    mpoName: "North Central Texas Council of Governments",
    mpoNumber: 282,
    corridor_interstate_name: null,
  },
  {
    id: 564,
    name: "Texas TIC Denison",
    dir: ['N'],
    type: "Public",
    districtName: "Paris",
    districtNumber: 1,
    mpoName: "Grayson County",
    mpoNumber: 232,
    corridor_interstate_name: null,
  },
  {
    id: 565,
    name: "Whitesboro Loves Travel Stop",
    dir: ['B'],
    type: "Private",
    districtName: "Paris",
    districtNumber: 1,
    mpoName: "Grayson County",
    mpoNumber: 232,
    corridor_interstate_name: null,
  },
  {
    id: 566,
    name: "Gainesville",
    dir: ['S'],
    type: "Public",
    districtName: "Wichita Falls",
    districtNumber: 3,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0035-KG",
  },
  {
    id: 567,
    name: "Gainesville Road Ranger",
    dir: ['B'],
    type: "Private",
    districtName: "Wichita Falls",
    districtNumber: 3,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0035-KG",
  },
  {
    id: 568,
    name: "Gainesville Quicktrip Summit Ave",
    dir: ['B'],
    type: "Private",
    districtName: "Wichita Falls",
    districtNumber: 3,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0035-KG",
  },
  {
    id: 569,
    name: "Valleyview",
    dir: ['N', 'S'],
    type: "Public",
    districtName: "Wichita Falls",
    districtNumber: 3,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 570,
    name: "Valley View One9 Fuel Network",
    dir: ['B'],
    type: "Private",
    districtName: "Wichita Falls",
    districtNumber: 3,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0035-KG",
  },
  {
    id: 571,
    name: "Drop Tine Services",
    dir: ['B'],
    type: "Private",
    districtName: "Dallas",
    districtNumber: 18,
    mpoName: "North Central Texas Council of Governments",
    mpoNumber: 282,
    corridor_interstate_name: "IH0035-KG",
  },
  {
    id: 572,
    name: "Denton Loves Travel Stop Barthold Rd",
    dir: ['B'],
    type: "Private",
    districtName: "Dallas",
    districtNumber: 18,
    mpoName: "North Central Texas Council of Governments",
    mpoNumber: 282,
    corridor_interstate_name: "IH0035-KG",
  },
  {
    id: 573,
    name: "Denton Ta Travel Center Purple Heart Trl",
    dir: ['B'],
    type: "Private",
    districtName: "Dallas",
    districtNumber: 18,
    mpoName: "North Central Texas Council of Governments",
    mpoNumber: 282,
    corridor_interstate_name: "IH0035-KG",
  },
  {
    id: 574,
    name: "Springtown Qwik Stop",
    dir: ['B'],
    type: "Private",
    districtName: "Fort Worth",
    districtNumber: 2,
    mpoName: "North Central Texas Council of Governments",
    mpoNumber: 282,
    corridor_interstate_name: null,
  },
  {
    id: 575,
    name: "Rhome Loves Travel Stop",
    dir: ['B'],
    type: "Private",
    districtName: "Fort Worth",
    districtNumber: 2,
    mpoName: "North Central Texas Council of Governments",
    mpoNumber: 282,
    corridor_interstate_name: null,
  },
  {
    id: 576,
    name: "Big Z Travel Center",
    dir: ['B'],
    type: "Private",
    districtName: "Fort Worth",
    districtNumber: 2,
    mpoName: "North Central Texas Council of Governments",
    mpoNumber: 282,
    corridor_interstate_name: null,
  },
  {
    id: 577,
    name: "New Fairview Pilot Travel Center Hwy287",
    dir: ['B'],
    type: "Private",
    districtName: "Fort Worth",
    districtNumber: 2,
    mpoName: "North Central Texas Council of Governments",
    mpoNumber: 282,
    corridor_interstate_name: null,
  },
  {
    id: 578,
    name: "Decatur Shell US287",
    dir: ['B'],
    type: "Private",
    districtName: "Fort Worth",
    districtNumber: 2,
    mpoName: "North Central Texas Council of Governments",
    mpoNumber: 282,
    corridor_interstate_name: null,
  },
  {
    id: 579,
    name: "Decatur Alon US87",
    dir: ['B'],
    type: "Private",
    districtName: "Fort Worth",
    districtNumber: 2,
    mpoName: "North Central Texas Council of Governments",
    mpoNumber: 282,
    corridor_interstate_name: null,
  },
  {
    id: 580,
    name: "Decatur Truck And Auto Plaza Ts",
    dir: ['B'],
    type: "Private",
    districtName: "Fort Worth",
    districtNumber: 2,
    mpoName: "North Central Texas Council of Governments",
    mpoNumber: 282,
    corridor_interstate_name: null,
  },
  {
    id: 581,
    name: "Denton Jj Truck Stop",
    dir: ['B'],
    type: "Private",
    districtName: "Dallas",
    districtNumber: 18,
    mpoName: "North Central Texas Council of Governments",
    mpoNumber: 282,
    corridor_interstate_name: null,
  },
  {
    id: 582,
    name: "Denton Martin Eagle Oil",
    dir: ['B'],
    type: "Private",
    districtName: "Dallas",
    districtNumber: 18,
    mpoName: "North Central Texas Council of Governments",
    mpoNumber: 282,
    corridor_interstate_name: null,
  },
  {
    id: 583,
    name: "Bridgeport Loves Travel Stop",
    dir: ['B'],
    type: "Private",
    districtName: "Fort Worth",
    districtNumber: 2,
    mpoName: "North Central Texas Council of Governments",
    mpoNumber: 282,
    corridor_interstate_name: null,
  },
  {
    id: 584,
    name: "Bridgeport Quick Trac 101",
    dir: ['B'],
    type: "Private",
    districtName: "Fort Worth",
    districtNumber: 2,
    mpoName: "North Central Texas Council of Governments",
    mpoNumber: 282,
    corridor_interstate_name: null,
  },
  {
    id: 585,
    name: "Wise County",
    dir: ['N'],
    type: "Public",
    districtName: "Fort Worth",
    districtNumber: 2,
    mpoName: "North Central Texas Council of Governments",
    mpoNumber: 282,
    corridor_interstate_name: null,
  },
  {
    id: 586,
    name: "Alvord Paradise Truck Park",
    dir: ['B'],
    type: "Private",
    districtName: "Fort Worth",
    districtNumber: 2,
    mpoName: "North Central Texas Council of Governments",
    mpoNumber: 282,
    corridor_interstate_name: null,
  },
  {
    id: 587,
    name: "Alvord Trucker Parking Paradise Quick Stop",
    dir: ['B'],
    type: "Private",
    districtName: "Fort Worth",
    districtNumber: 2,
    mpoName: "North Central Texas Council of Governments",
    mpoNumber: 282,
    corridor_interstate_name: null,
  },
  {
    id: 588,
    name: "Sunset",
    dir: ['N', 'S'],
    type: "Public",
    districtName: "Wichita Falls",
    districtNumber: 3,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 589,
    name: "Bowie Star Travel Center",
    dir: ['B'],
    type: "Private",
    districtName: "Wichita Falls",
    districtNumber: 3,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 590,
    name: "Henrietta",
    dir: ['N'],
    type: "Public",
    districtName: "Wichita Falls",
    districtNumber: 3,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 591,
    name: "Wichita Falls Loves Travel Stop Windthorst",
    dir: ['B'],
    type: "Private",
    districtName: "Wichita Falls",
    districtNumber: 3,
    mpoName: "Wichita Falls",
    mpoNumber: 151,
    corridor_interstate_name: null,
  },
  {
    id: 592,
    name: "Wichita Falls Flying J Travel Center",
    dir: ['B'],
    type: "Private",
    districtName: "Wichita Falls",
    districtNumber: 3,
    mpoName: "Wichita Falls",
    mpoNumber: 151,
    corridor_interstate_name: "IH0044-KG",
  },
  {
    id: 593,
    name: "Iowa Park Cefco Travel Center",
    dir: ['B'],
    type: "Private",
    districtName: "Wichita Falls",
    districtNumber: 3,
    mpoName: "Wichita Falls",
    mpoNumber: 151,
    corridor_interstate_name: null,
  },
  {
    id: 594,
    name: "Wichita County",
    dir: ['E', 'W'],
    type: "Public",
    districtName: "Wichita Falls",
    districtNumber: 3,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 595,
    name: "Cisco Flying J Dealer Cisco Travel Plaza",
    dir: ['B'],
    type: "Private",
    districtName: "Brownwood",
    districtNumber: 23,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0020-KG",
  },
  {
    id: 596,
    name: "Cisco Stripes 206",
    dir: ['B'],
    type: "Private",
    districtName: "Brownwood",
    districtNumber: 23,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0020-KG",
  },
  {
    id: 597,
    name: "Cowpokes",
    dir: ['B'],
    type: "Private",
    districtName: "Brownwood",
    districtNumber: 23,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0020-KG",
  },
  {
    id: 598,
    name: "Eastland Texaco I20",
    dir: ['B'],
    type: "Private",
    districtName: "Brownwood",
    districtNumber: 23,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0020-KG",
  },
  {
    id: 599,
    name: "Eastland County",
    dir: ['E', 'W'],
    type: "Public",
    districtName: "Brownwood",
    districtNumber: 23,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0020-KG",
  },
  {
    id: 600,
    name: "Strawn",
    dir: ['E', 'W'],
    type: "Public",
    districtName: "Brownwood",
    districtNumber: 23,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0020-KG",
  },
  {
    id: 601,
    name: "Gordon Texaco I20",
    dir: ['B'],
    type: "Private",
    districtName: "Fort Worth",
    districtNumber: 2,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0020-KG",
  },
  {
    id: 602,
    name: "Weatherford Pilot Travel Center Old Dennis",
    dir: ['B'],
    type: "Private",
    districtName: "Fort Worth",
    districtNumber: 2,
    mpoName: "North Central Texas Council of Governments",
    mpoNumber: 282,
    corridor_interstate_name: "IH0020-KG",
  },
  {
    id: 603,
    name: "Weatherford Quick Trip Bowie",
    dir: ['B'],
    type: "Private",
    districtName: "Fort Worth",
    districtNumber: 2,
    mpoName: "North Central Texas Council of Governments",
    mpoNumber: 282,
    corridor_interstate_name: "IH0020-KG",
  },
  {
    id: 604,
    name: "406 Chrome Shop Truck And Travel Center",
    dir: ['B'],
    type: "Private",
    districtName: "Fort Worth",
    districtNumber: 2,
    mpoName: "North Central Texas Council of Governments",
    mpoNumber: 282,
    corridor_interstate_name: "IH0020-KG",
  },
  {
    id: 605,
    name: "Weatherford Petro Travel Center",
    dir: ['B'],
    type: "Private",
    districtName: "Fort Worth",
    districtNumber: 2,
    mpoName: "North Central Texas Council of Governments",
    mpoNumber: 282,
    corridor_interstate_name: "IH0020-KG",
  },
  {
    id: 606,
    name: "Weatherford Loves Truck Stop",
    dir: ['B'],
    type: "Private",
    districtName: "Fort Worth",
    districtNumber: 2,
    mpoName: "North Central Texas Council of Governments",
    mpoNumber: 282,
    corridor_interstate_name: "IH0020-KG",
  },
  {
    id: 607,
    name: "Van Zandt County",
    dir: ['E', 'W'],
    type: "Public",
    districtName: "Tyler",
    districtNumber: 10,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0020-KG",
  },
  {
    id: 608,
    name: "Hamilton Cefco Ross",
    dir: ['B'],
    type: "Private",
    districtName: "Waco",
    districtNumber: 9,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "US281",
  },
  {
    id: 609,
    name: "Gatesville Priutts",
    dir: ['B'],
    type: "Private",
    districtName: "Waco",
    districtNumber: 9,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 610,
    name: "Waco Cefco Bagby",
    dir: ['B'],
    type: "Private",
    districtName: "Waco",
    districtNumber: 9,
    mpoName: "Waco",
    mpoNumber: 140,
    corridor_interstate_name: null,
  },
  {
    id: 611,
    name: "Waco Cefco Imperial",
    dir: ['B'],
    type: "Private",
    districtName: "Waco",
    districtNumber: 9,
    mpoName: "Waco",
    mpoNumber: 140,
    corridor_interstate_name: null,
  },
  {
    id: 612,
    name: "Bellmead Cefco",
    dir: ['B'],
    type: "Private",
    districtName: "Waco",
    districtNumber: 9,
    mpoName: "Waco",
    mpoNumber: 140,
    corridor_interstate_name: null,
  },
  {
    id: 613,
    name: "Riesel Cefco",
    dir: ['B'],
    type: "Private",
    districtName: "Waco",
    districtNumber: 9,
    mpoName: "Waco",
    mpoNumber: 140,
    corridor_interstate_name: null,
  },
  {
    id: 614,
    name: "Kosse Exxon",
    dir: ['B'],
    type: "Private",
    districtName: "Waco",
    districtNumber: 9,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 615,
    name: "Prairie Hill Sunmart",
    dir: ['B'],
    type: "Private",
    districtName: "Waco",
    districtNumber: 9,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 616,
    name: "97.0880518°W 31.8979715°N",
    dir: ['B'],
    type: "Private",
    districtName: "Waco",
    districtNumber: 9,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: null,
  },
  {
    id: 617,
    name: "Abbott Travel Center Ts",
    dir: ['B'],
    type: "Private",
    districtName: "Waco",
    districtNumber: 9,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0035-KG",
  },
  {
    id: 618,
    name: "Hillsboro El Taco Jalisco",
    dir: ['B'],
    type: "Private",
    districtName: "Waco",
    districtNumber: 9,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0035-KG",
  },
  {
    id: 619,
    name: "Waco Quicktrip New Rd",
    dir: ['B'],
    type: "Private",
    districtName: "Waco",
    districtNumber: 9,
    mpoName: "Waco",
    mpoNumber: 140,
    corridor_interstate_name: "IH0035-KG",
  },
  {
    id: 620,
    name: "Hewitt Circlek",
    dir: ['B'],
    type: "Private",
    districtName: "Waco",
    districtNumber: 9,
    mpoName: "Waco",
    mpoNumber: 140,
    corridor_interstate_name: null,
  },
  {
    id: 621,
    name: "Temple Texstar Travel Center I35",
    dir: ['B'],
    type: "Private",
    districtName: "Waco",
    districtNumber: 9,
    mpoName: "Killeen-Temple",
    mpoNumber: 277,
    corridor_interstate_name: "IH0035-KG",
  },
  {
    id: 622,
    name: "Jarrell Texas Travel Center",
    dir: ['B'],
    type: "Private",
    districtName: "Waco",
    districtNumber: 9,
    mpoName: "Killeen-Temple",
    mpoNumber: 277,
    corridor_interstate_name: "IH0035-KG",
  },
  {
    id: 623,
    name: "Tye Yesway",
    dir: ['B'],
    type: "Private",
    districtName: "Abilene",
    districtNumber: 8,
    mpoName: "Abilene",
    mpoNumber: 166,
    corridor_interstate_name: "IH0020-KG",
  },
  {
    id: 624,
    name: "Tye Wes T Go Travel Center",
    dir: ['B'],
    type: "Private",
    districtName: "Abilene",
    districtNumber: 8,
    mpoName: "Abilene",
    mpoNumber: 166,
    corridor_interstate_name: "IH0020-KG",
  },
  {
    id: 625,
    name: "Cotulla Flying J Travel Center",
    dir: ['B'],
    type: "Private",
    districtName: "Laredo",
    districtNumber: 22,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0035-KG",
  },
  {
    id: 626,
    name: "Fort Worth Drivers Truck Wash",
    dir: ['B'],
    type: "Private",
    districtName: "Fort Worth",
    districtNumber: 2,
    mpoName: "North Central Texas Council of Governments",
    mpoNumber: 282,
    corridor_interstate_name: "IH0035W-KG",
  },
  {
    id: 627,
    name: "Huntsville Kudos Travel Center And Truck Stop",
    dir: ['B'],
    type: "Private",
    districtName: "Bryan",
    districtNumber: 17,
    mpoName: null,
    mpoNumber: null,
    corridor_interstate_name: "IH0045-KG",
  },
];
