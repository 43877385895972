import React, { useContext, useEffect, useState } from "react";
import {
  months,
  parkingDurationOptions,
  sitesYearsMonths,
} from "../common/constants";

import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import HighContrasLight from "highcharts/themes/high-contrast-light";
import { QueryContext } from "../context/QueryContext";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import StackedBarChart from "./HighCharts/StackedBarChart";
import Typography from "@mui/material/Typography";
import { findSiteUnoffRatioByDirYearMonthDay } from "../data-loaders/DataLoaders";
import { gridStyles } from "../ui/styles";
import { parkingSites } from "../common/parkingSites";
import { setChartTitle } from "../utils/utils";

// import BoxPlot from "./HighCharts/BoxPlot";

function ChartsLayoutPrivateSites(props) {
  const [query, dispatch] = useContext(QueryContext);
  const classesGrid = gridStyles();
  const {
    selectedSite,
    parkingSiteGeom,
    chartHeight,
    siteOffUnoffByYearMonthHourData,
    siteOffUnoffByYearMonthDayData,
  } = props;
  // console.log("siteSelected-check", siteSelected);

  let hourlyCountsSummaryVars = [
    "unauth_ratio",
    "unauth1_ratio",
    "unauth1_3_ratio",
    "unauth3_7_ratio",
    "unauth7_11_ratio",
    "unauth11_ratio",
  ];
  useEffect(() => {
    if (selectedSite?.name !== "All") {
      let siteDirs = selectedSite?.dir;
      // console.log("siteDirs-check", siteDirs);
      siteDirs.map((ste, index) => {
        // console.log("ste-check", ste);
        let siteLabel =
          selectedSite?.name +
          (ste === "N"
            ? " - Northbound"
            : ste === "S"
            ? " - Southbound"
            : ste === "W"
            ? " - Westbound"
            : ste === "E"
            ? " - Eastbound"
            : "");

        findSiteUnoffRatioByDirYearMonthDay(
          selectedSite?.name,
          ste,
          query.selectedSite.year,
          query.selectedSite.month
        ).then((response) => {
          let daysTemp = [];
          let chartDataTemp = [];

          response.data.map((data) => {
            daysTemp.push(data.start_day);

            hourlyCountsSummaryVars.map((item, index) => {
              if (!chartDataTemp[index]) {
                chartDataTemp[index] = [];
              }
              chartDataTemp[index].push(data[item]);
            });
          });

          let mergedChartData = [];

          hourlyCountsSummaryVars.map((item, index) => {
            mergedChartData = [
              ...mergedChartData,
              { name: item, data: chartDataTemp[index] },
            ];
          });
        });
      });
    }
  }, [selectedSite, query.selectedSite.year, query.selectedSite.month]);

  function setChartDataOffUnoffByYearMonthHourData(direction, category) {
    let tempHourData = siteOffUnoffByYearMonthHourData.filter(
      (obj) => obj.direction === direction
    );
    let startMonths = [];
    sitesYearsMonths
      .filter((itm) => itm.year === query.selectedSite.year)
      .map((option) => startMonths.push(option.month));
    let chartData = [];
    sitesYearsMonths
      .filter((itm) => itm.year === query.selectedSite.year)
      .map((itm) => {
        let monthObj = itm.month;
        let tempData = [];
        tempHourData
          .filter((hrData) => hrData.start_month === monthObj)
          .map((dt) => (tempData[dt.start_hour] = dt[category]));
        chartData.push({
          name: months[monthObj].description,
          data: tempData,
          id: monthObj.toLocaleString() + "-month",
          color: itm.color,
        });
      });

    return chartData;
  }
  function setChartDataOffUnoffByYearMonthDayData(direction, category) {
    let tempDayData = siteOffUnoffByYearMonthDayData.filter(
      (obj) => obj.direction === direction
    );
    let startMonths = [];
    sitesYearsMonths
      .filter((itm) => itm.year === query.selectedSite.year)
      .map((option) => startMonths.push(option.month));

    let chartData = [];
    sitesYearsMonths
      .filter((itm) => itm.year === query.selectedSite.year)
      .map((itm) => {
        let monthObj = itm.month;
        let tempData = [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0,
        ];
        tempDayData
          .filter((dayData) => dayData.start_month === monthObj)
          .map((dt) => (tempData[dt.start_day] = dt[category]));
        chartData.push({
          name: months[monthObj].description,
          data: tempData,
          color: itm.color,
        });
      });

    return chartData;
  }

  const [selectedSitesDuration, setSelectedSitesDuration] = useState(
    parkingDurationOptions[0].value
  );

  const handleChangeParkingDuration = (event) => {
    setSelectedSitesDuration(event.target.value);
  };

  return (
    <>
      {selectedSite?.name !== "All" &&
        selectedSite.dir.map((dir) => {
          return (
            <Grid className="site-chart-grid-container" container spacing={1}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Divider
                  component="div"
                  role="presentation"
                  textAlign="center"
                  className="site-chart-chip"
                >
                  {/* any elements nested inside the role="presentation" preserve their semantics. */}

                  <Chip
                    label={
                      <Typography
                        variant="h5"
                        // component="div"
                        // sx={{ padding: "5px", textAlign: "center" }}
                        sx={{ margin: 5, padding: 5 }}
                      >
                        Utilization
                      </Typography>
                    }
                    sx={{ backgroundColor: "#a9a9a9" }}
                  />
                </Divider>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl className={classesGrid.formLabel}>
                  <FormLabel
                    id="sites-duration-radio-buttons-group-label"
                    className={classesGrid.formLabel}
                  >
                    Parking Duration
                  </FormLabel>
                  <RadioGroup
                    aria-label="gender"
                    name="duration-radio-buttons-group"
                    value={selectedSitesDuration}
                    onChange={handleChangeParkingDuration}
                    className={classesGrid.formLabel}
                    row
                  >
                    {parkingDurationOptions.map((option) => (
                      <FormControlLabel
                        value={option.value}
                        control={<Radio size="small" />}
                        label={option.label}
                        key={"sitesDurations-" + option.value}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Grid>
              {query.selectedComponents.utilizationByHour && (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={6}
                  id="sites_util_hour"
                  style={{ paddingRight: 5, paddingTop: 10 }}
                >
                  <StackedBarChart
                    chartData={setChartDataOffUnoffByYearMonthHourData(
                      dir,
                      parkingDurationOptions.filter(
                        (durationOpt) =>
                          durationOpt.value === selectedSitesDuration
                      )[0].authorizedField
                    )}
                    plotOptionsColumnStacking={"normal"}
                    plotOptionsColumnDataLabelsEndabled={false}
                    chartHeight={"50%"}
                    themeHC={HighContrasLight}
                    yAxisTitleText={"# of Parking Events"}
                    legendSpecs={{
                      layout: "vertical",
                      align: "right",
                      verticalAlign: "middle",
                      itemMarginTop: 0.5,
                      itemMarginBottom: 0,
                      itemStyle: {
                        fontSize: 10.5,
                      },
                    }}
                    titleText={
                      setChartTitle(
                        query.selectedSite.year,
                        selectedSite?.name,
                        dir
                      ) + " Average Utilization by Hour"
                    }
                    subtitleText={
                      selectedSitesDuration !== "all"
                        ? "Parking " +
                          parkingDurationOptions.filter(
                            (durationOpt) =>
                              durationOpt.value === selectedSitesDuration
                          )[0].label
                        : " "
                    }
                    xAxisTitle={{
                      enabled: true,
                      text: "Hour (Hour 0 is 12 a.m.)",
                    }}
                  />
                </Grid>
              )}
              {query.selectedComponents.utilizationByDay && (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={6}
                  id="sites_util_day"
                  style={{ paddingLeft: 5, paddingTop: 10 }}
                >
                  <StackedBarChart
                    chartData={setChartDataOffUnoffByYearMonthDayData(
                      dir,
                      parkingDurationOptions.filter(
                        (duration) => duration.value === selectedSitesDuration
                      )[0].authorizedField
                    )}
                    plotOptionsColumnStacking={"normal"}
                    plotOptionsColumnDataLabelsEndabled={false}
                    chartHeight={"50%"}
                    themeHC={HighContrasLight}
                    yAxisTitleText={"# of Parking Events"}
                    legendSpecs={{
                      layout: "vertical",
                      align: "right",
                      verticalAlign: "middle",
                      itemMarginTop: 0.5,
                      itemMarginBottom: 0,
                      itemStyle: {
                        fontSize: 10.5,
                      },
                    }}
                    titleText={
                      setChartTitle(
                        query.selectedSite.year,
                        selectedSite?.name,
                        dir
                      ) + " Average Utilization by Day"
                    }
                    subtitleText={
                      selectedSitesDuration !== "all"
                        ? "Parking " +
                          parkingDurationOptions.filter(
                            (duration) =>
                              duration.value === selectedSitesDuration
                          )[0].label
                        : " "
                    }
                    xAxisTitle={{
                      enabled: true,
                      text: "Day",
                    }}
                  />
                </Grid>
              )}
            </Grid>
          );
        })}
    </>
  );
}

export default ChartsLayoutPrivateSites;
