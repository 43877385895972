import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import axios from "axios";
import {
  months,
  parkingDurationOptions,
  sitesYearsMonths,
} from "../common/constants";
import { parkingSites } from "../common/parkingSites";
import { setChartTitle } from "../utils/utils";
import { QueryContext } from "../context/QueryContext";
import { findSiteUnoffRatioByDirYearMonthDay } from "../data-loaders/DataLoaders";
import { gridStyles } from "../ui/styles";

import {
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "../components/mui";

import Donut3dChart from "./HighCharts/Donut3dChart";
import HighContrasLight from "highcharts/themes/high-contrast-light";
import MultiLineChart from "./HighCharts/MultiLineChart";
import StackedBarChart from "./HighCharts/StackedBarChart";

const monthsDescription = [
  { value: 1, text: "January", color: "#7cb5ec" },
  { value: 2, text: "February", color: "#434348" },
  { value: 3, text: "March", color: "#90ed7d" },
  { value: 4, text: "April", color: "#f7a35c" },
  { value: 5, text: "May", color: "#8085e9" },
  { value: 6, text: "June", color: "#f15c80" },
  { value: 7, text: "July", color: "#e4d354" },
  { value: 8, text: "August", color: "#2b908f" },
  { value: 9, text: "September", color: "#f45b5b" },
  { value: 10, text: "October", color: "#91e8e1" },
  { value: 11, text: "November", color: "#4572A7" },
  { value: 12, text: "December", color: "#8bbc21" },
];

function ChartsLayoutSites(props) {
  const [query, dispatch] = useContext(QueryContext);
  const classesGrid = gridStyles();
  //   const classes = useStyles();
  const {
    selectedSite,
    siteOffUnoffByYearMonthHourData,
    siteOffUnoffByYearMonthDayData,
  } = props;
  const [unofficialDataChart, setUnofficialDataChart] = useState(null);
  const [siteDirs, setSiteDirs] = useState(null);
  let hourlyCountsSummaryVars = [
    "unauth_ratio",
    "unauth1_ratio",
    "unauth1_3_ratio",
    "unauth3_7_ratio",
    "unauth7_11_ratio",
    "unauth11_ratio",
  ];
  useEffect(() => {
    if (selectedSite?.name !== "All") {
      let siteDirsTemp = selectedSite.dir;
      setSiteDirs(siteDirsTemp);
      //console.log("siteDirs-check", siteDirs);
      siteDirsTemp &&
        siteDirsTemp.map((ste, index) => {
          // console.log("ste-check", ste);
          let siteLabel =
            selectedSite?.name +
            (ste === "N"
              ? " - Northbound"
              : ste === "S"
              ? " - Southbound"
              : ste === "W"
              ? " - Westbound"
              : ste === "E"
              ? " - Eastbound"
              : "");
          // let dailyAveTotalCount = (
          //   siteEventsGeom.filter(
          //     (obj) => obj.sitename === selectedSite?.name && obj.dir === ste
          //   ).length / 31
          // ).toFixed(0);

          // let tempSiteData = [];
          // parkingSiteGeom.features.map((fc) =>
          //   tempSiteData.push(fc.properties)
          // );

          // let spaces = 0;
          // if (
          //   tempSiteData.filter(
          //     (fc) => fc.dir === ste && fc.authorized === 1
          //   )[0]
          // )
          //   spaces = tempSiteData.filter(
          //     (fc) => fc.dir === ste && fc.authorized === 1
          //   )[0].space;
          // tempSpaceRatioData.push({
          //   siteName: selectedSite?.name,
          //   dir: ste,
          //   yearMonth: siteSelected.yearMonth,
          //   siteLabel: siteLabel,
          //   totalCount: dailyAveTotalCount,
          //   spaces: spaces,
          //   spaceRatio: spaces > 0 ? (dailyAveTotalCount / spaces) * 100 : 0,
          // });

          findSiteUnoffRatioByDirYearMonthDay(
            selectedSite?.name,
            ste,
            query.selectedSite.year,
            query.selectedSite.month
          ).then((response) => {
            // console.log("response-check", response);
            // setSiteEventsGeom(response.data);

            let daysTemp = [];
            let chartDataTemp = [];

            response.data.map((obj) => {
              //push start day to daysTemp
              daysTemp.push(obj.start_day);

              hourlyCountsSummaryVars.map((item, index) => {
                if (!chartDataTemp[index]) {
                  chartDataTemp[index] = [];
                }
                chartDataTemp[index].push(obj[item]);
              });
            });

            let mergedChartData = [];

            hourlyCountsSummaryVars.map((item, index) => {
              mergedChartData = [
                ...mergedChartData,
                { name: item, data: chartDataTemp[index] },
              ];
            });
            if (index === 0)
              setUnofficialDataChart({
                siteName: selectedSite?.name,
                dir: ste,
                yearMonth: query.selectedSite.yearMonth,
                siteLabel: siteLabel,
                days: daysTemp,
                chartData: mergedChartData,
              });
          });
        });
    }
  }, [selectedSite, query.selectedSite.year, query.selectedSite.month]);

  function setChartDataOffUnoffByYearMonthHourData(
    direction,
    category,
    yAxisNumber,
    xAxisNumber,
    showInLegend
  ) {
    let tempHourData = siteOffUnoffByYearMonthHourData.filter(
      (obj) => obj.direction === direction
    );
    // let startMonths = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    let startMonths = [];
    sitesYearsMonths
      .filter((itm) => itm.year === query.selectedSite.year)
      .map((option) => startMonths.push(option.month));
    let chartData = [];
    // monthsDescription.map((itm) => {
    sitesYearsMonths
      .filter((itm) => itm.year === query.selectedSite.year)
      .map((itm) => {
        // let obj = itm.value;
        let month = itm.month;
        let tempData = [];
        tempHourData
          .filter((hrData) => hrData.start_month === month)
          .map((dt) => (tempData[dt.start_hour] = dt[category]));
        chartData.push({
          // name: "Month " + obj.toLocaleString(),
          // name: itm.text,
          name: months[month].description,
          data: tempData,
          // offset: 0,
          // yAxis: yAxisNumber,
          // xAxis: yAxisNumber,
          // showInLegend: showInLegend,
          // id: obj.toLocaleString() + "-month-" + yAxisNumber,
          id: month.toLocaleString() + "-month",
          // pointWidth: 5,
          // groupPadding: 0,
          // pointPadding: 0,
          // type: "column",
          color: itm.color,
        });
      });

    // console.log("chartData-check", chartData);
    return chartData;
  }
  // console.log(
  //   "siteOffUnoffByYearMonthDayData-check",
  //   siteOffUnoffByYearMonthDayData
  // );
  function setChartDataOffUnoffByYearMonthDayData(
    direction,
    category,
    yAxisNumber,
    xAxisNumber,
    showInLegend
  ) {
    let tempDayData = siteOffUnoffByYearMonthDayData.filter(
      (obj) => obj.direction === direction
    );
    // let startMonths = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    let startMonths = [];
    sitesYearsMonths
      .filter((itm) => itm.year === query.selectedSite.year)
      .map((option) => startMonths.push(option.month));

    let chartData = [];
    // monthsDescription.map((itm) => {
    sitesYearsMonths
      .filter((itm) => itm.year === query.selectedSite.year)
      .map((itm) => {
        let month = itm.month;
        let tempData = [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0,
        ];
        tempDayData
          .filter((dayData) => dayData.start_month === month)
          .map((dt) => (tempData[dt.start_day] = dt[category]));
        // console.log("tempData-day-check", tempData);
        chartData.push({
          // name: "Month " + obj.toLocaleString(),
          name: months[month].description,
          data: tempData,
          // offset: 0,
          // yAxis: yAxisNumber,
          // xAxis: yAxisNumber,
          // showInLegend: showInLegend,
          // id: obj.toLocaleString() + "-month-" + yAxisNumber,
          id: month.toLocaleString() + "-monthDay",
          // pointWidth: 5,
          // groupPadding: 0,
          // pointPadding: 0,
          // type: "column",
          color: itm.color,
        });
      });

    // console.log("chartData-check", chartData);
    return chartData;
  }

  const [selectedSitesDuration, setSelectedSitesDuration] = useState(
    parkingDurationOptions[0].value
  );

  const handleChangeParkingDuration = (event) => {
    setSelectedSitesDuration(event.target.value);
    // if (event.target.value === "leaflet") setDeckGlMapTypeOptionsEnabled(true);
    // else setDeckGlMapTypeOptionsEnabled(false);
    // dispatch({
    //   type: "setSelectedVehicleWeightClass",
    //   selection: event.target.value,
    // });
  };

  return (
    <Grid container spacing={1} className="site-chart-grid-container">
      {query.selectedComponents.utilizationByHour && (
        <React.Fragment>
          <Grid item xs={12} sm={12} md={12} lg={12} id="sites_util_hour">
            <Divider
              component="div"
              role="presentation"
              textAlign="center"
              className="site-chart-chip"
            >
              {/* any elements nested inside the role="presentation" preserve their semantics. */}

              <Chip
                label={
                  <Typography
                    variant="h5"
                    // component="div"
                    // sx={{ padding: "5px", textAlign: "center" }}
                    sx={{ margin: 5, padding: 5 }}
                  >
                    Utilization by Hour
                  </Typography>
                }
                sx={{ backgroundColor: "#a9a9a9" }}
              />
            </Divider>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <FormControl className={classesGrid.formLabel}>
              <FormLabel
                id="sites-duration-radio-buttons-group-label_hour"
                className={classesGrid.formLabel}
              >
                Parking Duration
              </FormLabel>
              <RadioGroup
                aria-label="gender"
                name="duration-radio-buttons-group"
                value={selectedSitesDuration}
                onChange={handleChangeParkingDuration}
                className={classesGrid.formLabel}
                row
              >
                {parkingDurationOptions.map((option, index) => (
                  <FormControlLabel
                    // value="mapbox://styles/mapbox/streets-v11"
                    value={option.value}
                    control={<Radio size="small" />}
                    label={option.label}
                    key={"utilHour-sitesDurations-" + option.value}
                    // className={classesGrid.formLabel}
                    // disabled={false}
                    // disabled={regionSelected.year === 2018 ? true : false}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
          {selectedSite?.name !== "All" &&
            selectedSite.dir.map((dir) => {
              return (
                <React.Fragment key={dir}>
                  <Grid item xs={12} sm={12} md={12} lg={6}>
                    <StackedBarChart
                      id={selectedSite?.name + dir + "authorized"}
                      chartData={setChartDataOffUnoffByYearMonthHourData(
                        dir,
                        parkingDurationOptions.filter(
                          (obj) => obj.value === selectedSitesDuration
                        )[0].authorizedField
                      )}
                      plotOptionsColumnStacking={"normal"}
                      plotOptionsColumnDataLabelsEndabled={false}
                      chartHeight={"35%"}
                      chartWidth={"50%"}
                      themeHC={HighContrasLight}
                      yAxisTitleText={"# of Parking Events"}
                      legendSpecs={{
                        layout: "vertical",
                        align: "right",
                        verticalAlign: "middle",
                        itemMarginTop: 0.5,
                        itemMarginBottom: 0,
                        itemStyle: {
                          // color: "#000000",
                          // fontWeight: "bold",
                          fontSize: 10.5,
                        },
                      }}
                      titleText={
                        setChartTitle(
                          query.selectedSite.year,
                          selectedSite?.name,
                          dir
                        ) + " Average Authorized Utilization by Hour"
                      }
                      subtitleText={
                        selectedSitesDuration !== "all"
                          ? "Parking " +
                            parkingDurationOptions.filter(
                              (obj) => obj.value === selectedSitesDuration
                            )[0].label
                          : " "
                      }
                      xAxisTitle={{
                        enabled: true,
                        text: "Hour (Hour 0 is 12 a.m.)",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={6}>
                    <StackedBarChart
                      id={selectedSite?.name + dir + "unauthorized"}
                      chartData={setChartDataOffUnoffByYearMonthHourData(
                        dir,
                        parkingDurationOptions.filter(
                          (obj) => obj.value === selectedSitesDuration
                        )[0].unauthorizedField
                      )}
                      plotOptionsColumnStacking={"normal"}
                      plotOptionsColumnDataLabelsEndabled={false}
                      chartHeight={"35%"}
                      chatWidth="50%"
                      themeHC={HighContrasLight}
                      yAxisTitleText={"# of Parking Events"}
                      legendSpecs={{
                        layout: "vertical",
                        align: "right",
                        verticalAlign: "middle",
                        itemMarginTop: 0.5,
                        itemMarginBottom: 0,
                        itemStyle: {
                          // color: "#000000",
                          // fontWeight: "bold",
                          fontSize: 10.5,
                        },
                      }}
                      titleText={
                        setChartTitle(
                          query.selectedSite.year,
                          selectedSite?.name,
                          dir
                        ) + " Average Unauthorized Utilization by Hour"
                      }
                      subtitleText={
                        selectedSitesDuration !== "all"
                          ? "Parking " +
                            parkingDurationOptions.filter(
                              (obj) => obj.value === selectedSitesDuration
                            )[0].label
                          : ""
                      }
                      xAxisTitle={{
                        enabled: true,
                        text: "Hour (Hour 0 is 12 a.m.)",
                      }}
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
        </React.Fragment>
      )}

      {query.selectedComponents.utilizationByDay && (
        <React.Fragment>
          <Grid item xs={12} sm={12} md={12} lg={12} id="sites_util_day">
            <Divider
              component="div"
              role="presentation"
              textAlign="center"
              className="site-chart-chip"
            >
              {/* any elements nested inside the role="presentation" preserve their semantics. */}

              <Chip
                label={
                  <Typography
                    variant="h5"
                    // component="div"
                    // sx={{ padding: "5px", textAlign: "center" }}
                    sx={{ margin: 5, padding: 5 }}
                  >
                    Utilization by Day
                  </Typography>
                }
                sx={{ backgroundColor: "#a9a9a9" }}
              />
            </Divider>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <FormControl className={classesGrid.formLabel}>
              <FormLabel
                id="sites-duration-radio-buttons-group-label_day"
                className={classesGrid.formLabel}
              >
                Parking Duration
              </FormLabel>
              <RadioGroup
                aria-label="gender"
                name="duration-radio-buttons-group"
                value={selectedSitesDuration}
                onChange={handleChangeParkingDuration}
                className={classesGrid.formLabel}
                row
              >
                {parkingDurationOptions.map((option, index) => (
                  <FormControlLabel
                    // value="mapbox://styles/mapbox/streets-v11"
                    value={option.value}
                    control={<Radio size="small" />}
                    label={option.label}
                    key={"utilDay-sitesDurations-" + option.value}
                    // className={classesGrid.formLabel}
                    // disabled={false}
                    // disabled={regionSelected.year === 2018 ? true : false}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
          {selectedSite?.name !== "All" &&
            selectedSite.dir.map((dir) => {
              return (
                <React.Fragment key={dir}>
                  <Grid item xs={12} sm={12} md={12} lg={6}>
                    <StackedBarChart
                      id={selectedSite?.name + dir + "authorized"}
                      chartData={setChartDataOffUnoffByYearMonthDayData(
                        dir,
                        parkingDurationOptions.filter(
                          (obj) => obj.value === selectedSitesDuration
                        )[0].authorizedField
                      )}
                      plotOptionsColumnStacking={"normal"}
                      plotOptionsColumnDataLabelsEndabled={false}
                      chartHeight={"35%"}
                      chatWidth="50%"
                      themeHC={HighContrasLight}
                      yAxisTitleText={"# of Parking Events"}
                      legendSpecs={{
                        layout: "vertical",
                        align: "right",
                        verticalAlign: "middle",
                        itemMarginTop: 0.5,
                        itemMarginBottom: 0,
                        itemStyle: {
                          // color: "#000000",
                          // fontWeight: "bold",
                          fontSize: 10.5,
                        },
                      }}
                      titleText={
                        setChartTitle(
                          query.selectedSite.year,
                          selectedSite?.name,
                          dir
                        ) + " Average Authorized Utilization by Day"
                      }
                      subtitleText={
                        selectedSitesDuration !== "all"
                          ? "Parking " +
                            parkingDurationOptions.filter(
                              (obj) => obj.value === selectedSitesDuration
                            )[0].label
                          : " "
                      }
                      xAxisTitle={{
                        enabled: true,
                        text: "Day",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={6}>
                    <StackedBarChart
                      id={selectedSite?.name + dir + "unauthorized"}
                      chartData={setChartDataOffUnoffByYearMonthDayData(
                        dir,
                        parkingDurationOptions.filter(
                          (obj) => obj.value === selectedSitesDuration
                        )[0].unauthorizedField
                      )}
                      plotOptionsColumnStacking={"normal"}
                      plotOptionsColumnDataLabelsEndabled={false}
                      chartHeight={"35%"}
                      chatWidth="50%"
                      themeHC={HighContrasLight}
                      yAxisTitleText={"# of Parking Events"}
                      legendSpecs={{
                        layout: "vertical",
                        align: "right",
                        verticalAlign: "middle",
                        itemMarginTop: 0.5,
                        itemMarginBottom: 0,
                        itemStyle: {
                          // color: "#000000",
                          // fontWeight: "bold",
                          fontSize: 10.5,
                        },
                      }}
                      titleText={
                        setChartTitle(
                          query.selectedSite.year,
                          selectedSite?.name,
                          dir
                        ) + " Average Unauthorized Utilization by Day"
                      }
                      subtitleText={
                        selectedSitesDuration !== "all"
                          ? "Parking " +
                            parkingDurationOptions.filter(
                              (obj) => obj.value === selectedSitesDuration
                            )[0].label
                          : ""
                      }
                      xAxisTitle={{
                        enabled: true,
                        text: "Day",
                      }}
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
        </React.Fragment>
      )}
    </Grid>
  );
}

export default ChartsLayoutSites;
