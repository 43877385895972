import React, { useState, useContext } from "react";
import { QueryContext } from "../context/QueryContext";
import { makeStyles } from "@material-ui/core/styles";
// import Typography from "@material-ui/core/Typography";

import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";

import {
  yearsMonths,
  geographies,
  sitesYearsMonths,
  sitesYears,
  sitesMonths,
} from "../common/constants";

import CircleIcon from "@mui/icons-material/Circle";
import Typography from "@material-ui/core/Typography";
import SquareIcon from "@mui/icons-material/Square";
import CropSquareIcon from "@mui/icons-material/CropSquare";

import IconButton from "@mui/material/IconButton";

import InputLabel from "@mui/material/InputLabel";
// import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const useStyles = makeStyles((theme) => ({
  menuListContainer: {
    marginTop: "0px",
    paddingTop: "0px",
  },
  inactiveLayer: {
    color: "#C0C0C0",
  },
  activeLayer: {
    color: "inherit",
  },
}));

let optionsYearsMonths = [];
yearsMonths.map((obj) => {
  optionsYearsMonths.push(obj.yearMonth);
});

let optionsGeographies = [];
geographies.map((obj) => {
  optionsGeographies.push(obj.label);
});

export default function SiteMapLegend(props) {
  const { dataLayers } = props;
  const classes = useStyles();
  const [query, dispatch] = useContext(QueryContext);

  function handleChangeDataLayer(key) {
    let item = dataLayers.filter((obj) => obj.key === key)[0];
    item.setVisibility(!item.visibility);
  }
  function handleChangeParkingDurationCats(key) {
    let selectedDataCats = [...query.selectedSite.parkingDurationCategories];
    const index = selectedDataCats.indexOf(key);
    if (index === -1) {
      selectedDataCats = [...selectedDataCats, key];
    } else {
      selectedDataCats = selectedDataCats.filter((item) => item !== key);
    }
    dispatch({
      type: "setSelectedSite",
      selection: {
        ...query.selectedSite,
        parkingDurationCategories: selectedDataCats,
      },
    });
  }

  function setDataLayerClass(key) {
    let item = dataLayers.filter((obj) => obj.key === key)[0]; // console.log("item-check", item);
    if (item.visibility) return classes.activeLayer;
    else return classes.inactiveLayer;
  }

  function setDataLayerTooltip(key) {
    let item = dataLayers.filter((obj) => obj.key === key)[0];

    if (item.visibility) return "Click to hide layer.";
    else return "Click to show layer - " + item.label + ".";
  }

  function setParkingDurationCatsClass(key) {
    let selectedDataCats = query.selectedSite.parkingDurationCategories;
    const index = selectedDataCats.indexOf(key);

    if (index === -1) return classes.inactiveLayer;
    else return classes.activeLayer;
  }

  function setParkingDurationCatsTooltip(key) {
    let selectedDataCats = query.selectedSite.parkingDurationCategories;
    const index = selectedDataCats.indexOf(key);

    let descText = "";
    if (key === 1) descText = "Less than 1 hour";
    if (key === 2) descText = "1 to 3 hours";
    if (key === 3) descText = "3 to 7 hours";
    if (key === 4) descText = "7 to 11 hours";
    if (key === 5) descText = "Greater than 11 hours";

    if (index === -1)
      return "Click to show layer - Parking Duration " + descText + ".";
    else return "Click to hide layer.";
  }

  return (
    <Accordion disableGutters defaultExpanded>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
      >
        <Typography variant="h6">Interactive Legend</Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          marginLeft: 2,
          marginTop: 0,
          paddingTop: 0,
        }}
      >
        <Typography style={{ fontSize: "18px" }}>Parking Duration:</Typography>
        <Tooltip title={setParkingDurationCatsTooltip(1)}>
          <IconButton
            className="sites-legend-iconButton"
            aria-label="less than 1 hour "
            aria-controls="map-legend-toggle-layer-visibility"
            aria-haspopup="false"
            onClick={() => handleChangeParkingDurationCats(1)}
            color="inherit"
          >
            <CircleIcon
              className="sites-legend-icon"
              sx={{ color: "#7F3C8D" }}
            />
            <Typography
              style={{ fontSize: "18px" }}
              className={setParkingDurationCatsClass(1)}
            >
              Less than 1 hour
            </Typography>
          </IconButton>
        </Tooltip>
        <Tooltip title={setParkingDurationCatsTooltip(2)}>
          <IconButton
            className="sites-legend-iconButton"
            aria-label="1 to 3 hours"
            aria-controls="map-legend-toggle-layer-visibility"
            aria-haspopup="false"
            onClick={() => handleChangeParkingDurationCats(2)}
            color="inherit"
          >
            <CircleIcon
              className="sites-legend-icon"
              sx={{ color: "#11A579" }}
            />
            <Typography
              style={{ fontSize: "18px" }}
              className={setParkingDurationCatsClass(2)}
            >
              1 to 3 hours
            </Typography>
          </IconButton>
        </Tooltip>
        <Tooltip title={setParkingDurationCatsTooltip(3)}>
          <IconButton
            className="sites-legend-iconButton"
            aria-label="3 to 7 hours"
            aria-controls="map-legend-toggle-layer-visibility"
            aria-haspopup="false"
            onClick={() => handleChangeParkingDurationCats(3)}
            color="inherit"
          >
            <CircleIcon
              className="sites-legend-icon"
              sx={{ color: "#3969AC" }}
            />
            <Typography
              style={{ fontSize: "18px" }}
              className={setParkingDurationCatsClass(3)}
            >
              3 to 7 hours
            </Typography>
          </IconButton>
        </Tooltip>
        <Tooltip title={setParkingDurationCatsTooltip(4)}>
          <IconButton
            className="sites-legend-iconButton"
            aria-label="7 to 11 hours"
            aria-controls="map-legend-toggle-layer-visibility"
            aria-haspopup="false"
            onClick={() => handleChangeParkingDurationCats(4)}
            color="inherit"
          >
            <CircleIcon
              className="sites-legend-icon"
              sx={{ color: "#F2B701" }}
            />
            <Typography
              style={{ fontSize: "18px" }}
              className={setParkingDurationCatsClass(4)}
            >
              7 to 11 hours
            </Typography>
          </IconButton>
        </Tooltip>
        <Tooltip title={setParkingDurationCatsTooltip(5)}>
          <IconButton
            className="sites-legend-iconButton"
            aria-label="Greater than 11 hours"
            aria-controls="map-legend-toggle-layer-visibility"
            aria-haspopup="false"
            onClick={() => handleChangeParkingDurationCats(5)}
            color="inherit"
          >
            <CircleIcon
              className="sites-legend-icon"
              sx={{ color: "#E73F74" }}
            />
            <Typography
              style={{ fontSize: "18px" }}
              className={setParkingDurationCatsClass(5)}
            >
              Greater than 11 hours
            </Typography>
          </IconButton>
        </Tooltip>
        <Typography style={{ fontSize: "18px" }} variant="subtitle1">
          Parking Areas:
        </Typography>
        <Tooltip title={setDataLayerTooltip("authorized-parking-area")}>
          <IconButton
            className="sites-legend-iconButton"
            aria-label="Authorized Parking"
            aria-controls="map-legend-toggle-layer-visibility"
            aria-haspopup="false"
            onClick={() => handleChangeDataLayer("authorized-parking-area")}
            color="inherit"
          >
            <SquareIcon
              className="sites-legend-icon"
              sx={{ color: "#90ee90" }}
            />
            <Typography
              style={{ fontSize: "18px" }}
              className={setDataLayerClass("authorized-parking-area")}
            >
              Authorized Parking
            </Typography>
          </IconButton>
        </Tooltip>
        <Tooltip title={setDataLayerTooltip("unauthorized-parking-area")}>
          <IconButton
            className="sites-legend-iconButton"
            aria-label="Unauthorized Parking"
            aria-controls="map-legend-toggle-layer-visibility"
            aria-haspopup="false"
            onClick={() => handleChangeDataLayer("unauthorized-parking-area")}
            color="inherit"
          >
            <CropSquareIcon
              className="sites-legend-icon"
              sx={{
                color: "#ff0000",
                opacity: 0.5,
              }}
            />
            <Typography
              style={{ fontSize: "18px" }}
              className={setDataLayerClass("unauthorized-parking-area")}
            >
              Unauthorized Parking
            </Typography>
          </IconButton>
        </Tooltip>
      </AccordionDetails>
    </Accordion>
  );
}
