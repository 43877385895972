import React, { useContext } from "react";
import {
  dayTypeOptions,
  geoOptions,
  month2019Options,
  monthOptions,
  sitesMonth2019Options,
  sitesMonthOptions,
  sitesYears,
  stateMapGridResolution,
  vehicleWeightClassOptions,
  yearOptions,
  txdotDistricts,
  mpoList,
  corridors,
} from "../common/constants";

import BottomSettings from "./BottomSettings";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { QueryContext } from "../context/QueryContext";
import Slider from "@mui/material/Slider";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@mui/material/styles";

const OptionsBar = (props) => {
  const {
    statusGuide,
    showVehicleWeightClass,
    showDayType,
    showYear,
    showMonth,
    showGridResolution,
    showOpacity,
  } = props;
  /** using context to fetch selection */
  const [query, dispatch] = useContext(QueryContext);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <Paper className="optionsBar">
      <Toolbar className="optionsBar--toolbar">
        <Grid container className="optionsBar--gridContainer">
          {/* data options */}
          <Grid item className="optionsBar--gridItem">
            <Typography variant="subtitle1">
              {!isSmallScreen ? "Data Options: " : ""}
            </Typography>
          </Grid>
          <Grid
            item
            className="optionsBar--gridItem"
            sx={{ display: !showVehicleWeightClass ? "none" : "block" }}
          >
            <BottomSettings
              initialValue={query.selectedVehicleWeightClass}
              name="Vehicle Weight Class"
              options={vehicleWeightClassOptions}
              type="setSelectedVehicleWeightClass"
            />
          </Grid>
          <Grid
            item
            className="optionsBar--gridItem"
            sx={{ display: !showDayType ? "none" : "block" }}
          >
            <BottomSettings
              initialValue={query.selectedDayType}
              name="Day Type"
              options={dayTypeOptions}
              type="setSelectedDayType"
            />
          </Grid>
          {statusGuide === "ParkingSites" ? (
            <>
              <Grid
                item
                className="optionsBar--gridItem"
                sx={{ display: !showYear ? "none" : "block" }}
              >
                <BottomSettings
                  initialValue={query.selectedSite && query.selectedSite.year}
                  name="Year"
                  options={sitesYears}
                  type="setSelectedSite"
                />
              </Grid>
              <Grid
                item
                className="optionsBar--gridItem"
                sx={{ display: !showMonth ? "none" : "block" }}
              >
                <BottomSettings
                  initialValue={query.selectedSite && query.selectedSite.month}
                  name="Month"
                  options={
                    query.selectedSite.year === 2019
                      ? sitesMonth2019Options
                      : sitesMonthOptions
                  }
                  type="setSelectedSite"
                />
              </Grid>
            </>
          ) : (
            <>
              <Grid
                item
                className="optionsBar--gridItem"
                sx={{ display: !showYear ? "none" : "block" }}
              >
                <BottomSettings
                  initialValue={query.statewide && query.statewide.year}
                  name="Year"
                  options={yearOptions}
                  type="statewide"
                />
              </Grid>
              <Grid
                item
                className="optionsBar--gridItem"
                sx={{ display: !showMonth ? "none" : "block" }}
              >
                <BottomSettings
                  initialValue={query.statewide && query.statewide.month}
                  name="Month"
                  options={
                    query.statewide.year === 2019
                      ? month2019Options
                      : monthOptions
                  }
                  type="statewide"
                />
              </Grid>
            </>
          )}
          {/* map options */}
          <Grid item sx={{ display: isSmallScreen ? "none" : "block" }}>
            <Typography variant="subtitle1">Map Options: </Typography>
          </Grid>
          <Grid
            item
            className="optionsBar--gridItem"
            sx={{ display: statusGuide !== "State" ? "none" : "block" }}
          >
            <BottomSettings
              initialValue={query.geoFilterMode}
              name="Geography"
              options={geoOptions}
              type="setGeoFilterMode"
            />
          </Grid>
          <Grid
            item
            className="optionsBar--gridItem"
            sx={{
              display:
                !["district", "mpo", "corridor"].includes(
                  query.geoFilterMode
                ) || statusGuide !== "State"
                  ? "none"
                  : "block",
            }}
          >
            <BottomSettings
              key={`${query.geoFilterMode}-options`}
              initialValue={""}
              name={
                query.geoFilterMode === "district"
                  ? "District"
                  : query.geoFilterMode === "mpo"
                  ? "MPO"
                  : query.geoFilterMode === "corridor"
                  ? "Corridor"
                  : ""
              }
              options={
                query.geoFilterMode === "district"
                  ? txdotDistricts
                  : query.geoFilterMode === "mpo"
                  ? mpoList
                  : query.geoFilterMode === "corridor"
                  ? corridors
                  : []
              }
              type="setGeoFilterOption"
            />
          </Grid>
          <Grid
            item
            className="optionsBar--gridItem"
            sx={{ display: !showGridResolution ? "none" : "block" }}
          >
            <BottomSettings
              initialValue={query.stateMapGridResolution}
              name="Grid Resolution"
              options={stateMapGridResolution}
              type="setStateMapGridResolution"
            />
          </Grid>
          <Grid
            item
            className="optionsBar--gridItem"
            sx={{ display: !showOpacity ? "none" : "block" }}
          >
            <Typography
              sx={{
                fontSize: 13,
                ml: 2.5,
                textTransform: "uppercase",
                fontWeight: "medium",
              }}
            >
              Opacity
            </Typography>
            <Box
              sx={{
                fontSize: 12,
                width: 100,
                p: 0,
                m: 0,
                ml: 2.5,
                mr: 2.5,
              }}
            >
              <Slider
                aria-label="Map Opacity"
                defaultValue={0.8}
                value={query.mapOpacity}
                valueLabelDisplay="auto"
                step={0.1}
                marks
                min={0}
                max={1}
                sx={{
                  fontSize: 12,
                }}
                onChangeCommitted={(_, v) =>
                  dispatch({
                    type: "setMapOpacity",
                    selection: v,
                  })
                }
              />
            </Box>
          </Grid>
        </Grid>
      </Toolbar>
    </Paper>
  );
};

export default OptionsBar;
