/**
 * Handles the creation of a select component with options.
 * @param {int|string} initialValue - All values are expected to be integers, except for DayType and geoFilterMode, and geoFilterOption, which are stored as strings in context.
 * @param {string} name - The label for the TextField component.
 * @param {object[]} options - An array of objects representing the select options. Each object should contain `label` and `value` fields.
 * @param {string} type - The name of the dispatch event to trigger.
 */

import React, { useContext, useState } from "react";
import { sitesYearsMonths, yearsMonths } from "../common/constants";

import MenuItem from "@mui/material/MenuItem";
import { QueryContext } from "../context/QueryContext";
import TextField from "@mui/material/TextField";

export default function BottomSettings({ initialValue, name, options, type }) {
  const [query, dispatch] = useContext(QueryContext);
  const [op, setOp] = useState(initialValue);
  //used to set aria-expanded for now
  const [isOpen, setIsOpen] = useState(false);

  const handleChange = (event) => {
    const { value, name } = event.target;
    // Set selected options to display in the Select component
    setOp(value);

    // Check if we need to pass an object instead of just a value in dispatch
    if (type === "setSelectedSite" || type === "statewide") {
      let tempData =
        type === "statewide" ? query.statewide : query.selectedSite; //Get previous selections from context

      //Right now we maintain two different objects for state and parkingSites
      let ym = type === "statewide" ? yearsMonths : sitesYearsMonths;

      // If a year is selected, check the month from stored values and vice versa
      let filterBy = name === "year" ? "month" : "year";
      let yearMonthTemp = ym.find(
        (obj) => obj[name] === value && obj[filterBy] === tempData[filterBy]
      ).yearMonth;

      // Update the temporary object with the passed-in changes and the calculated yearMonth key
      tempData[name] = value;
      tempData.yearMonth = yearMonthTemp;
      dispatch({
        type: type,
        selection: tempData,
      });
    } else {
      dispatch({
        type: type,
        selection: value,
      });
    }
    if (type === "setGeoFilterMode") {
      dispatch({
        type: "setGeoFilterOption",
        selection: "",
      });
    }
  };

  /** Iterate through the passed-in options to display in the Select component */
  const selectItems = options.map((option) => {
    return (
      <MenuItem
        key={option.value}
        value={name === "Day Type" ? option.label : option.value} //Set the value as the label for DayType
      >
        {option.label}
      </MenuItem>
    );
  });

  return (
    <TextField
      className="optionsBar--select"
      select
      fullWidth
      id={`select-${name}`}
      label={name}
      name={name.toLowerCase()}
      size="small"
      value={op}
      onClick={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      onChange={handleChange}
      aria-label={`${name.toLowerCase()} selection`}
      aria-controls="listbox"
      aria-haspopup={true}
      aria-expanded={isOpen}
      sx={{
        minWidth: 100, // Minimum width to prevent shrinking too much
        maxWidth: 160, // Maximum width to prevent growing too large
      }}
    >
      {selectItems}
    </TextField>
  );
}
