import "../App.scss";

import { QueryContext } from "../context/QueryContext";
import React, { useContext } from "react";
import { HashLink as Link } from "react-router-hash-link";
import { useLocation } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Checkbox,
  Divider,
  ExpandMoreIcon,
  Typography,
} from "../components/mui";

export default function SitesDashboardOverview(props) {
  const { selectedSite } = props;
  const [query, dispatch] = useContext(QueryContext);
  let location = useLocation();

  const handleToggle = (toggle) => {
    let tempSelection = query.selectedComponents;

    if (toggle === "parkingEvents")
      dispatch({
        type: "setToggle",
        selection: {
          ...tempSelection,
          parkingEvents: !tempSelection.parkingEvents,
        },
      });

    if (toggle === "hour")
      dispatch({
        type: "setToggle",
        selection: {
          ...tempSelection,
          utilizationByHour: !tempSelection.utilizationByHour,
        },
      });
    if (toggle === "day")
      dispatch({
        type: "setToggle",
        selection: {
          ...tempSelection,
          utilizationByDay: !tempSelection.utilizationByDay,
        },
      });

    return;
  };

  return (
    <>
      {selectedSite === "All" && (
        <Alert severity="info">
          Select a site from the dropdown or by clicking the parking icon on the
          map to view detailed summary.
        </Alert>
      )}
      <Divider
        component="div"
        role="presentation"
        textAlign="center"
        className="sitesLayout-topDivider"
      >
        <Typography variant="h4">
          {selectedSite !== "All" ? selectedSite : null} Overview
        </Typography>
      </Divider>

      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
          sx={{ height: "50px" }}
        >
          <Typography>
            <Checkbox
              disabled={selectedSite === "All"}
              checked={query.selectedComponents.parkingEvents}
              onChange={() => handleToggle("parkingEvents")}
            />
            <Link to={`${location.pathname}#sites_park_summary`} smooth>
              Parking Events Summary
            </Link>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          Table 1 – Unofficial Parking Rate Percent: This table provides a
          summary of the parking events for the selected year and month by
          parking duration. This includes the unofficial parking rate, which is
          an indicator of how much parking is occurring outside of the official
          parking spaces.
          <br></br>
          Table 2 – Average Daily Parking Usage: This table provides a summary
          of the average daily parking usage, including an expansion factor.
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
          sx={{ height: "50px" }}
        >
          <Typography>
            <Checkbox
              disabled={selectedSite === "All"}
              checked={query.selectedComponents.utilizationByHour}
              onClick={() => handleToggle("hour")}
            />
            <Link to={`${location.pathname}#sites_util_hour`} smooth>
              Utilization by Hour
            </Link>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          These figures provide a summary of the parking events by hour for each
          month included in the data, one for those parking events in authorized
          parking locations and one for those parking events in unauthorized
          parking locations by parking duration (selection above the figures).
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
          sx={{ height: "50px" }}
        >
          <Typography>
            <Checkbox
              disabled={selectedSite === "All"}
              checked={query.selectedComponents.utilizationByDay}
              onClick={() => handleToggle("day")}
            />
            <Link to={`${location.pathname}#sites_util_day`} smooth>
              Utilization by Day
            </Link>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          These figures provide a summary of the parking events by hour for each
          month included in the data, one for those parking events in authorized
          parking locations and one for those parking events in unauthorized
          parking locations by parking duration (selection above the figures).
        </AccordionDetails>
      </Accordion>
    </>
  );
}
