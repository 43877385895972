export function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

export function setChartTitle(year, site, dir) {
  if (dir === "N") return year + " " + site + " - Northbound";
  if (dir === "S") return year + " " + site + " - Southbound";
  if (dir === "E") return year + " " + site + " - Eastbound";
  if (dir === "W") return year + " " + site + " - Westbound";
  if (dir === "B") return year + " " + site;

  return year + " " + site;
} 
