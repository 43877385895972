import React, { useState, useRef, useEffect, useMemo, useContext } from "react";
import axios from "axios";

import {
  vehicleWeightClassOptions,
  yearsMonths,
  dayTypeOptions,
} from "../common/constants";

import { Grid } from "../components/mui";

import StackedBarChart from "./HighCharts/StackedBarChart";
import Donut3dChart from "./HighCharts/Donut3dChart";
import TimeSeriesWithLineChart from "./HighCharts/TimeSeriesWithLineChart";
import Column3dPlot from "./HighCharts/Column3dPlot";
import HighContrasLight from "highcharts/themes/high-contrast-light";
import BoxPlot from "./HighCharts/BoxPlot";

import { QueryContext } from "../context/QueryContext";

import { gridStyles } from "../ui/styles";

import {
  fetchEventCountsByMonthHour,
  fetchParkingEventsHourlyStats,
} from "../data-loaders/DataLoaders";

function ChartsLayoutH3(props) {
  const { chartHeight, visibility } = props;
  const [query, dispatch] = useContext(QueryContext);

  const [selectedVehicleWeightClass, setSelectedVehicleWeightClass] =
    useState(0);
  const [selectedDayType, setSelectedDayType] = useState("All Daytypes");

  const [regionParkingCatSummary, setRegionParkingCatSummary] = useState(null);
  const [regionYearParkingCatSummary, setRegionYearParkingCatSummary] =
    useState(null);
  const [
    regionYearParkingCatSummaryChart,
    setRegionYearParkingCatSummaryChart,
  ] = useState(null);
  const [
    regionYearDOWParkingCatSummaryChart,
    setRegionYearDOWParkingCatSummaryChart,
  ] = useState(null);
  const [
    duration1HrBinsByRegionYearMonth,
    setDuration1HrBinsByRegionYearMonth,
  ] = useState(null);
  const [durationBinsAveMax, setDurationBinsAveMax] = useState({
    averageBin: 0,
    maxEvents: 0,
  });

  useMemo(() => {
    if (query.geoFilterMode === "statewide" || query.geoFilterOption !== "") {
      const fetchRegionParkingCatSummary = async (
        region,
        year,
        month,
        vehweightclass,
        number
      ) => {
        try {
          let dataUrl = "";
          if (selectedDayType === "All Daytypes") {
            dataUrl =
              `${process.env.REACT_APP_API_ROOT_NEW}` +
              `/fetchParkingCatSummaryByRegionYearMonth?region=${region}&year=${year}&month=${month}&number=${number}`;
            if (vehweightclass !== 0)
              dataUrl =
                `${process.env.REACT_APP_API_ROOT_NEW}` +
                `/fetchParkingCatSummaryByRegionYearMonthVehWeightClass?region=${region}&year=${year}&month=${month}&vehweightclass=${vehweightclass}&number=${number}`;
          } else {
            dataUrl =
              `${process.env.REACT_APP_API_ROOT_NEW}` +
              `/fetchParkingCatSummaryByRegionYearDayType?region=${region}&year=${year}&dow=${selectedDayType}&number=${number}`;
            if (vehweightclass !== 0)
              dataUrl =
                `${process.env.REACT_APP_API_ROOT_NEW}` +
                `/fetchParkingCatSummaryByRegionYearDayTypeVehWeightClass?region=${region}&year=${year}&vehweightclass=${vehweightclass}&dow=${selectedDayType}&number=${number}`;
          }
          const response = await axios.get(dataUrl);
          if (response && response?.data.length > 0) {
            setRegionParkingCatSummary(setYearMonthSummaryData(response.data));
          } else {
            setRegionParkingCatSummary(null);
          }
        } catch (e) {
          console.log(e);
        }
      };
      fetchRegionParkingCatSummary(
        query.geoFilterMode,
        query.statewide.year,
        query.statewide.month,
        selectedVehicleWeightClass,
        query.geoFilterOption
      );

      const fetchRegionYearParkingCatSummary = async (
        region,
        year,
        month,
        vehweightclass,
        number
      ) => {
        try {
          let dataUrl = "";
          if (selectedDayType === "All Daytypes") {
            dataUrl =
              `${process.env.REACT_APP_API_ROOT_NEW}` +
              `/fetchParkingCatSummaryByRegionYear?region=${region}&year=${year}&number=${number}`;
            if (vehweightclass !== 0)
              dataUrl =
                `${process.env.REACT_APP_API_ROOT_NEW}` +
                `/fetchParkingCatSummaryByRegionYearVehWeightClass?region=${region}&year=${year}&vehweightclass=${vehweightclass}&number=${number}`;
          } else {
            dataUrl =
              `${process.env.REACT_APP_API_ROOT_NEW}` +
              `/fetchParkingCatSummaryByRegionYearDayType?region=${region}&year=${year}&dow=${selectedDayType}&number=${number}`;
            if (vehweightclass !== 0)
              dataUrl =
                `${process.env.REACT_APP_API_ROOT_NEW}` +
                `/fetchParkingCatSummaryByRegionYearDayTypeVehWeightClass?region=${region}&year=${year}&vehweightclass=${vehweightclass}&dow=${selectedDayType}&number=${number}`;
          }

          const response = await axios.get(dataUrl);
          if (response?.data && response.data.length > 0) {
            let tableData = response.data;
            setRegionYearParkingCatSummary(tableData);
            setRegionYearParkingCatSummaryChart(
              setYearSummaryData2(tableData, query.statewide.year)
            );
            let totals = {
              region: tableData[0].region,
              start_year: tableData[0].start_year,
              start_month: "Total",
              parking_cat_1: 0,
              parking_cat_1_pc: 0,
              parking_cat_2: 0,
              parking_cat_2_pc: 0,
              parking_cat_3: 0,
              parking_cat_3_pc: 0,
              parking_cat_4: 0,
              parking_cat_4_pc: 0,
              parking_cat_5: 0,
              parking_cat_5_pc: 0,
              parking_cat_total: 0,
              parking_cat_total_pc: 0,
            };
            tableData.map((obj) => {
              obj.parking_cat_total =
                obj.parking_cat_1 +
                obj.parking_cat_2 +
                obj.parking_cat_3 +
                obj.parking_cat_4 +
                obj.parking_cat_5;
              obj.parking_cat_total_pc =
                (
                  obj.parking_cat_1_pc +
                  obj.parking_cat_2_pc +
                  obj.parking_cat_3_pc +
                  obj.parking_cat_4_pc +
                  obj.parking_cat_5_pc
                ).toFixed(2) * 1;
              obj.parking_cat_1_pc = obj.parking_cat_1_pc.toFixed(2) * 1;
              obj.parking_cat_2_pc = obj.parking_cat_2_pc.toFixed(2) * 1;
              obj.parking_cat_3_pc = obj.parking_cat_3_pc.toFixed(2) * 1;
              obj.parking_cat_4_pc = obj.parking_cat_4_pc.toFixed(2) * 1;
              obj.parking_cat_5_pc = obj.parking_cat_5_pc.toFixed(2) * 1;

              totals.parking_cat_1 = totals.parking_cat_1 + obj.parking_cat_1;
              totals.parking_cat_2 = totals.parking_cat_2 + obj.parking_cat_2;
              totals.parking_cat_3 = totals.parking_cat_3 + obj.parking_cat_3;
              totals.parking_cat_4 = totals.parking_cat_4 + obj.parking_cat_4;
              totals.parking_cat_5 = totals.parking_cat_5 + obj.parking_cat_5;
              totals.parking_cat_total =
                totals.parking_cat_total +
                obj.parking_cat_1 +
                obj.parking_cat_2 +
                obj.parking_cat_3 +
                obj.parking_cat_4 +
                obj.parking_cat_5;
              return obj;
            });
            totals.parking_cat_1_pc =
              (totals.parking_cat_1 / totals.parking_cat_total) * 100;
            totals.parking_cat_2_pc =
              (totals.parking_cat_2 / totals.parking_cat_total) * 100;
            totals.parking_cat_3_pc =
              (totals.parking_cat_3 / totals.parking_cat_total) * 100;
            totals.parking_cat_4_pc =
              (totals.parking_cat_4 / totals.parking_cat_total) * 100;
            totals.parking_cat_5_pc =
              (totals.parking_cat_5 / totals.parking_cat_total) * 100;
            totals.parking_cat_total_pc =
              (
                totals.parking_cat_1_pc +
                totals.parking_cat_2_pc +
                totals.parking_cat_3_pc +
                totals.parking_cat_4_pc +
                totals.parking_cat_5_pc
              ).toFixed(2) * 1;
            totals.parking_cat_1_pc = totals.parking_cat_1_pc.toFixed(2) * 1;
            totals.parking_cat_2_pc = totals.parking_cat_2_pc.toFixed(2) * 1;
            totals.parking_cat_3_pc = totals.parking_cat_3_pc.toFixed(2) * 1;
            totals.parking_cat_4_pc = totals.parking_cat_4_pc.toFixed(2) * 1;
            totals.parking_cat_5_pc = totals.parking_cat_5_pc.toFixed(2) * 1;
            tableData.push(totals);

            dispatch({
              type: "setRegionCategoryTableData",
              selection: tableData,
            });
          } else {
            setRegionYearParkingCatSummary(null);
            setRegionYearParkingCatSummaryChart(null);
          }
        } catch (e) {
          console.log(e);
        }
      };
      fetchRegionYearParkingCatSummary(
        query.geoFilterMode,
        query.statewide.year,
        query.statewide.month,
        selectedVehicleWeightClass,
        query.geoFilterOption
      );

      const fetchRegionYearDOWParkingCatSummary = async (
        region,
        year,
        month,
        vehweightclass,
        number
      ) => {
        try {
          let dataUrl =
            `${process.env.REACT_APP_API_ROOT_NEW}` +
            `/fetchParkingCatSummaryByRegionYearDayType?region=${region}&year=${year}&dow=all&number=${number}`;
          if (vehweightclass !== 0)
            dataUrl =
              `${process.env.REACT_APP_API_ROOT_NEW}` +
              `/fetchParkingCatSummaryByRegionYearDayTypeVehWeightClass?region=${region}&year=${year}&vehweightclass=${vehweightclass}&dow=all&number=${number}`;
          const response = await axios.get(dataUrl);
          if (response?.data && response.data.length > 0) {
            setRegionYearDOWParkingCatSummaryChart(
              setYearDOWSummaryData(response.data)
            );
          } else {
            setRegionYearDOWParkingCatSummaryChart(null);
          }
        } catch (e) {
          console.log(e);
        }
      };
      fetchRegionYearDOWParkingCatSummary(
        query.geoFilterMode,
        query.statewide.year,
        query.statewide.month,
        selectedVehicleWeightClass,
        query.geoFilterOption
      );

      const fetch1HrDurationBins = async (region, year, month, number) => {
        try {
          let dataUrl =
            `${process.env.REACT_APP_API_ROOT_NEW}` +
            `/fetchHalfHrDurationBinsByRegionYearMonth?region=${region}&year=${year}&month=${month}&vehweightclass=${selectedVehicleWeightClass}&number=${number}`;
          if (selectedDayType === "All Daytypes")
            dataUrl = dataUrl + `&dow=All`;
          else dataUrl = dataUrl + `&dow=${selectedDayType}`;
          const response = await axios.get(dataUrl);
          if (response?.data && response.data.length > 0) {
            let fJson = [];
            let eventsTotal = 0;
            let hrEventsTotal = 0;
            let eventsMax = 0;
            response.data.map((obj) => {
              fJson.push([obj.duration_bin, obj.events]);
              eventsTotal = eventsTotal + obj.events;
              hrEventsTotal = hrEventsTotal + obj.duration_bin * obj.events;
              if (obj.events > eventsMax) eventsMax = obj.events;
            });

            setDuration1HrBinsByRegionYearMonth(fJson);
            setDurationBinsAveMax({
              averageBin: hrEventsTotal / eventsTotal,
              maxEvents: eventsMax,
            });
          } else {
            setDuration1HrBinsByRegionYearMonth(null);
          }
        } catch (e) {
          console.log(e);
        }
      };
      fetch1HrDurationBins(
        query.geoFilterMode,
        query.statewide.year,
        query.statewide.month,
        query.geoFilterOption
      );
    }
  }, [
    query.geoFilterMode,
    query.statewide.year,
    query.statewide.month,
    selectedVehicleWeightClass,
    selectedDayType,
    query.geoFilterOption,
  ]);

  function setYearMonthSummaryData(tempData) {
    let tempChartData = [];
    if (tempData !== null) {
      tempChartData.push({
        name: query.regionLookup,
        data: [
          ["Less than 1 hour", tempData[0].parking_cat_1_pc],
          [
            "Greater than 1 hour, Less than 3 hours",
            tempData[0].parking_cat_2_pc,
          ],
          ["Greater than 3, Less than 7 hours", tempData[0].parking_cat_3_pc],
          ["Greater than 7, Less than 11 hours", tempData[0].parking_cat_4_pc],
          ["Greater than 11 hours", tempData[0].parking_cat_5_pc],
        ],
      });
    }
    return tempChartData;
  }

  function setYearSummaryData2(tempData) {
    let tempChartData = [];
    if (tempData !== null) {
      let dataTempCat1 = [];
      let dataTempCat2 = [];
      let dataTempCat3 = [];
      let dataTempCat4 = [];
      let dataTempCat5 = [];
      let years = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
      years.map((mobj) => {
        if (tempData.filter((f) => f.start_month === mobj).length > 0) {
          let tempDataMonth = tempData.filter((f) => f.start_month === mobj)[0];

          dataTempCat1.push(
            Math.round(
              (tempDataMonth.parking_cat_1_pc + Number.EPSILON) * 100
            ) / 100
          );
          dataTempCat2.push(
            Math.round(
              (tempDataMonth.parking_cat_2_pc + Number.EPSILON) * 100
            ) / 100
          );
          dataTempCat3.push(
            Math.round(
              (tempDataMonth.parking_cat_3_pc + Number.EPSILON) * 100
            ) / 100
          );
          dataTempCat4.push(
            Math.round(
              (tempDataMonth.parking_cat_4_pc + Number.EPSILON) * 100
            ) / 100
          );
          dataTempCat5.push(
            Math.round(
              (tempDataMonth.parking_cat_5_pc + Number.EPSILON) * 100
            ) / 100
          );
        }
      });
      tempChartData.push({
        name: "Greater than 11 hours",
        data: dataTempCat5,
        index: 0,
        legendIndex: 4,
      });
      tempChartData.push({
        name: "Greater than 7, Less than 11 hours",
        data: dataTempCat4,
        index: 1,
        legendIndex: 3,
      });
      tempChartData.push({
        name: "Greater than 3, Less than 7 hours",
        data: dataTempCat3,
        index: 2,
        legendIndex: 2,
      });
      tempChartData.push({
        name: "Greater than 1, Less than 3 hours",
        data: dataTempCat2,
        index: 3,
        legendIndex: 1,
      });
      tempChartData.push({
        name: "Less than 1 hour",
        data: dataTempCat1,
        index: 4,
        legendIndex: 0,
      });
    }
    return tempChartData;
  }

  function setYearDOWSummaryData(tempData) {
    let tempChartData = [];
    if (tempData !== null) {
      let dataTempCat1 = [];
      let dataTempCat2 = [];
      let dataTempCat3 = [];
      let dataTempCat4 = [];
      let dataTempCat5 = [];
      let tempDataMonth = tempData.filter(
        (f) => f.start_month === query.statewide.month
      );
      [1, 2, 3, 4, 5, 6, 7].forEach((idx) => {
        let tempDataMonthWeek = tempDataMonth.filter(
          (f) => f.dow_index === idx
        )[0];
        dataTempCat1.push(
          Math.round(
            (tempDataMonthWeek.parking_cat_1_pc + Number.EPSILON) * 100
          ) / 100
        );
        dataTempCat2.push(
          Math.round(
            (tempDataMonthWeek.parking_cat_2_pc + Number.EPSILON) * 100
          ) / 100
        );
        dataTempCat3.push(
          Math.round(
            (tempDataMonthWeek.parking_cat_3_pc + Number.EPSILON) * 100
          ) / 100
        );
        dataTempCat4.push(
          Math.round(
            (tempDataMonthWeek.parking_cat_4_pc + Number.EPSILON) * 100
          ) / 100
        );
        dataTempCat5.push(
          Math.round(
            (tempDataMonthWeek.parking_cat_5_pc + Number.EPSILON) * 100
          ) / 100
        );
      });

      tempChartData.push({
        name: "Greater than 11 hours",
        data: dataTempCat5,
        index: 0,
        legendIndex: 4,
      });
      tempChartData.push({
        name: "Greater than 7, Less than 11 hours",
        data: dataTempCat4,
        index: 1,
        legendIndex: 3,
      });
      tempChartData.push({
        name: "Greater than 3, Less than 7 hours",
        data: dataTempCat3,
        index: 2,
        legendIndex: 2,
      });
      tempChartData.push({
        name: "Greater than 1, Less than 3 hours",
        data: dataTempCat2,
        index: 3,
        legendIndex: 1,
      });
      tempChartData.push({
        name: "Less than 1 hour",
        data: dataTempCat1,
        index: 4,
        legendIndex: 0,
      });
    }
    return tempChartData;
  }

  useMemo(() => {
    setSelectedVehicleWeightClass(query.selectedVehicleWeightClass);
  }, [query.selectedVehicleWeightClass]);

  useMemo(() => {
    setSelectedDayType(query.selectedDayType);
  }, [query.selectedDayType]);

  const [hourEventCounts, setHourEventCounts] = React.useState(null);
  const [allBoxPlotData, setAllBoxPlotData] = React.useState(null);

  useMemo(() => {
    if (query.geoFilterMode === "statewide" || query.geoFilterOption !== "") {
      let vehWeightClasses = [2, 3];
      if (query.selectedVehicleWeightClass != 0)
        vehWeightClasses = query.selectedVehicleWeightClass;
      let dayTypes = [1, 2, 3, 4, 5, 6, 7];
      if (query.selectedDayType != "All Daytypes")
        dayTypes = dayTypeOptions.filter(
          (itm) => itm.label === query.selectedDayType
        )[0].value;

      fetchEventCountsByMonthHour(
        query.geoFilterMode,
        query.statewide.year,
        query.statewide.month,
        dayTypes,
        vehWeightClasses,
        query.geoFilterOption
      ).then((res) => {
        if (res?.data && res.data.length > 0) {
          let hourEventCountsTemp = [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0,
          ];

          res.data.map((itm) => {
            hourEventCountsTemp[itm.start_hour] = itm.count;
          });
          setHourEventCounts(hourEventCountsTemp);
        } else {
          setHourEventCounts(null);
        }
      });

      let dayTypeQuery = "all";
      if (selectedDayType !== "All Daytypes")
        dayTypeQuery = dayTypeOptions.filter(
          (itm) => itm.label === selectedDayType
        )[0].value;
      fetchParkingEventsHourlyStats(
        query.geoFilterMode,
        query.statewide.year,
        query.statewide.month,
        dayTypeQuery,
        selectedVehicleWeightClass,
        query.geoFilterOption
      ).then((res) => {
        res.data.length > 0
          ? setAllBoxPlotData(res.data)
          : setAllBoxPlotData(null);
      });
    }
  }, [
    query.geoFilterMode,
    selectedDayType,
    selectedVehicleWeightClass,
    query.statewide.year,
    query.statewide.month,
    query.geoFilterOption,
  ]);

  const monthsLookup = [
    { value: 1, description: "January" },
    { value: 2, description: "February" },
    { value: 3, description: "March" },
    { value: 4, description: "April" },
    { value: 5, description: "May" },
    { value: 6, description: "June" },
    { value: 7, description: "July" },
    { value: 8, description: "August" },
    { value: 9, description: "September" },
    { value: 10, description: "October" },
    { value: 11, description: "November" },
    { value: 12, description: "December" },
  ];

  const sitesYears = [...new Set(yearsMonths.map((item) => item.year))];

  return (
    <Grid container spacing={1} className="statewide-chart-grid-container">
      {visibility.show2 && regionParkingCatSummary && (
        <Grid item xs={12} sm={12} md={6} lg={6} id="donut">
          <Donut3dChart
            chartHeight={chartHeight}
            titleText={
              query.regionLookup +
              " Truck Parking Events By Duration" +
              "<br/>" +
              (selectedDayType !== "All Daytypes"
                ? selectedDayType + ", "
                : "") +
              monthsLookup.filter(
                (obj) => obj.value === query.statewide.month
              )[0].description +
              "-" +
              query.statewide.year +
              ", " +
              vehicleWeightClassOptions.filter(
                (obj) => obj.value === selectedVehicleWeightClass
              )[0].label
            }
            pieInnerSize={100}
            pieDepth={60}
            chartData={regionParkingCatSummary}
          />
        </Grid>
      )}

      {visibility.show3 && regionYearDOWParkingCatSummaryChart && (
        <Grid item xs={12} sm={12} md={6} lg={6} id="stackedbarbydow">
          <StackedBarChart
            themeHC={HighContrasLight}
            chartHeight={chartHeight}
            chartData={regionYearDOWParkingCatSummaryChart}
            titleText={
              query.regionLookup +
              " Truck Parking Events By Day-of-Week" +
              "<br/>" +
              monthsLookup.filter(
                (obj) => obj.value === query.statewide.month
              )[0].description +
              "-" +
              query.statewide.year +
              ", " +
              vehicleWeightClassOptions.filter(
                (obj) => obj.value === selectedVehicleWeightClass
              )[0].label
            }
            xAxisCategories={[
              "Sunday",
              "Monday",
              "Tuesday",
              "Wednesday",
              "Thursday",
              "Friday",
              "Saturday",
            ]}
            plotOptionsColumnStacking={"normal"}
            plotOptionsColumnDataLabelsEndabled={true}
            yAxisMax={100}
            yAxisTitleText={"% of Truck Parking Events"}
            legendLayout={"horizontal"}
            legendFontSize={11}
            legendSpecs={{
              layout: "horizontal",
              itemStyle: {
                fontSize: 11,
              },
            }}
          />
        </Grid>
      )}

      {visibility.show4 && regionYearParkingCatSummaryChart && (
        <Grid item xs={12} sm={12} md={6} lg={6} id="stackedbar">
          <StackedBarChart
            themeHC={HighContrasLight}
            chartHeight={chartHeight}
            chartData={regionYearParkingCatSummaryChart}
            titleText={
              query.statewide.year +
              " " +
              query.regionLookup +
              " Truck Parking Events By Month" +
              "<br/>" +
              (selectedDayType !== "All Daytypes"
                ? selectedDayType + ", "
                : "") +
              vehicleWeightClassOptions.filter(
                (obj) => obj.value === selectedVehicleWeightClass
              )[0].label
            }
            xAxisCategories={monthsLookup.map((mobj) => {
              return mobj.description;
            })}
            plotOptionsColumnStacking={"normal"}
            plotOptionsColumnDataLabelsEndabled={true}
            yAxisMax={100}
            yAxisTitleText={"% of Truck Parking Events"}
            legendSpecs={{
              layout: "horizontal",
              itemStyle: {
                fontSize: 11,
              },
            }}
          />
        </Grid>
      )}

      {visibility.show5 && duration1HrBinsByRegionYearMonth && (
        <Grid item xs={12} sm={12} md={6} lg={6} id="timeseries">
          <TimeSeriesWithLineChart
            themeHC={HighContrasLight}
            chartHeight={chartHeight}
            titleText={
              monthsLookup.filter(
                (obj) => obj.value === query.statewide.month
              )[0].description +
              "-" +
              query.statewide.year +
              " " +
              query.regionLookup +
              " Truck Parking Events By Duration Bin" +
              "<br/>" +
              (selectedDayType !== "All Daytypes"
                ? selectedDayType + ", "
                : "") +
              vehicleWeightClassOptions.filter(
                (obj) => obj.value === selectedVehicleWeightClass
              )[0].label
            }
            yAxisTitleText={"Number of Parking Events"}
            xAxisType={"time"}
            xAxisTitleText={"Parking Duration (Minutes)"}
            seriesType={"area"}
            seriesName={"Number of Parking Events"}
            seriesData={duration1HrBinsByRegionYearMonth}
            lineName={"Average Duration Bin"}
            lineData={[
              [durationBinsAveMax.averageBin, 0],
              [durationBinsAveMax.averageBin, durationBinsAveMax.maxEvents],
            ]}
          />
        </Grid>
      )}

      {visibility.show6 && allBoxPlotData && (
        <Grid item xs={12} sm={12} md={6} lg={6} id="boxplot">
          <BoxPlot
            themeHC={HighContrasLight}
            chartHeight={chartHeight}
            titleText={
              query.regionLookup +
              " Truck Parking Events " +
              "<br/>" +
              (selectedDayType !== "All Daytypes"
                ? selectedDayType + ", "
                : "") +
              monthsLookup.filter(
                (obj) => obj.value === query.statewide.month
              )[0].description +
              "-" +
              query.statewide.year +
              ", " +
              vehicleWeightClassOptions.filter(
                (obj) => obj.value === selectedVehicleWeightClass
              )[0].label
            }
            yAxisTitleText={"Parking Duration (Minutes)"}
            xAxisType={"time"}
            xAxisTitleText={"Time of Day"}
            seriesName1={"Parking Events"}
            allBoxPlotData={allBoxPlotData}
          />
        </Grid>
      )}

      {visibility.show7 && (
        <Grid item xs={12} sm={12} md={6} lg={6} id="columnplot">
          {hourEventCounts !== null && (
            <Column3dPlot
              themeHC={HighContrasLight}
              chartHeight={chartHeight}
              titleText={
                query.regionLookup +
                " Summary of Truck Parking Events " +
                "<br/>" +
                (selectedDayType !== "All Daytypes"
                  ? selectedDayType + ", "
                  : "") +
                monthsLookup.filter(
                  (obj) => obj.value === query.statewide.month
                )[0].description +
                "-" +
                query.statewide.year +
                ", " +
                vehicleWeightClassOptions.filter(
                  (obj) => obj.value === selectedVehicleWeightClass
                )[0].label
              }
              yAxisTitleText={"Parking Duration (Minutes)"}
              xAxisType={"time"}
              xAxisTitleText={"Time of Day"}
              barName1={"Number of Events"}
              barData1={hourEventCounts}
            />
          )}
        </Grid>
      )}
    </Grid>
  );
}

export default ChartsLayoutH3;
