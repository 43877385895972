/**
 * @fileOverview the main UI component
 */

import "./App.scss";

import { ThemeProvider, responsiveFontSizes } from "@material-ui/core/styles";
import { makeTheme, sidebarStyles } from "./ui/styles";

import AppBar from "@material-ui/core/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import IconButton from "@mui/material/IconButton";
import Layout from "./layouts/Layout";
import { Link, useLocation } from "react-router-dom";
import { QueryProvider } from "./context/QueryContext";
import React from "react";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { parkingSites } from "./common/parkingSites";
import useMediaQuery from "@material-ui/core/useMediaQuery";

function App() {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const mode = prefersDarkMode ? "dark" : "light";
  let theme = makeTheme(mode);
  theme = responsiveFontSizes(theme);
  const classes = sidebarStyles();

  /**To style the menu items on active state check url*/
  const location = useLocation();

  return (
    <ThemeProvider theme={theme}>
      <QueryProvider>
        <div className={classes.root}>
          <CssBaseline />

          <AppBar position="fixed" className={classes.appBar}>
            <Toolbar>
              <Grid container justifyContent="center">
                <Grid item xs={1}>
                  <Box
                    component="img"
                    sx={{
                      height: 58,
                      width: 87,
                      maxHeight: { xs: 233, md: 167 },
                      maxWidth: { xs: 350, md: 250 },
                    }}
                    alt="TxDOT Logo"
                    src="Transparent Background Color3lineLOGO_TxDOT_WHITE_300dpi.png"
                  />
                </Grid>
                <Grid item xs={1}>
                  <Box
                    height="100%"
                    display="flex"
                    justifyContent="center"
                    flexDirection="column"
                  >
                    <img
                      // className="logo"
                      src={"TTI-Color-reverse.png"}
                      alt="TTI Logo"
                      title="TTI Logo"
                    />
                  </Box>
                </Grid>
                <Grid item xs={6} align="center" sx={{ padding: 0, margin: 0 }}>
                  <Box
                    height="100%"
                    display="flex"
                    justifyContent="center"
                    flexDirection="column"
                  >
                    <Typography variant="h4" noWrap>
                      Texas Truck Parking Visualization
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={4} align="right">
                  <Box
                    height="100%"
                    display="flex"
                    justifyContent="flex-end"
                    flexDirection="row"
                  >
                    <IconButton
                      size="medium"
                      aria-label="statewide view"
                      aria-controls="menu-appbar"
                      aria-haspopup="false"
                      color="inherit"
                    >
                      <Box sx={{ m: 0.5 }}>
                        <Typography variant="h6" noWrap>
                          <Link
                            className={`menu-button ${
                              location.pathname === "/"
                                ? "menu-button-active"
                                : ""
                            }`}
                            style={{ textDecoration: "none", color: "white" }}
                            to="/"
                          >
                            Statewide
                          </Link>
                        </Typography>
                      </Box>
                    </IconButton>
                    <IconButton
                      size="medium"
                      aria-label="parking sites"
                      aria-controls="menu-appbar"
                      aria-haspopup="false"
                      color="inherit"
                    >
                      <Box sx={{ m: 0.5 }}>
                        <Typography variant="h6" noWrap>
                          <Link
                            className={`menu-button ${
                              location.pathname === "/parking-sites"
                                ? "menu-button-active"
                                : ""
                            }`}
                            style={{ textDecoration: "none", color: "white" }}
                            to="/parking-sites"
                          >
                            Parking Sites
                          </Link>
                        </Typography>
                      </Box>
                    </IconButton>
                    <IconButton
                      size="medium"
                      aria-label="Other-Resources"
                      aria-controls="other-resources"
                      aria-haspopup="false"
                      color="inherit"
                    >
                      <Box sx={{ m: 0.5 }}>
                        <Typography variant="h6" noWrap>
                          <Link
                            className={`menu-button ${
                              location.pathname === "/other-resources"
                                ? "menu-button-active"
                                : ""
                            }`}
                            style={{ textDecoration: "none", color: "white" }}
                            to="/other-resources"
                          >
                            Other Resources
                          </Link>
                        </Typography>
                      </Box>
                    </IconButton>
                  </Box>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>

          <main className={classes.content}>
            <div className={classes.toolbar} />
            <Layout />
          </main>
        </div>
      </QueryProvider>
    </ThemeProvider>
  );
}

export default App;
