import React, { useState, useContext, useEffect, useMemo } from "react";
import DeckGL from "@deck.gl/react";
import { ScatterplotLayer, GeoJsonLayer } from "@deck.gl/layers";
import { hexToRgb } from "../utils/utils";
import {
  StaticMap,
  MapContext,
  NavigationControl,
  ScaleControl,
  WebMercatorViewport,
} from "react-map-gl";
import { bbox, centroid } from "@turf/turf";
import { QueryContext } from "../context/QueryContext";
import LayerControls from "./contols/LayerControls";
import { Box, CircularProgress } from "../components/mui";

import SiteMapLegend from "./SiteMapLegend";

import truckParkingIconSvg from "../assets/icons/local_parking_white_on_green_24dp.svg";
import commercialBorderCrossingIconSvg from "../assets/icons/green_box_24dp.svg";
import railBorderCrossingIconSvg from "../assets/icons/pink_box_24dp.svg";
import cargoAirportsIconSvg from "../assets/icons/flight_yellow_24dp.svg";
import portsIconSvg from "../assets/icons/anchor_white_24dp.svg";
import parkingIconSvg from "../assets/icons/site_parking.svg";

import {
  fetchStatewideTruckParkingLocations,
  fetchCommercialBorderCrossings,
  fetchRailBorderCrossings,
  fetchCargoAirports,
  fetchPorts,
} from "../data-loaders/DataLoaders";
import { IconLayer } from "deck.gl";
import { parkingSites } from "../common/parkingSites";

function DeckglMapSites(props) {
  const {
    parkingSiteGeom,
    siteEventsGeom,
    selectedSite,
    setSelectedSite,
    isLoading,
  } = props;

  const [query, dispatch] = useContext(QueryContext);

  const [viewport, setViewport] = useState({
    width: "100%",
    height: "100%",
  });

  const [authorizedParkingSiteGeom, setAuthorizedParkingSiteGeom] =
    useState(null);
  const [unauthorizedParkingSiteGeom, setUnauthorizedParkingSiteGeom] =
    useState(null);
  const [filteredSiteEventsGeom, setFilteredSiteEventsGeom] = useState(null);

  const NAV_CONTROL_STYLE = {
    position: "absolute",
    marginTop: "10px",
    marginLeft: "12px",
  };
  const ScaleControl_STYLE = {
    position: "absolute",
    top: "96%",
    left: "125px",
  };
  const layers_CONTROL_STYLE = {
    position: "absolute",
    marginTop: "90px",
    marginLeft: "0px",
    paddingLeft: "0px",
  };

  const [baseMap, setBaseMap] = useState("mapbox://styles/mapbox/streets-v11");
  const [zoomLevel, setZoomLevel] = useState(7);

  const [homeMapBounds, setHomeMapBounds] = useState({
    longitude: -99.343675,
    latitude: 31.587881,
    zoom: 7,
    pitch: 0,
    bearing: 0,
  });

  function getBoundsForRoads(mapBounds_bbox) {
    const cornersLongLat = [
      [mapBounds_bbox[0], mapBounds_bbox[1]],
      [mapBounds_bbox[2], mapBounds_bbox[3]],
    ];
    // Use WebMercatorViewport to get center longitude/latitude and zoom
    const viewport = new WebMercatorViewport({
      width: 800,
      height: 800,
    }).fitBounds(cornersLongLat, { padding: 200 }); // Can also use option: offset: [0, -100]
    const { longitude, latitude, zoom } = viewport;
    setZoomLevel(zoom);
    return { longitude, latitude, zoom: zoom - 0.5 };
  }

  useEffect(() => {
    if (parkingSiteGeom?.features.length > 0) {
      const mapBounds_bbox = bbox(parkingSiteGeom);
      setHomeMapBounds(getBoundsForRoads(mapBounds_bbox));
      setAuthorizedParkingSiteGeom(parkingSiteGeomFilter(1));
      setUnauthorizedParkingSiteGeom(parkingSiteGeomFilter(0));
    }
  }, [parkingSiteGeom]);

  useEffect(() => {
    if (siteEventsGeom && !siteEventsGeom.hasOwnProperty("siteName")) {
      setFilteredSiteEventsGeom(siteEventsGeom);
    }
  }, [siteEventsGeom]);

  useEffect(() => {
    if (
      selectedSite?.name !== "All" &&
      !siteEventsGeom.hasOwnProperty("siteName")
    ) {
      let filtered = siteEventsGeom.filter((obj) =>
        query.selectedSite.parkingDurationCategories.includes(obj.parking_cat)
      );
      setFilteredSiteEventsGeom(filtered);
    }
  }, [query.selectedSite.parkingDurationCategories]);

  const handleViewStateChange = (newViewState) => {
    // Handle view state change
    if (newViewState.viewState.zoom !== homeMapBounds.zoom) {
      setZoomLevel(newViewState.viewState.zoom);
    }
  };

  function deckGlTooltip(layer, object) {
    let toolTipStyle = {
      backgroundColor: "#e1e7ed",
      color: "#000000",
      fontSize: "1.0em",
    };
    if (layer.id === "parkings-icons") {
      return {
        html:
          `<h7><strong>Parking Area</strong></h7>` +
          `<br />` +
          `<h7>Name: </h7> ${object.properties.name}`,
        style: toolTipStyle,
      };
    }
    if (layer.id === "parkingSiteGeom-authorized")
      return {
        html:
          `<h7><strong>Parking Area</strong></h7>` +
          `<br />` +
          `<h7>Name: </h7> ${object.properties.name}` +
          `<br />` +
          `Authorized Area` +
          `<br />` +
          `<h7>Direction: </h7> ${object.properties.dir}` +
          `<br />` +
          `<h7>Number of spaces: </h7> ${object.properties.space}`,
        style: toolTipStyle,
      };
    // "landUse"
    if (layer.id === "parkingSiteGeom-unauthorized")
      return {
        html:
          `<h7><strong>Parking Area</strong></h7>` +
          `<br />` +
          `<h7>Name: </strong></h7> ${object.properties.name}` +
          `<br />` +
          `Unauthorized Area` +
          `<br />` +
          `<h7>Direction: </h7> ${object.properties.dir}`,
        style: toolTipStyle,
      };
    if (layer.id === "siteEvents")
      return {
        html:
          `<h7><strong>Parking Event</strong></h7>` +
          `<br />` +
          `<h7>Site: </h7> ${object.sitename}` +
          `<br />` +
          `${object.authorized} area` +
          `<br />` +
          `<h7>Direction: </h7> ${object.dir}` +
          `<br />` +
          `<h7>Parking Duration: </h7> ${object.parking_duration}` +
          `<br />` +
          `<h7>Parked Time: </h7> ${object.total_parked_time}`,

        style: toolTipStyle,
      };

    //siteEvents

    return;
  }

  function parkingSiteGeomFilter(category) {
    let filtered = parkingSiteGeom?.features.filter(
      (f) => f.properties.authorized === category
    );
    return {
      type: "FeatureCollection",
      features: filtered,
    };
  }

  const [showSiteParkingEvents, setShowSiteParkingEvents] = useState(true);
  const [showAuthorizedParkingArea, setShowAuthorizedParkingArea] =
    useState(true);
  const [showUnauthorizedParkingArea, setShowUnauthorizedParkingArea] =
    useState(true);
  const [showStateParkingSites, setShowStateParkingSites] = useState(false);
  const [showCommercialBorderCrossings, setShowCommercialBorderCrossings] =
    useState(false);
  const [showRailBorderCrossings, setShowRailBorderCrossings] = useState(false);
  const [showCargoAirports, setShowCargoAirports] = useState(false);
  const [showPorts, setShowPorts] = useState(false);
  const dataLayers = [
    {
      label: "Site Parking Events",
      key: "site-parking-events",
      visibility: showSiteParkingEvents,
      setVisibility: setShowSiteParkingEvents,
      icon: null,
    },
    {
      label: "Authorized Parking Area",
      key: "authorized-parking-area",
      visibility: showAuthorizedParkingArea,
      setVisibility: setShowAuthorizedParkingArea,
      icon: null,
    },
    {
      label: "Unauthorized Parking Area",
      key: "unauthorized-parking-area",
      visibility: showUnauthorizedParkingArea,
      setVisibility: setShowUnauthorizedParkingArea,
      icon: null,
    },
    {
      label: "Statewide Parking Sites",
      key: "state-parking-sites",
      visibility: showStateParkingSites,
      setVisibility: setShowStateParkingSites,
      icon: truckParkingIconSvg,
    },
    {
      label: "Commercial Border Crossings",
      key: "commercial-border-crossings",
      visibility: showCommercialBorderCrossings,
      setVisibility: setShowCommercialBorderCrossings,
      icon: commercialBorderCrossingIconSvg,
    },
    {
      label: "Rail Border Crossings",
      key: "rail-border-crossings",
      visibility: showRailBorderCrossings,
      setVisibility: setShowRailBorderCrossings,
      icon: railBorderCrossingIconSvg,
    },
    {
      label: "Cargo Airports",
      key: "cargo-airports",
      visibility: showCargoAirports,
      setVisibility: setShowCargoAirports,
      icon: cargoAirportsIconSvg,
    },
    {
      label: "Ports",
      key: "ports",
      visibility: showPorts,
      setVisibility: setShowPorts,
      icon: portsIconSvg,
    },
  ];
  const [portsData, setPortsData] = useState(null);
  useMemo(() => {
    if (showPorts && portsData === null) {
      const fetchPortsData = async () => {
        await fetchPorts().then((res) => {
          setPortsData(res.data);
        });
      };
      fetchPortsData();
    }
  }, [portsData, showPorts]);
  const [cargoAirportsData, setCargoAirportsData] = useState(null);
  useMemo(() => {
    if (showCargoAirports && cargoAirportsData === null) {
      const fetchCargoAirportsData = async () => {
        await fetchCargoAirports().then((res) => {
          setCargoAirportsData(res.data);
        });
      };
      fetchCargoAirportsData();
    }
  }, [cargoAirportsData, showCargoAirports]);
  const [railBorderCrossingsData, setRailBorderCrossingsData] = useState(null);
  useMemo(() => {
    if (showRailBorderCrossings && railBorderCrossingsData === null) {
      const fetchRailBorderCrossingsData = async () => {
        await fetchRailBorderCrossings().then((res) => {
          setRailBorderCrossingsData(res.data);
        });
      };
      fetchRailBorderCrossingsData();
    }
  }, [railBorderCrossingsData, showRailBorderCrossings]);
  const [commercialBorderCrossingsData, setCommercialBorderCrossingsData] =
    useState(null);
  useMemo(() => {
    if (
      showCommercialBorderCrossings &&
      commercialBorderCrossingsData === null
    ) {
      const fetchCommercialBorderCrossingsData = async () => {
        await fetchCommercialBorderCrossings().then((res) => {
          setCommercialBorderCrossingsData(res.data);
        });
      };
      fetchCommercialBorderCrossingsData();
    }
  }, [commercialBorderCrossingsData, showCommercialBorderCrossings]);
  const [stateParkingSitesData, setStateParkingSitesData] = useState(null);
  useMemo(() => {
    if (showStateParkingSites && stateParkingSitesData === null) {
      const fetchStateParkingSitesData = async () => {
        await fetchStatewideTruckParkingLocations().then((res) => {
          setStateParkingSitesData(res.data);
        });
      };
      fetchStateParkingSitesData();
    }
  }, [showStateParkingSites, stateParkingSitesData]);

  return (
    <div onContextMenu={(e) => e.preventDefault()} id="map">
      <DeckGL
        {...viewport}
        initialViewState={homeMapBounds}
        getTooltip={({ layer, object }) =>
          object && deckGlTooltip(layer, object)
        }
        controller={true}
        ContextProvider={MapContext.Provider}
        onViewStateChange={handleViewStateChange}
      >
        <StaticMap
          mapStyle={baseMap}
          mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_ACCESSTOKEN}
          preventStyleDiffing={true}
        />
        <NavigationControl style={NAV_CONTROL_STYLE} captureScroll={true} />
        <div style={layers_CONTROL_STYLE}>
          <LayerControls
            baseMap={baseMap}
            setBaseMap={setBaseMap}
            dataLayers={dataLayers}
          />
        </div>
        <div className="sites-map-legend">
          <SiteMapLegend dataLayers={dataLayers} />
        </div>
        {isLoading && (
          <Box sx={{ marginTop: "43vh", marginLeft: "50%" }}>
            <CircularProgress
              sx={{
                marginLeft: "5%",
                marginTop: "5%",
              }}
              size={50}
              thickness={5}
            />
          </Box>
        )}

        {parkingSiteGeom?.features.length > 0 && zoomLevel && !isLoading && (
          <IconLayer
            id="parkings-icons"
            visible={zoomLevel < 12}
            data={parkingSiteGeom.features.map((feature) => ({
              ...feature,
              coordinates: centroid(feature).geometry.coordinates, // Calculate centroid for each feature
            }))}
            pickable={true}
            iconAtlas={parkingIconSvg}
            iconMapping={{
              marker: {
                x: 0,
                y: 0,
                width: 64,
                height: 64,
                anchorY: 64,
              },
            }}
            getPosition={(d) => d.coordinates}
            getIcon={(d) => "marker"}
            getSize={(d) => 24}
            getColor={(d) => [0, 140, 250]}
            onClick={(info) =>
              setSelectedSite(
                parkingSites.find(
                  (p) => p.name === info.object?.properties.name
                )
              )
            }
          />
        )}

        {showAuthorizedParkingArea && (
          <GeoJsonLayer
            id="parkingSiteGeom-authorized"
            data={authorizedParkingSiteGeom}
            filled={true}
            opacity={0.25}
            pickable={true}
            pickingRadius={5}
            stroked={true}
            pointType={"circle"}
            lineWidthScale={5}
            lineWidthMinPixels={0.1}
            getPointRadius={0.5}
            getLineWidth={0.5}
            autoHighlight={true}
            highlightColor={[160, 160, 180, 10]}
            getLineColor={[46, 49, 49]}
            getFillColor={"#90ee90"
              .match(/[0-9a-f]{2}/g)
              .map((x) => parseInt(x, 16))}
          />
        )}
        {showUnauthorizedParkingArea && (
          <GeoJsonLayer
            id="parkingSiteGeom-unauthorized"
            data={unauthorizedParkingSiteGeom}
            filled={true}
            opacity={0.001}
            pickable={true}
            pickingRadius={5}
            stroked={true}
            pointType={"circle"}
            lineWidthScale={5}
            lineWidthMinPixels={0.1}
            getPointRadius={0.5}
            getLineWidth={0.75}
            autoHighlight={true}
            highlightColor={[160, 160, 180, 10]}
            getLineColor={[255, 0, 0, 2000]}
            getFillColor={"#90ee90"
              .match(/[0-9a-f]{2}/g)
              .map((x) => parseInt(x, 16))}
          />
        )}
        {showSiteParkingEvents && filteredSiteEventsGeom && (
          <ScatterplotLayer
            id="siteEvents"
            data={filteredSiteEventsGeom}
            getPosition={(d) => [d.long, d.lat]}
            pickable={true}
            opacity={query.mapOpacity}
            filled={true}
            getRadius={1}
            getLineWiddth={0.0005}
            radiusScale={8}
            radiusMinPixels={1}
            radiusMaxPixels={5}
            lineWidthMinPixels={1}
            getFillColor={(d) => {
              let colors = [
                "#7F3C8D",
                "#11A579",
                "#3969AC",
                "#F2B701",
                "#E73F74",
                "#80BA5A",
                "#E68310",
                "#008695",
                "#CF1C90",
                "#f97b72",
                "#4b4b8f",
                "#A5AA99",
              ];

              let colorTemp = colors[d.parking_cat - 1];
              let colorTempRGB = hexToRgb(colorTemp);
              return [colorTempRGB.r, colorTempRGB.g, colorTempRGB.b];
            }}
            getLineColor={(d) => [0, 0, 0]}
          />
        )}
        {stateParkingSitesData !== null && showStateParkingSites && (
          <GeoJsonLayer
            id="state-parking-sites"
            data={stateParkingSitesData}
            pickable={true}
            wireframe={false}
            pointType={"icon"}
            getIcon={() => ({
              url: truckParkingIconSvg,
              width: 24,
              height: 24,
            })}
            getIconSize={1}
            getIconColor={(d) => [Math.sqrt(d.exits), 140, 0]}
            getIconAngle={0}
            getIconPixelOffset={[0, 0]}
            iconSizeUnits={"pixels"}
            iconSizeScale={15}
          />
        )}
        {commercialBorderCrossingsData !== null &&
          showCommercialBorderCrossings && (
            <GeoJsonLayer
              id="commercial-border-crossings"
              data={commercialBorderCrossingsData}
              pickable={true}
              wireframe={false}
              pointType={"icon"}
              getIcon={() => ({
                url: commercialBorderCrossingIconSvg,
                width: 24,
                height: 24,
              })}
              getIconSize={1}
              getIconColor={(d) => [Math.sqrt(d.exits), 140, 0]}
              getIconAngle={0}
              getIconPixelOffset={[0, 0]}
              iconSizeUnits={"pixels"}
              iconSizeScale={15}
            />
          )}
        {railBorderCrossingsData !== null && showRailBorderCrossings && (
          <GeoJsonLayer
            id="rail-border-crossings"
            data={railBorderCrossingsData}
            pickable={true}
            wireframe={false}
            pointType={"icon"}
            getIcon={() => ({
              url: railBorderCrossingIconSvg,
              width: 24,
              height: 24,
            })}
            getIconSize={1}
            getIconColor={(d) => [Math.sqrt(d.exits), 140, 0]}
            getIconAngle={0}
            getIconPixelOffset={[0, 0]}
            iconSizeUnits={"pixels"}
            iconSizeScale={15}
          />
        )}
        {cargoAirportsData !== null && showCargoAirports && (
          <GeoJsonLayer
            id="cargo-airports"
            data={cargoAirportsData}
            pickable={true}
            wireframe={false}
            pointType={"icon"}
            getIcon={() => ({
              url: cargoAirportsIconSvg,
              width: 24,
              height: 24,
            })}
            getIconSize={1}
            getIconColor={(d) => [Math.sqrt(d.exits), 140, 0]}
            getIconAngle={0}
            getIconPixelOffset={[0, 0]}
            iconSizeUnits={"pixels"}
            iconSizeScale={15}
          />
        )}
        {portsData !== null && showPorts && (
          <GeoJsonLayer
            id="ports"
            data={portsData}
            pickable={true}
            wireframe={false}
            pointType={"icon"}
            getIcon={() => ({
              url: portsIconSvg,
              width: 24,
              height: 24,
            })}
            getIconSize={1}
            getIconColor={(d) => [Math.sqrt(d.exits), 140, 0]}
            getIconAngle={0}
            getIconPixelOffset={[0, 0]}
            iconSizeUnits={"pixels"}
            iconSizeScale={15}
          />
        )}

        <ScaleControl style={ScaleControl_STYLE} />
      </DeckGL>
    </div>
  );
}

export default DeckglMapSites;
