import { Route, Routes } from "react-router-dom";

import OtherResources from "./OtherResources";
import React from "react";
import SitesLayout from "./SitesLayout";
import StatewideLayoutH3 from "./StatewideLayoutH3";

const Layout = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={<StatewideLayoutH3 statusGuide="State" />}
      ></Route>
      <Route
        path="/parking-sites"
        element={<SitesLayout statusGuide="ParkingSites" />}
      ></Route>
      <Route
        path="/other-resources"
        element={<OtherResources statusGuide="Other-Resources" />}
      ></Route>
    </Routes>
  );
};

export default Layout;
