import React, { useState, useContext, useEffect } from "react";
import MaterialTable from "material-table";
import { QueryContext } from "../context/QueryContext";
import { fetchEventCountsByMonthParkingCat } from "../data-loaders/DataLoaders";
import { yearsMonths, dayTypeOptions } from "../common/constants";

import { Chip, Divider, Typography, Box } from "../components/mui";

export default function TablePanel(props) {
  const [query, dispatch] = useContext(QueryContext);
  const { id, titleDesc } = props;
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    const setEventCountsByMonthParkingCat = async () => {
      let vehWeightClasses = [2, 3];
      if (query.selectedVehicleWeightClass != 0)
        vehWeightClasses = query.selectedVehicleWeightClass;
      let dayTypes = [1, 2, 3, 4, 5, 6, 7];
      if (query.selectedDayType != "All Daytypes")
        dayTypes = dayTypeOptions.filter(
          (itm) => itm.label === query.selectedDayType
        )[0].value;

      let months = [];
      let parkingCatDataTemp = [];
      yearsMonths
        .filter((itm) => itm.year === query.statewide.year)
        .map((obj) => {
          months.push(obj.month);
          parkingCatDataTemp.push({
            region: query.geoFilterMode,
            start_year: query.statewide.year,
            start_month: obj.month,
            month: obj.yearMonth.split("-")[1],
            parking_cat_1: 0,
            parking_cat_2: 0,
            parking_cat_3: 0,
            parking_cat_4: 0,
            parking_cat_5: 0,
            parking_cat_total: 0,
          });
        });

      await fetchEventCountsByMonthParkingCat(
        query.geoFilterMode,
        query.statewide.year,
        months,
        dayTypes,
        vehWeightClasses,
        query.geoFilterOption
      ).then((res) => {
        if (res?.data.length > 0) {
          res.data.map((itm) => {
            let colDesc = "parking_cat_" + String(itm.parking_cat);
            parkingCatDataTemp.filter(
              (obj) => obj.start_month === itm.start_month
            )[0][colDesc] = itm.count;
          });

          parkingCatDataTemp.map((itm) => {
            let parking_cat_total =
              itm.parking_cat_1 +
              itm.parking_cat_2 +
              itm.parking_cat_3 +
              itm.parking_cat_4 +
              itm.parking_cat_5;
            itm.parking_cat_total = parking_cat_total;
            let parking_cat_1_pc =
              (itm.parking_cat_1 / parking_cat_total) * 100;
            let parking_cat_2_pc =
              (itm.parking_cat_2 / parking_cat_total) * 100;
            let parking_cat_3_pc =
              (itm.parking_cat_3 / parking_cat_total) * 100;
            let parking_cat_4_pc =
              (itm.parking_cat_4 / parking_cat_total) * 100;
            let parking_cat_5_pc =
              (itm.parking_cat_5 / parking_cat_total) * 100;
            itm.parking_cat_1_pc = parking_cat_1_pc.toFixed(2);
            itm.parking_cat_2_pc = parking_cat_2_pc.toFixed(2);
            itm.parking_cat_3_pc = parking_cat_3_pc.toFixed(2);
            itm.parking_cat_4_pc = parking_cat_4_pc.toFixed(2);
            itm.parking_cat_5_pc = parking_cat_5_pc.toFixed(2);
            itm.parking_cat_total_pc = (
              parking_cat_1_pc +
              parking_cat_2_pc +
              parking_cat_3_pc +
              parking_cat_4_pc +
              parking_cat_5_pc
            ).toFixed(2);
          });
          setTableData(parkingCatDataTemp);
        } else {
          setTableData(null);
        }
      });
    };
    if (query.geoFilterMode === "statewide" || query.geoFilterOption !== "") {
      setEventCountsByMonthParkingCat();
    }
  }, [
    query.geoFilterMode,
    query.statewide.year,
    query.selectedDayType,
    query.selectedVehicleWeightClass,
    query.geoFilterOption,
  ]);

  const materialTableDetailPanel = (data) => {
    // console.log("detailPanel-check", data);
    let detailData = [
      {
        region: data.region,
        start_year: data.start_year,
        start_month: data.start_month,
        parking_cat_1: data.parking_cat_1,
        parking_cat_2: data.parking_cat_2,
        parking_cat_3: data.parking_cat_3,
        parking_cat_4: data.parking_cat_4,
        parking_cat_5: data.parking_cat_5,
        parking_cat_total: data.parking_cat_total,
      },
    ];

    return (
      <div
        style={{
          fontSize: 14,
          textAlign: "center",
          color: "white",
          backgroundColor: "#E53935",
          marginLeft: 10,
          marginBottom: 10,
          marginTop: 0,
          borderTopWidth: 0,
        }}
      >
        <MaterialTable
          title=""
          columns={[
            // { title: "Region", field: "region" },
            // { title: "Year", field: "start_year", width: "5%" },
            // { title: "Month", field: "start_month" },
            {
              title: "< 1 hour",
              field: "parking_cat_1",
              type: "numeric",
              render: (obj) => obj.parking_cat_1.toLocaleString(),
            },
            {
              title: ">= 1, < 3 hours",
              field: "parking_cat_2",
              type: "numeric",
              render: (obj) => obj.parking_cat_2.toLocaleString(),
            },
            {
              title: ">= 3, < 7 hours",
              field: "parking_cat_3",
              type: "numeric",
              render: (obj) => obj.parking_cat_3.toLocaleString(),
            },
            {
              title: ">= 7, < 11 hours",
              field: "parking_cat_4",
              type: "numeric",
              render: (obj) => obj.parking_cat_4.toLocaleString(),
            },
            {
              title: ">= 11 hours",
              field: "parking_cat_5",
              type: "numeric",
              render: (obj) => obj.parking_cat_5.toLocaleString(),
            },
            {
              title: "Total Events",
              field: "parking_cat_total",
              type: "numeric",
              render: (obj) => obj.parking_cat_total.toLocaleString(),
            },
          ]}
          data={detailData}
          options={{
            // exportButton: { csv: true, pdf: false },
            // // maxBodyHeight: "25vh"
            // exportAllData: true,
            paging: false,
            search: false,
          }}
        />
      </div>
    );
  };

  return (
    <Box id={id} className="parkingevent">
      <Divider
        component="div"
        role="presentation"
        textAlign="center"
        sx={{ paddingBottom: "1rem", paddingTop: "0.5rem" }}
      >
        <Chip
          label={
            <Typography
              variant="h5"
              component="div"
              sx={{ padding: "5px", textAlign: "center" }}
            >
              Parking Events Summary
            </Typography>
          }
          sx={{ backgroundColor: "#a9a9a9" }}
        />
      </Divider>

      {tableData && tableData.length > 0 && (
        <MaterialTable
          columns={[
            { title: "Region", field: "region", hidden: true, export: true },
            {
              title: "Year",
              field: "start_year",
              width: "5%",
              hidden: true,
              export: true,
            },
            {
              title: "Month",
              field: "month",
              cellStyle: {
                backgroundColor: "#039be5",
                color: "#FFF",
                // width: "100px",
                // maxWidth: "2px",
              },
            },
            {
              title: "Events < 1 hour",
              field: "parking_cat_1",
              type: "numeric",
              hidden: true,
              export: true,
            },
            {
              title: "Events >= 1, < 3 hours",
              field: "parking_cat_2",
              type: "numeric",
              hidden: true,
              export: true,
            },
            {
              title: "Events >= 3, < 7 hours",
              field: "parking_cat_3",
              type: "numeric",
              hidden: true,
              export: true,
            },
            {
              title: "Events >= 7, < 11 hours",
              field: "parking_cat_4",
              type: "numeric",
              hidden: true,
              export: true,
            },
            {
              title: "Events >= 11 hours",
              field: "parking_cat_5",
              type: "numeric",
              hidden: true,
              export: true,
            },
            {
              title: "Total Events",
              field: "parking_cat_total",
              type: "numeric",
              hidden: true,
              export: true,
            },
            {
              title: "% < 1 hour",
              field: "parking_cat_1_pc",
              type: "numeric",
            },
            {
              title: "% >= 1, < 3 hours",
              field: "parking_cat_2_pc",
              type: "numeric",
            },
            {
              title: "% >= 3, < 7 hours",
              field: "parking_cat_3_pc",
              type: "numeric",
            },
            {
              title: "% >= 7, < 11 hours",
              field: "parking_cat_4_pc",
              type: "numeric",
            },
            {
              title: "% >= 11 hours",
              field: "parking_cat_5_pc",
              type: "numeric",
            },
            {
              title: "Total",
              field: "parking_cat_total_pc",
              type: "numeric",
            },
          ]}
          data={tableData}
          title={titleDesc}
          options={{
            exportButton: { csv: true, pdf: false },
            // maxBodyHeight: "25vh"
            exportAllData: true,
            exportFileName:
              tableData[0].region +
              "_" +
              tableData[0].start_year +
              "-" +
              tableData[0].start_month +
              "-" +
              (query.selectedDayType === "All Daytypes"
                ? "AllDaytypes"
                : query.selectedDayType) +
              "_" +
              (query.selectedVehicleWeightClass === 0
                ? "alltrucks"
                : "vehwghtclass" + query.selectedVehicleWeightClass) +
              "_truckparkingevents",
            paging: false,
            rowStyle: {
              wordWrap: "break-word",
            },
            padding: "dense",
            // tableLayout: "auto",
            doubleHorizontalScroll: false,
            detailPanelType: "multiple",
            headerStyle: {
              // position: "sticky",
              top: 0,
              paddingTop: 0,
              marginTop: 0,
              backgroundColor: "#01579b",
              color: "#FFF",
            },
            titleStyle: {
              paddingTop: 0,
              marginTop: 0,
            },
          }}
          detailPanel={[
            {
              // disabled: roadLayerType === "MD100",
              tooltip: "Show Detailed Information",
              render: (rowData) => {
                // console.log("rowData-check", rowData[0]);
                return (
                  <div
                    style={{
                      fontSize: 100,
                      textAlign: "center",
                      color: "white",
                      backgroundColor: "#43A047",
                    }}
                  >
                    {materialTableDetailPanel(rowData)}
                  </div>
                );
              },
            },
          ]}
        />
      )}
      <Divider
        component="div"
        role="presentation"
        textAlign="center"
        sx={{ paddingBottom: "1rem" }}
      ></Divider>
    </Box>
  );
}
