/**
 * @fileOverview the main UI component
 */

import "../App.scss";

import { QueryContext, QueryProvider } from "../context/QueryContext";
import React, { useContext, useState } from "react";
import { cardStyles, gridStyles, sidebarStyles } from "../ui/styles";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import { CardActionArea } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

// import CardActions from "@mui/material/CardActions";

// import CardMedia from "@mui/material/CardMedia";

// import ButtonGroup from "@mui/material/ButtonGroup";

export default function OtherResources(props) {
  const classesCards = cardStyles();

  const [query, dispatch] = useContext(QueryContext);

  const [storyMapLink, setStoryMapLink] = useState(
    "https://storymaps.arcgis.com/stories/f2345f35ef524be8993615965b1f263a"
  );

  const freightGenerators = [
    {
      name: "DFW Alliance Area",
      storymapLink:
        "https://storymaps.arcgis.com/stories/bfd7f9699f1c45398ce016d870ecc58e",
    },
    {
      name: "Dallas South Distribution Center Cluster",
      storymapLink:
        "https://storymaps.arcgis.com/stories/5d1dd647942845c2b546749fa8294c96",
    },
    {
      name: "Dallas YRC Freight",
      storymapLink:
        "https://storymaps.arcgis.com/stories/0940b801e1b648c6808a7596240a67d3",
    },
    {
      name: "Houston Barbours Cut Terminal",
      storymapLink:
        "https://storymaps.arcgis.com/stories/0d1e32966d134de69b9fb121edc40472",
    },
    {
      name: "Houston Cedar Port Industrial Park",
      storymapLink:
        "https://storymaps.arcgis.com/stories/8354d4e51422475e8c49e761e5510c86",
    },
    {
      name: "Laredo Industrial area",
      storymapLink:
        "https://storymaps.arcgis.com/stories/5624ca62913b4e1c829b8f4b2d979db0",
    },
    {
      name: "Odessa Halliburton/American Cementing",
      storymapLink:
        "https://storymaps.arcgis.com/stories/84e0f33eef6e4175a01d8545ee1bcd24",
    },
    {
      name: "San Antonio Halliburton",
      storymapLink:
        "https://storymaps.arcgis.com/stories/e827396baa244af1b019138231194f03",
    },
    {
      name: "San Antonio HEB/IKEA warehouses",
      storymapLink:
        "https://storymaps.arcgis.com/stories/6b713daf50a64d3dbd87b6d950ee9689",
    },
    {
      name: "Temple",
      storymapLink:
        "https://storymaps.arcgis.com/stories/358cb7de3bc148c198fc1cea7507c0de",
    },
  ];

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    if (event.target.id) {
      let cardAttributesTemp = query.cardAttributes;
      cardAttributesTemp.usBorder = "inactive";
      cardAttributesTemp.disruptiveEvents = "inactive";
      cardAttributesTemp.freightGenerators = "active";
      // setCardAttributes(cardAttributesTemp);
      dispatch({
        type: "setCardAttributes",
        selection: cardAttributesTemp,
      });
      setStoryMapLink(freightGenerators[event.target.id].storymapLink);
    }
    setAnchorEl(null);
  };

  const disruptiveEvents = [
    {
      name: "Austin 2019 SXSW Conference & Festivals",
      storymapLink: "https://arcg.is/bD5Si",
    },
    {
      name: "San Antonio Stockshow and Rodeo",
      storymapLink: "https://arcg.is/0PzOei",
    },
  ];

  const [anchorElDE, setAnchorElDE] = React.useState(null);
  const openDE = Boolean(anchorElDE);
  const handleClickDE = (event) => {
    setAnchorElDE(event.currentTarget);
  };
  const handleCloseDE = (event) => {
    if (event.target.id) {
      let cardAttributesTemp = query.cardAttributes;
      cardAttributesTemp.usBorder = "inactive";
      cardAttributesTemp.disruptiveEvents = "active";
      cardAttributesTemp.freightGenerators = "inactive";
      // setCardAttributes(cardAttributesTemp);
      dispatch({
        type: "setCardAttributes",
        selection: cardAttributesTemp,
      });
      setStoryMapLink(disruptiveEvents[event.target.id].storymapLink);
    }
    setAnchorElDE(null);
  };

  return (
    <Stack direction="column" spacing={0}>
      <Box
        // height="90vh"
        // display="flex"
        flexDirection="column"
        style={{ overflow: "none" }}
      >
        <Grid container spacing={0}>
          <Grid item xs={5}>
            {" "}
            <Card
              sx={{ maxWidth: 345, p: 0, m: 0 }}
              class={classesCards[query.cardAttributes.usBorder]}
            >
              <CardActionArea
                onClick={(event) => {
                  // console.log("event-check", event);
                  let cardAttributesTemp = query.cardAttributes;
                  cardAttributesTemp.usBorder = "active";
                  cardAttributesTemp.disruptiveEvents = "inactive";
                  cardAttributesTemp.freightGenerators = "inactive";
                  // setCardAttributes(cardAttributesTemp);
                  dispatch({
                    type: "setCardAttributes",
                    selection: cardAttributesTemp,
                  });
                  setStoryMapLink(
                    "https://storymaps.arcgis.com/stories/f2345f35ef524be8993615965b1f263a"
                  );
                }}
              >
                <CardContent sx={{ mt: 0.5, pt: 1, mb: 0, pt: 0 }}>
                  <Typography variant="h5" component="div">
                    Truck Parking at US-Mexico Border Ports Of Entry
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ marginBottom: 0, paddingBottom: 0 }}
                  >
                    This study uses the probe data provided by INRIX to analyze
                    the truck parking issues in the El Paso region.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <Card
              sx={{
                // width: "25%",
                maxWidth: 345,
                m: 0,
                ml: 0.5,
                p: 0,
              }}
              class={classesCards[query.cardAttributes.disruptiveEvents]}
            >
              <CardActionArea onClick={handleClickDE}>
                <CardContent sx={{ mt: 0.5, pt: 0, mb: 0, pb: 0 }}>
                  <Typography variant="h5" component="div">
                    Disruptive Events
                  </Typography>
                  <Typography variant="body2">
                    The purpose of purpose of these studies are to determine if
                    there is a relationship between truck parking and a specific
                    disruptive event.
                  </Typography>
                </CardContent>
              </CardActionArea>
              <Menu
                id="basic-menu-DE"
                anchorEl={anchorElDE}
                open={openDE}
                onClose={handleCloseDE}
                MenuListProps={{
                  "aria-labelledby": "basic-button-DE",
                }}
              >
                {disruptiveEvents.map((itm, index) => (
                  <MenuItem
                    onClick={handleCloseDE}
                    value={itm.storymapLink}
                    id={index}
                  >
                    {itm.name}
                  </MenuItem>
                ))}
              </Menu>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card
              sx={{ maxWidth: 345, p: 0, m: 0 }}
              class={classesCards[query.cardAttributes.freightGenerators]}
            >
              <CardActionArea onClick={handleClick}>
                <CardContent sx={{ mt: 0.5, pt: 1, mb: 0, pt: 0 }}>
                  <Typography variant="h5" component="div">
                    Freight Generators
                  </Typography>

                  <Typography
                    variant="body2"
                    sx={{ marginBottom: 0, paddingBottom: 0 }}
                  >
                    This study examines the first/last stops of trips
                    leaving/entering the freight generator. Click here to load
                    options.
                  </Typography>
                </CardContent>
              </CardActionArea>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                {freightGenerators.map((itm, index) => (
                  <MenuItem
                    onClick={handleClose}
                    value={itm.storymapLink}
                    id={index}
                  >
                    {itm.name}
                  </MenuItem>
                ))}
              </Menu>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <Box
        height="78vh"
        display="flex"
        flexDirection="column"
        style={{ overflow: "none" }}
      >
        <iframe
          src={storyMapLink}
          width="100%"
          height="900px"
          frameborder="0"
          allowfullscreen
          allow="geolocation"
        ></iframe>
      </Box>
    </Stack>
  );
}
