import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  Divider,
  ExpandMoreIcon,
  Typography,
} from "../components/mui.js";

export default function DashboardLinksStatewide({ toggleVisibility }) {
  return (
    <Box className="dashboardOverview">
      <Divider
        aria-hidden="true"
        component="div"
        role="presentation"
        className="dashboardOverview-topDivider"
      >
        <Typography variant="h4">Demand Overview</Typography>
      </Divider>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
          sx={{ height: "50px" }}
        >
          <Typography>
            <Checkbox
              defaultChecked
              onChange={() => toggleVisibility("show1")}
            />
            <Link to="#parkingevent" smooth>
              Parking Events Summary
            </Link>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          Comparison of distribution of parking events across the parking
          duration categories by month.
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
          sx={{ height: "50px" }}
        >
          <Typography>
            <Checkbox
              defaultChecked
              onChange={() => toggleVisibility("show2")}
            />
            <Link to="#donut" smooth>
              Parking events by Month, Parking Duration Category
            </Link>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          Donut chart showing the distribution of the statewide parking events
          for the selected month across the parking duration categories.
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
          sx={{ height: "50px" }}
        >
          <Typography>
            <Checkbox
              defaultChecked
              onChange={() => toggleVisibility("show3")}
            />
            <Link to="#stackedbarbydow" smooth>
              Parking Events by Day-of-Week
            </Link>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          Chart showing the distribution of the statewide parking events across
          the parking duration categories for the selected month by day of week.
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
          sx={{ height: "50px" }}
        >
          <Typography>
            <Checkbox
              defaultChecked
              onChange={() => toggleVisibility("show4")}
            />
            <Link to="#stackedbar" smooth>
              Parking Events by Month
            </Link>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          Chart showing the distribution of the statewide parking events across
          the parking duration categories for the selected year by month.
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
          sx={{ height: "50px" }}
        >
          <Typography>
            <Checkbox
              defaultChecked
              onChange={() => toggleVisibility("show5")}
            />
            <Link to="#timeseries" smooth>
              Parking Events by Duration
            </Link>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          Chart showing the distribution of the statewide parking events across
          the parking duration categories for the selected year by month.
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
          sx={{ height: "50px" }}
        >
          <Typography>
            <Checkbox
              defaultChecked
              onChange={() => toggleVisibility("show6")}
            />
            <Link to="#boxplot" smooth>
              Truck Parking Events
            </Link>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          Statistics on the statewide parking events for the selected year and
          month by time-of-day.
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
          sx={{ height: "50px" }}
        >
          <Typography>
            <Checkbox
              defaultChecked
              onChange={() => toggleVisibility("show7")}
            />
            <Link to="#columnplot" smooth>
              Truck Parking Events by Time-of-Day
            </Link>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          Distribution of statewide parking events for the selected year and
          month by time-of-day.
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
