import { createTheme, makeStyles } from "@material-ui/core/styles";

// Main theme and responsive fonts
export function makeTheme(mode) {
  return createTheme({
    palette: {
      type: mode,
      primary: {
        // light: "#1E1E1E",
        light: "#1E1E1E",
        main: "#14375a",
        dark: "#000000",
        contrastText: "#fff",
      },
      secondary: {
        light: "#ffd35f",
        main: "#b28c27",
        dark: "#b28c27",
        contrastText: "#000",
      },
      background: {
        paper: mode === "dark" ? "#1E1E1E" : "#ffffff",
      },
      layersControl: {
        paper: "#ffffff",
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
  });
}

// Sidebar
const drawerWidth = 240;
export const sidebarStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: "auto",
  },
  content: {
    flexGrow: 1,
    padding: 0,
  },
}));

export const gridStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  gridRow1: {
    // flexGrow: 1,
    marginBottom: theme.spacing(1),
  },
  menuListContainer: {
    // background: "#4d4dff",
    //float: 'left',
    // marginLeft: "5px",
    // marginRight: "5px",
    // marginBottom: "10px",
    marginTop: "0px",
    paddingTop: "0px",
    // left: "75%",
  },
  formLabel: {
    marginLeft: "5px",
    paddingLeft: "5px",
    color: "black",
  },
}));

export const cardStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  active: {
    // flexGrow: 1,
    marginBottom: 0,
    marginTop: 0,
    // backgroundColor: "#50C878",
    background: "#50C878",
  },
  inactive: {
    marginBottom: 0,
    marginTop: 0,
  },
}));
