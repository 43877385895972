/**
 * @fileOverview the main UI component
 * Displays parking sites overview using gemetries
 * @param  {Object[][]} props
 *     @param {String} statusGuide a tracking string for current page expected value "ParkingSites"
 * @Components
 *      @DeckglMapSites Displays the selected parking site geometry and events on the map
 *      @ChartsLayoutSites Displays the charts for public parking site events
 *      @ChartsLayoutPrivateSites Displays the charts for private parking site events
 *      @TablePanelSites Displays events by parking category in a table
 * @returns {SitesLayout}
 */

import React, { useState, useRef, useEffect, useMemo, useContext } from "react";

import { useSearchParams } from "react-router-dom";

import {
  Box,
  Button,
  TextField,
  Autocomplete,
  Typography,
  Grid,
} from "../components/mui.js";

import { QueryContext } from "../context/QueryContext";

import {
  fetchParkingSitesGeom,
  findParkingSiteEvents,
  findSiteUnoffRatioByDirYearMonthDay,
  findSiteOffUnoffByYearMonthDay,
  findSiteOffUnoffByYearMonthHour,
  fetchExpansionFactorsSiteYearMonth,
} from "../data-loaders/DataLoaders";
// import axios from "axios";
import { txdotDistricts, mpoList, corridors } from "../common/constants";
import { parkingSites } from "../common/parkingSites";

import DeckglMapSites from "../map/DeckglMapSites.js";
import SitesDashboardOverview from "./SitesDashboardOverview.js";
import ChartsLayoutSites from "../Charts/ChartsLayoutSites";
import ChartsLayoutPrivateSites from "../Charts/ChartsLayoutPrivateSites";
import TablePanelSites from "../table/TablePanelSites";

import OptionsBar from "../ui/OptionsBar";
import ScrollArrow from "../ui/ScrollTop.js";

function SitesLayout(props) {
  const { statusGuide } = props;

  const [query, dispatch] = useContext(QueryContext);

  const [parkingSiteGeom, setParkingSiteGeom] = useState(null);
  const [allParkingSiteGeom, setAllParkingSiteGeom] = useState(null);
  const [siteEventsGeom, setSiteEventsGeom] = useState({ siteName: null });
  const [loading, setLoading] = useState(false);

  const [siteOffUnoffByYearMonthHourData, setSiteOffUnoffByYearMonthHourData] =
    useState("");
  const [siteOffUnoffByYearMonthDayData, setSiteOffUnoffByYearMonthDayData] =
    useState("");

  const [unauthorizedPercentTable, setUnauthorizedPercentTable] =
    useState(null);
  const [spaceRatioData, setSpaceRatioData] = useState(null);
  const [unofficialDataDay, setUnofficialDataDay] = useState(null);
  const [expansionFactor, setExpansionFactor] = useState(0.1);

  // console.log("SitesLayout-call-check");
  let hourlyCountsSummaryVars = [
    "unauth_ratio",
    "unauth1_ratio",
    "unauth1_3_ratio",
    "unauth3_7_ratio",
    "unauth7_11_ratio",
    "unauth11_ratio",
  ];

  let publicParkingSites = parkingSites.filter(
    (item) => item.type === "Public"
  );

  /** Add siteName to the URL to allow bookmarking and to reference in other places*/
  const [searchParams, setSearchParams] = useSearchParams();

  /*Filter and select option variables*/
  const [selectedAreaGroupFilter, setSelectedAreaGroupFilter] =
    useState("District");
  const [selectedAreaGroupFilterData, setSelectedAreaGroupFilterData] =
    useState(txdotDistricts);
  const [selectedArea, setSelectedArea] = useState("");

  const [selectedSiteTypeData, setSelectedSiteTypeData] =
    useState(publicParkingSites);
  const [selectedSite, setSelectedSite] = useState("");

  useEffect(() => {
    let siteNameFromUrl = searchParams.get("siteName") || "All";
    if (siteNameFromUrl !== "All") {
      setSelectedSite(
        publicParkingSites.find((p) => p.name === siteNameFromUrl)
      );
    } else {
      setSelectedSite({ name: "All" });
    }
    dispatch({
      type: "setSelectedSite",
      selection: {
        ...query.selectedSite,
        selected: siteNameFromUrl,
      },
    });
  }, []);

  /**Styles for sites filter */
  const actionStyle = {
    flex: 2,
    alignItems: "center",
    marginLeft: "40px",
    marginBottom: "10px",
  };

  const wrapperStyle = {
    display: "flex",
    margin: "6px",
    alignItems: "center",
  };

  const buttonStyle = {
    minWidth: "70px",
    maxWidth: "90px",
    marginRight: "2px",
  };

  const labelStyle = {
    flex: 0.45,
    color: "black",
  };

  const stageCanvasRef = useRef(null);
  const [chartHeight, setChartHeight] = useState(600);

  const callMultipleGeomFunctions = useMemo(() => {
    return async () => {
      setLoading(true);
      let newFeatures = allParkingSiteGeom,
        filteredFeatures = [];

      try {
        if (!allParkingSiteGeom) {
          // Map each URL to a call to geom function
          const promises = publicParkingSites.map((site) =>
            fetchParkingSitesGeom(site.name)
          );
          // Wait for all promises to resolve
          const results = await Promise.all(promises);

          newFeatures = results.map((res) => res.data[0]);
          setAllParkingSiteGeom(newFeatures);
        }
        if (selectedAreaGroupFilter && selectedArea?.label) {
          switch (selectedAreaGroupFilter) {
            case "District":
              filteredFeatures = newFeatures.filter(
                (f) => f.properties.districtname === selectedArea.label
              );
              break;
            case "MPO":
              filteredFeatures = newFeatures.filter(
                (f) => f.properties.mponame === selectedArea.label
              );
              break;
            case "Corridor":
              let corridor_sites = publicParkingSites.filter(
                (p) => p.corridor_interstate_name === selectedArea.value
              );
              filteredFeatures = newFeatures.filter((f) =>
                corridor_sites.some((c) => c.name === f.properties.name)
              );
              break;
            default:
              filteredFeatures = newFeatures;
          }
        } else {
          filteredFeatures = newFeatures;
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setParkingSiteGeom({
          type: "FeatureCollection",
          features: filteredFeatures,
        });
        setSiteEventsGeom({ siteName: "All" });
        setLoading(false);
      }
    };
  });

  useEffect(() => {
    // The 'current' property contains info of the reference:
    // align, title, ... , width, height, etc.
    if (stageCanvasRef.current) {
      let height = stageCanvasRef.current.offsetHeight;
      let width = stageCanvasRef.current.offsetWidth;

      setChartHeight(stageCanvasRef.current.offsetHeight);
    }
  }, [stageCanvasRef]);

  const removeParam = (key) => {
    // Clone the current search parameters
    const newParams = new URLSearchParams(searchParams);

    // Delete the specific parameter
    newParams.delete(key);

    // Update the search parameters in the URL
    setSearchParams(newParams);
  };

  useEffect(() => {
    let site = selectedSite?.name;
    if (site && site !== "All") {
      setSearchParams({ siteName: site });
      setLoading(true);
      dispatch({
        type: "setToggle",
        selection: {
          ...query.selectedComponents,
          parkingEvents: true,
          utilizationByDay: true,
          utilizationByHour: true,
        },
      });
      async function asyncCall() {
        findSiteOffUnoffByYearMonthHour(site, query.selectedSite.year).then(
          (response) => {
            if (response.data && response.data.length > 0)
              setSiteOffUnoffByYearMonthHourData(response.data);
          }
        );

        findSiteOffUnoffByYearMonthDay(site, query.selectedSite.year).then(
          (response) => {
            if (response.data && response.data.length > 0)
              setSiteOffUnoffByYearMonthDayData(response.data);
          }
        );

        fetchExpansionFactorsSiteYearMonth(
          site,
          query.selectedSite.year,
          query.selectedSite.month
        ).then((response) => {
          if (response.data && response.data.length > 0)
            setExpansionFactor(response.data[0].expansion_fact);
        });
      }

      async function asyncCallGeom() {
        fetchParkingSitesGeom(site).then((response) => {
          // console.log("response-check", response);
          if (response.data && response.data.length > 0) {
            setParkingSiteGeom({
              type: "FeatureCollection",
              features: response.data,
            });
          }
          return response.data;
        });

        findParkingSiteEvents(
          site,
          query.selectedSite.year,
          query.selectedSite.month
        ).then((response) => {
          response.data &&
            response.data.length > 0 &&
            setSiteEventsGeom(response.data);
        });
        setLoading(false);
      }
      asyncCall();
      asyncCallGeom();
    } else {
      removeParam("siteName");
      dispatch({
        type: "setToggle",
        selection: {
          ...query.selectedComponents,
          parkingEvents: false,
          utilizationByDay: false,
          utilizationByHour: false,
        },
      });

      callMultipleGeomFunctions();
    }
  }, [selectedSite, query.selectedSite.year, query.selectedSite.month]);

  useEffect(() => {
    if (
      parkingSiteGeom &&
      !siteEventsGeom.hasOwnProperty("siteName") &&
      selectedSite?.name !== "All"
    ) {
      let tempDataTable = [];
      [
        "less than 1 hour",
        "1-3 hours",
        "3-7 hours",
        "7-11 hours",
        "greater than 11 hours",
      ].map((obj) => {
        let dataTemp = calcUnauthorizedPercent(obj, siteEventsGeom);
        // console.log("dataTemp-check", dataTemp);
        tempDataTable.push(dataTemp);
      });
      let totalCount = sumByName(tempDataTable, "totalCount");
      let authorizedCount = sumByName(tempDataTable, "authorizedCount");
      let unauthorizedCount = sumByName(tempDataTable, "unauthorizedCount");
      let percentUnauthorized =
        totalCount > 0 ? (unauthorizedCount / totalCount) * 100 : 0;
      let percentAuthorized = 100 - percentUnauthorized;
      tempDataTable.push({
        siteName: selectedSite.name,
        yearMonth: query.selectedSite.yearMonth,
        parkingCategory: "Total",
        totalCount: totalCount,
        authorizedCount: authorizedCount,
        unauthorizedCount: unauthorizedCount,
        percentUnauthorized: percentUnauthorized,
        percentAuthorized: percentAuthorized,
      });
      setUnauthorizedPercentTable(tempDataTable);

      let tempSpaceRatioData = [];
      let tempUnofficialRatioDataDay = [];
      let siteDirs =
        selectedSite.name &&
        publicParkingSites.filter((obj) => obj.name === selectedSite.name)[0]
          .dir;
      // console.log("siteDirs-check", siteDirs);
      siteDirs &&
        siteDirs.map((ste) => {
          // console.log("ste-check", ste);
          let siteLabel =
            selectedSite.name +
            (ste === "N"
              ? " - Northbound"
              : ste === "S"
              ? " - Southbound"
              : ste === "W"
              ? " - Westbound"
              : ste === "E"
              ? " - Eastbound"
              : "");
          let dailyAveTotalCount = (
            siteEventsGeom.filter(
              (obj) => obj.sitename === selectedSite.name && obj.dir === ste
            ).length / 31
          ).toFixed(0);

          let tempSiteData = [];
          parkingSiteGeom.features.map((fc) =>
            tempSiteData.push(fc.properties)
          );
          // console.log("siteEventsGeom-check", siteEventsGeom);
          // console.log("tempSiteData-check", tempSiteData);
          let spaces = 0;
          if (
            tempSiteData.filter(
              (fc) => fc.dir === ste && fc.authorized === 1
            )[0]
          )
            spaces = tempSiteData.filter(
              (fc) => fc.dir === ste && fc.authorized === 1
            )[0].space;
          tempSpaceRatioData.push({
            siteName: selectedSite.name,
            dir: ste,
            yearMonth: query.selectedSite.yearMonth,
            siteLabel: siteLabel,
            totalCount: dailyAveTotalCount,
          });

          findSiteUnoffRatioByDirYearMonthDay(
            selectedSite.name,
            ste,
            query.selectedSite.year,
            query.selectedSite.month
          ).then((response) => {
            // console.log(
            //   "findSiteUnoffRatioByDirYearMonthDay-response-check",
            //   response,
            //   siteSelected
            // );
            // setSiteEventsGeom(response.data);

            let daysTemp = [];
            let chartDataTemp = [];
            hourlyCountsSummaryVars.map((item, index) => {
              chartDataTemp.push([]);
            });

            response.data &&
              response.data.length > 0 &&
              response.data.map((obj) => {
                daysTemp.push(obj.start_day);

                hourlyCountsSummaryVars.map((item, index) => {
                  chartDataTemp[index].push(obj[item]);
                });
              });

            tempUnofficialRatioDataDay.push({
              siteName: selectedSite.name,
              dir: ste,
              yearMonth: query.selectedSite.yearMonth,
              siteLabel: siteLabel,
              days: daysTemp,
              chartData: chartDataTemp,
            });
          });
        });
      setSpaceRatioData(tempSpaceRatioData);
      setUnofficialDataDay(tempUnofficialRatioDataDay);
    }
  }, [parkingSiteGeom, siteEventsGeom]);

  // Function to add the "All" option dynamically
  const getOptionsWithAll = (options) => {
    if (options.length > 0) {
      let newSite = {
        id: 0,
        name: "All",
        dir: ["E", "W", "N", "S", "B"],
        type: options[0].type,
        districtName: options[0].districtName,
        districtNumber: options[0].districtNumber,
        mpoName: options[0].mpoName,
        mpoNumber: options[0].mpoNumber,
        corridor_interstate_name: options[0].corridor_interstate_name,
      };
      options.sort((a, b) => a.name.localeCompare(b.name));
      return [newSite, ...options];
    }
    return [];
  };

  const calcUnauthorizedPercent = (parkCat, eventsData) => {
    let totalCount = eventsData.filter(
      (obj) => obj.parking_duration === parkCat
    ).length;

    let authorizedCount = eventsData.filter(
      (obj) =>
        obj.parking_duration === parkCat && obj.authorized === "authorized"
    ).length;

    let unauthorizedCount = eventsData.filter(
      (obj) =>
        obj.parking_duration === parkCat && obj.authorized === "unauthorized"
    ).length;

    let percentUnauthorized =
      totalCount > 0 ? (unauthorizedCount / totalCount) * 100 : 0;
    let percentAuthorized = 100 - percentUnauthorized;
    return {
      siteName: selectedSite.name,
      yearMonth: query.selectedSite.yearMonth,
      parkingCategory: parkCat,
      totalCount: totalCount,
      authorizedCount: authorizedCount,
      unauthorizedCount: unauthorizedCount,
      percentUnauthorized: percentUnauthorized,
      percentAuthorized: percentAuthorized,
    };
  };

  const sumByName = (data, name) => {
    return data.reduce(function (a, b) {
      return a + b[name];
    }, 0);
  };

  /* Filtering sites by district/MPO/corridor logic */

  const getLocationOptionLabel = (option) => {
    if (option && option.label) {
      return option.label;
    } else {
      return "";
    }
  };

  const getSiteOptionLabel = (option) => {
    if (option && option.name) {
      return option.name.toString();
    }
    return "";
  };

  const handleAreaGroupFilterClick = (option) => {
    if (option !== selectedAreaGroupFilter) {
      switch (option) {
        case "District":
          setSelectedAreaGroupFilterData(txdotDistricts);
          setSelectedAreaGroupFilter(option);
          break;
        case "MPO":
          setSelectedAreaGroupFilterData(mpoList);
          setSelectedAreaGroupFilter(option);
          break;
        case "Corridor":
          setSelectedAreaGroupFilterData(corridors);
          setSelectedAreaGroupFilter(option);
          break;
        // Add more cases for additional buttons if needed
        default:
          setSelectedAreaGroupFilterData([]);
      }
      setSelectedArea("");
      setSelectedSiteTypeData(publicParkingSites);
      setSelectedSite({ name: "All" });
    }
  };

  const handleAreaSelected = async (newValue) => {
    setSelectedArea(newValue);
    try {
      /** filtered data after a location is clicked */
      let filteredSite = [];
      if (newValue !== null) {
        if (selectedAreaGroupFilter === "District") {
          filteredSite = publicParkingSites.filter(
            (item) => item.districtName === newValue.label
          );
        } else if (selectedAreaGroupFilter === "MPO") {
          filteredSite = publicParkingSites.filter(
            (item) => item.mpoName === newValue.label
          );
        } else if (selectedAreaGroupFilter === "Corridor") {
          filteredSite = publicParkingSites.filter(
            (item) =>
              item.corridor_interstate_name !== null &&
              item.corridor_interstate_name.startsWith(newValue.label)
          );
        }
        if (filteredSite.length > 0) {
          setSelectedSiteTypeData(filteredSite);
          setSelectedSite({
            name: "All",
            districtName: filteredSite[0].districtName,
            districtNumber: filteredSite[0].districtNumber,
            mpoName: filteredSite[0].mpoName,
            mpoNumber: filteredSite[0].mpoNumber,
          });
        } else {
          setSelectedSiteTypeData([]);
          setSelectedSite("");
        }
        return;
      } else {
        /** Load all sites if no district, MPO, or corridor selected */
        setSelectedSiteTypeData(publicParkingSites);
        setSelectedSite({ name: "All" });
      }
    } catch (error) {
      console.error("Error filtering data:", error);
    }
  };

  const handleSiteSelected = (event, newSite) => {
    if (newSite) {
      setSelectedSite(newSite);
    }
  };

  return (
    <React.Fragment>
      <Grid container spacing={0} className="grid-container">
        <Grid item xs={12} sm={12} md={7} lg={7} className="grid-item">
          <Box className="map-container">
            <DeckglMapSites
              parkingSiteGeom={parkingSiteGeom}
              siteEventsGeom={siteEventsGeom}
              selectedSite={selectedSite?.name}
              setSelectedSite={setSelectedSite}
              isLoading={loading}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={5} lg={5} className="grid-item">
          <Box className="sitesLayout">
            <div style={wrapperStyle}>
              <Typography style={labelStyle}>Filter by:</Typography>
              <div style={actionStyle}>
                <Button
                  variant={
                    selectedAreaGroupFilter === "District"
                      ? "contained"
                      : "text"
                  }
                  style={buttonStyle}
                  onClick={() => handleAreaGroupFilterClick("District")}
                  key="District"
                >
                  District
                </Button>
                <Button
                  variant={
                    selectedAreaGroupFilter === "MPO" ? "contained" : "text"
                  }
                  style={buttonStyle}
                  onClick={() => handleAreaGroupFilterClick("MPO")}
                  key="MPO"
                >
                  MPO
                </Button>
                <Button
                  variant={
                    selectedAreaGroupFilter === "Corridor"
                      ? "contained"
                      : "text"
                  }
                  style={buttonStyle}
                  onClick={() => handleAreaGroupFilterClick("Corridor")}
                  key="Corridor"
                >
                  Corridor
                </Button>
              </div>
            </div>
            <div style={wrapperStyle}>
              <Typography style={labelStyle}>Select location: </Typography>
              <Autocomplete
                id="area-lists"
                sx={{
                  ...actionStyle,
                  display: "flex",
                  justifyContent: "center",
                }}
                value={selectedArea}
                onChange={(event, newValue) => handleAreaSelected(newValue)}
                options={selectedAreaGroupFilterData.sort((a, b) =>
                  a.label.localeCompare(b.label)
                )}
                getOptionLabel={getLocationOptionLabel}
                isOptionEqualToValue={(option, value) => true}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={
                      selectedAreaGroupFilter
                        ? `Select a ${selectedAreaGroupFilter}`
                        : "Filter by Geography"
                    }
                    placeholder={selectedAreaGroupFilter}
                  />
                )}
              />
            </div>
            <div style={wrapperStyle}>
              <Typography style={labelStyle}>Select site:&nbsp;</Typography>
              <Autocomplete
                style={actionStyle}
                id="parking-site"
                value={selectedSite}
                onChange={handleSiteSelected}
                options={getOptionsWithAll(selectedSiteTypeData)}
                getOptionLabel={getSiteOptionLabel}
                isOptionEqualToValue={(option, value) => true}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select a site"
                    placeholder="Sites"
                  />
                )}
              />
            </div>
            <SitesDashboardOverview selectedSite={selectedSite?.name} />
          </Box>
        </Grid>

        {query.selectedComponents.parkingEvents && (
          <TablePanelSites
            id="sites_park_summary"
            unauthorizedPercentTable={unauthorizedPercentTable}
            spaceRatioData={spaceRatioData}
            expansionFactor={expansionFactor}
          />
        )}

        {unofficialDataDay &&
          siteOffUnoffByYearMonthHourData &&
          siteOffUnoffByYearMonthDayData && (
            <>
              {selectedSite?.name !== "All" &&
                selectedSite?.type === "Public" && (
                  <ChartsLayoutSites
                    selectedSite={selectedSite} //pass complete site object here
                    siteOffUnoffByYearMonthHourData={
                      siteOffUnoffByYearMonthHourData
                    }
                    siteOffUnoffByYearMonthDayData={
                      siteOffUnoffByYearMonthDayData
                    }
                  />
                )}
              {selectedSite?.name !== "All" &&
                selectedSite?.type === "Private" && (
                  <ChartsLayoutPrivateSites
                    selectedSite={selectedSite} //pass complete site object here
                    parkingSiteGeom={parkingSiteGeom}
                    chartHeight={chartHeight}
                    siteOffUnoffByYearMonthHourData={
                      siteOffUnoffByYearMonthHourData
                    }
                    siteOffUnoffByYearMonthDayData={
                      siteOffUnoffByYearMonthDayData
                    }
                  />
                )}
            </>
          )}
      </Grid>

      <OptionsBar
        statusGuide={statusGuide}
        showVehicleWeightClass={false}
        showDayType={false}
        showYear={true}
        showMonth={true}
        showGridResolution={false}
        showOpacity={true}
      />
      <ScrollArrow />
    </React.Fragment>
  );
}

export default SitesLayout;
