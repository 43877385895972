/**map layers common constants */
export const MAPBOX_URL =
  "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={access_token}";
//"https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token={access_token}";

export const MAPBOX_STREETS_V10_URL =
  "https://api.tiles.mapbox.com/styles/v1/mapbox/streets-v10/tiles/256/{z}/{x}/{y}?access_token={access_token}";

export const MAPBOX_ATTRIBUTION = `Map data &copy; 
<a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, 
<a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>,
Imagery © <a href="https://www.mapbox.com/">Mapbox</a>`;

export const baseLayers = [
  {
    name: "Streets",
    url: MAPBOX_STREETS_V10_URL,
    id: "mapbox/streets-v11",
  },
  {
    name: "Satellite",
    url: MAPBOX_URL,
    id: "mapbox/satellite-v9",
  },
  {
    name: "Satellite-Streets",
    url: MAPBOX_URL,
    id: "mapbox/satellite-streets-v11",
  },
  {
    name: "Light",
    url: MAPBOX_URL,
    id: "mapbox/light-v10",
  },
  {
    name: "Dark",
    url: MAPBOX_URL,
    id: "mapbox/dark-v10",
  },
];

/** Statewide yearMonth and  month options */
export const yearsMonths = [
  { year: 2019, month: 3, yearMonth: "2019-March" },
  { year: 2019, month: 4, yearMonth: "2019-April" },
  { year: 2019, month: 5, yearMonth: "2019-May" },
  { year: 2019, month: 9, yearMonth: "2019-September" },
  { year: 2019, month: 10, yearMonth: "2019-October" },
  { year: 2019, month: 11, yearMonth: "2019-November" },
  { year: 2020, month: 2, yearMonth: "2020-February" },
  { year: 2020, month: 3, yearMonth: "2020-March" },
  { year: 2020, month: 4, yearMonth: "2020-April" },
  { year: 2020, month: 9, yearMonth: "2020-September" },
  { year: 2020, month: 10, yearMonth: "2020-October" },
  { year: 2020, month: 11, yearMonth: "2020-November" },
  { year: 2021, month: 2, yearMonth: "2021-February" },
  { year: 2021, month: 3, yearMonth: "2021-March" },
  { year: 2021, month: 4, yearMonth: "2021-April" },
  { year: 2021, month: 9, yearMonth: "2021-September" },
  { year: 2021, month: 10, yearMonth: "2021-October" },
  { year: 2021, month: 11, yearMonth: "2021-November" },
];

export const months = [
  { month: 1, description: "January" },
  { month: 2, description: "February" },
  { month: 3, description: "March" },
  { month: 4, description: "April" },
  { month: 5, description: "May" },
  { month: 6, description: "June" },
  { month: 7, description: "July" },
  { month: 8, description: "August" },
  { month: 9, description: "September" },
  { month: 10, description: "October" },
  { month: 11, description: "November" },
  { month: 12, description: "December" },
];

/** Sites yearMonth and  month options */
export const sitesMonths = [
  { month: 2, description: "February" },
  { month: 3, description: "March" },
  { month: 4, description: "April" },
  { month: 5, description: "May" },
  { month: 6, description: "June" },
  { month: 7, description: "July" },
  { month: 8, description: "August" },
  { month: 9, description: "September" },
  { month: 10, description: "October" },
  { month: 11, description: "November" },
  { month: 12, description: "December" },
];

export const sitesYearsMonths = [
  { year: 2019, month: 3, yearMonth: "2019-March" },
  { year: 2019, month: 4, yearMonth: "2019-April" },
  { year: 2019, month: 5, yearMonth: "2019-May" },
  { year: 2019, month: 9, yearMonth: "2019-September" },
  { year: 2019, month: 10, yearMonth: "2019-October" },
  { year: 2019, month: 11, yearMonth: "2019-November" },
  { year: 2020, month: 2, yearMonth: "2020-February" },
  { year: 2020, month: 3, yearMonth: "2020-March" },
  { year: 2020, month: 4, yearMonth: "2020-April" },
  { year: 2020, month: 9, yearMonth: "2020-September" },
  { year: 2020, month: 10, yearMonth: "2020-October" },
  { year: 2020, month: 11, yearMonth: "2020-November" },
  { year: 2021, month: 2, yearMonth: "2021-February" },
  { year: 2021, month: 3, yearMonth: "2021-March" },
  { year: 2021, month: 4, yearMonth: "2021-April" },
  { year: 2021, month: 9, yearMonth: "2021-September" },
  { year: 2021, month: 10, yearMonth: "2021-October" },
  { year: 2021, month: 11, yearMonth: "2021-November" },
];

/** TODO: Remove if not used anymore */
export const geographies = [
  {
    id: 1,
    name: "State",
    label: "State",
    lookup: "statewide",
    counties: [
      0, 1, 3, 5, 9, 11, 13, 15, 17, 19, 21, 23, 25, 27, 29, 31, 33, 35, 37, 39,
      41, 43, 45, 47, 510,
    ],
  },
  {
    id: 2,
    name: "Western Region",
    label: "Western Region",
    lookup: "western",
    counties: [1, 23, 43],
    countiesLandUse: ["WASH", "ALLE", "GARR"],
  },
  {
    id: 3,
    name: "Central Region",
    label: "Central Region",
    lookup: "central",
    counties: [3, 2, 5, 510, 13, 25, 27],
    countiesLandUse: ["ANNE", "BACO", "BACI", "CARR", "HARF", "HOWA"],
  },
  {
    id: 4,
    name: "DC Metro Area",
    label: "DC Metro Area",
    lookup: "dc-metro-area",
    counties: [21, 31, 33, 0],
    countiesLandUse: ["FRED", "MONT", "PRIN"],
  },
  {
    id: 5,
    name: "Southern Region",
    label: "Southern Region",
    lookup: "southern",
    counties: [9, 17, 37],
    countiesLandUse: ["CALV", "CHAR", "STMA"],
  },
  {
    id: 6,
    name: "Eastern Shore Region",
    label: "Eastern Shore Region",
    lookup: "eastern-shore",
    counties: [11, 15, 19, 29, 35, 39, 41, 45, 47],
    countiesLandUse: [
      "CARO",
      "CECI",
      "DORC",
      "KENT",
      "QUEE",
      "SOME",
      "TALB",
      "WICO",
      "WORC",
    ],
  },
  {
    id: 7,
    name: "Baltimore Area",
    label: "Baltimore Area",
    lookup: "baltimore-area",
    counties: [5, 510],
    countiesLandUse: ["BACO", "BACI"],
  },
];

export const regionParkingDataTypes = [
  {
    value: 0,
    label: "All Parking",
  },
  {
    value: 1,
    label: "Parking < 1 hour",
  },
  {
    value: 2,
    label: "Parking >= 1 hour, < 3 hours",
  },
  {
    value: 3,
    label: "Parking >= 3 hours, < 7 hours",
  },
  {
    value: 4,
    label: "Parking >= 7 hours, < 11 hours",
  },
  {
    value: 5,
    label: "Parking >= 11 hours",
  },
];

export const parkingDurationOptions = [
  {
    label: "All",
    value: "all",
    authorizedField: "total_auth",
    unauthorizedField: "total_unauth",
  },
  {
    label: "Less than 1 hour",
    value: "< 1",
    authorizedField: "total_auth1",
    unauthorizedField: "total_unauth1",
  },
  {
    label: "Greater than 1, less than 3 hours",
    value: "> 1, < 3",
    authorizedField: "total_auth1_3",
    unauthorizedField: "total_unauth1_3",
  },
  {
    label: "Greater than 3, less than 7 hours",
    value: "> 3, < 7",
    authorizedField: "total_auth3_7",
    unauthorizedField: "total_unauth3_7",
  },
  {
    label: "Greater than 7, less than 11 hours",
    value: "> 7, < 11",
    authorizedField: "total_auth7_11",
    unauthorizedField: "total_unauth7_11",
  },
  {
    label: "Greater than 11 hours",
    value: "> 11",
    authorizedField: "total_auth11",
    unauthorizedField: "total_unauth11",
  },
];

/** Bottom toolbar options for statewide menu */
export const geoOptions = [
  { label: "Statewide", value: "statewide" },
  { label: "Districts", value: "district" },
  { label: "MPOs", value: "mpo" },
  { label: "Corridors", value: "corridor" },
];

export const vehicleWeightClassOptions = [
  { label: "All Vehicles (Weight Class 2 and 3)", value: 0 },
  { label: "Weight Class 2", value: 2 },
  { label: "Weight Class 3", value: 3 },
];

export const yearOptions = [
  { label: 2021, value: 2021 },
  { label: 2020, value: 2020 },
  { label: 2019, value: 2019 },
];

export const dayTypeOptions = [
  { label: "All Daytypes", value: 10 },
  { label: "Monday", value: 1 },
  { label: "Tuesday", value: 2 },
  { label: "Wednesday", value: 3 },
  { label: "Thursday", value: 4 },
  { label: "Friday", value: 5 },
  { label: "Saturday", value: 6 },
  { label: "Sunday", value: 7 },
];

export const month2019Options = [
  { label: "March", value: 3 },
  { label: "April", value: 4 },
  { label: "May", value: 5 },
  { label: "September", value: 9 },
  { label: "October", value: 10 },
  { label: "November", value: 11 },
];

export const monthOptions = [
  { label: "February", value: 2 },
  { label: "March", value: 3 },
  { label: "April", value: 4 },
  { label: "September", value: 9 },
  { label: "October", value: 10 },
  { label: "November", value: 11 },
];

export const stateMapGridResolution = [
  { label: 6, value: 6 },
  { label: 7, value: 7 },
  { label: 8, value: 8 },
  { label: 9, value: 9 },
  { label: 10, value: 10 },
];

/**Bottom toolbar options for parking sites */
export const sitesYears = [
  { label: 2019, value: 2019 },
  { label: 2020, value: 2020 },
  { label: 2021, value: 2021 },
];

export const sitesMonth2019Options = [
  { label: "March", value: 3 },
  { label: "April", value: 4 },
  { label: "May", value: 5 },
  { label: "September", value: 9 },
  { label: "October", value: 10 },
  { label: "November", value: 11 },
];

export const sitesMonthOptions = [
  { label: "February", value: 2 },
  { label: "March", value: 3 },
  { label: "April", value: 4 },
  { label: "September", value: 9 },
  { label: "October", value: 10 },
  { label: "November", value: 11 },
];

/** TODO: Fetch by API calls instead */
export const mpoList = [
  { value: 166, label: "Abilene" },
  { value: 28, label: "Alamo Area" },
  { value: 120, label: "Amarillo" },
  { value: 249, label: "Bryan-College Station" },
  { value: 90, label: "Capital Area" },
  { value: 96, label: "Corpus Christi" },
  { value: 66, label: "El Paso" },
  { value: 232, label: "Grayson County" },
  { value: 15, label: "Houston Galveston Area Council" },
  { value: 277, label: "Killeen-Temple" },
  { value: 205, label: "Laredo Webb County Area" },
  { value: 361, label: "Longview" },
  { value: 122, label: "Lubbock" },
  { value: 282, label: "North Central Texas Council of Governments" },
  { value: 174, label: "Permian Basin" },
  { value: 230, label: "Rio Grande Valley" },
  { value: 208, label: "San Angelo" },
  { value: 135, label: "South East Texas Regional Planning Commission" },
  { value: 211, label: "Texarkana" },
  { value: 213, label: "Tyler" },
  { value: 363, label: "Victoria" },
  { value: 140, label: "Waco" },
  { value: 151, label: "Wichita Falls" },
];

/** TODO: Fetch by API calls instead */
export const txdotDistricts = [
  {
    value: 8,
    label: "Abilene",
  },
  {
    value: 4,
    label: "Amarillo",
  },
  {
    value: 19,
    label: "Atlanta",
  },
  {
    value: 14,
    label: "Austin",
  },
  {
    value: 20,
    label: "Beaumont",
  },
  {
    value: 23,
    label: "Brownwood",
  },
  {
    value: 17,
    label: "Bryan",
  },
  {
    value: 25,
    label: "Childress",
  },
  {
    value: 16,
    label: "Corpus Christi",
  },
  {
    value: 18,
    label: "Dallas",
  },
  {
    value: 24,
    label: "El Paso",
  },
  {
    value: 2,
    label: "Fort Worth",
  },
  {
    value: 12,
    label: "Houston",
  },
  {
    value: 22,
    label: "Laredo",
  },
  {
    value: 5,
    label: "Lubbock",
  },
  {
    value: 11,
    label: "Lufkin",
  },
  {
    value: 6,
    label: "Odessa",
  },
  {
    value: 1,
    label: "Paris",
  },
  {
    value: 21,
    label: "Pharr",
  },
  {
    value: 7,
    label: "San Angelo",
  },
  {
    value: 15,
    label: "San Antonio",
  },
  {
    value: 10,
    label: "Tyler",
  },
  {
    value: 9,
    label: "Waco",
  },
  {
    value: 3,
    label: "Wichita Falls",
  },
  {
    value: 13,
    label: "Yoakum",
  },
];

export const corridors = [
  // {
  //   label: 1,
  //   value: "Interstate Corridors (including loops)"
  // },
  {
    label: "Ports-to-Plains",
    value: "Ports-to-Plains",
  },
  {
    label: "US277_US83",
    value: "US277_US83",
  },
  {
    label: "US87_US83",
    value: "US87_US83",
  },
  {
    label: "US69_US175",
    value: "US69_US175",
  },
  {
    label: "US59",
    value: "US59",
  },
  {
    label: "US281",
    value: "US281",
  },
  {
    label: "IH0002",
    value: "IH0002-KG",
  },
  {
    label: "IH0010",
    value: "IH0010-KG",
  },
  {
    label: "IH0014",
    value: "IH0014-KG",
  },
  {
    label: "IH0020",
    value: "IH0020-KG",
  },
  {
    label: "IH0027",
    value: "IH0027-KG",
  },
  {
    label: "IH0030",
    value: "IH0030-KG",
  },
  {
    label: "IH0035",
    value: "IH0035-KG",
  },
  {
    label: "IH0035E",
    value: "IH0035E-KG",
  },
  {
    label: "IH0035W",
    value: "IH0035W-KG",
  },
  {
    label: "IH0037",
    value: "IH0037-KG",
  },
  {
    label: "IH0040",
    value: "IH0040-KG",
  },
  {
    label: "IH0044",
    value: "IH0044-KG",
  },
  {
    label: "IH0045",
    value: "IH0045-KG",
  },
  {
    label: "IH0069C",
    value: "IH0069C-KG",
  },
  {
    label: "IH0069E",
    value: "IH0069E-KG",
  },
  {
    label: "IH0069",
    value: "IH0069-KG",
  },
  {
    label: "IH0069W",
    value: "IH0069W-KG",
  },
];
